import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

import { StyledEmailConsent as S } from './EmailConsent.styles'
import { QUESTION, USER_ANSWERS } from './constants'

export const EmailConsent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const isResultActive = useFeatureIsOn('lv_176_result_page_test')

  const handleClick = useCallback(
    async (event) => {
      eventLogger.logQuestion({
        question: QUESTION,
        answers: event.target.dataset.value
          ? USER_ANSWERS.YES
          : USER_ANSWERS.NO,
      })
      dispatch(
        sendUserEmailConsentAction({ consented: !!event.target.dataset.value }),
      )

      isResultActive && goTo({ pathname: PageId.RESULT, search })
    },
    [dispatch, search, isResultActive],
  )

  return (
    <Container>
      <S.Title>
        <Trans
          i18nKey="onboarding.emailConsent.title"
          components={[<strong />]}
        />
      </S.Title>
      <Button marginBottom={24} data-value=" " onClick={handleClick}>
        {t('actions.yesIDo')}
      </Button>
      <S.Skip onClick={handleClick} data-value="">
        {t('actions.noIDont')}
      </S.Skip>
    </Container>
  )
}
