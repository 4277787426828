import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { paymentApi } from 'api'

import {
  setIsPaymentStatusShown,
  setPaymentAnimationStatus,
} from 'root-redux/actions/common'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { AnimationState } from 'modules/purchase/constants'
import { logFailedPayPalPayment } from 'modules/purchase/helpers/logFailedPayPalPayment'
import { logSuccessfulPayPalPayment } from 'modules/purchase/helpers/logSuccessfulPayPalPayment'

import { IResponseResult } from 'models/api.model'

import { Cohort } from 'root-constants'

type TValues = {
  createSubscription: (data) => Promise<IResponseResult<never>>
  handleError: (data) => void
  handleSuccess: (data) => void
}

export function useCreatePaypalSubscription(): TValues {
  const uuid = useSelector(selectUUID)
  const dispatch: TAppDispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort) as Cohort

  const createSubscription = useCallback(
    async (productId) =>
      await paymentApi.createPaypalSubscription({
        uuid,
        paypalPlanId: productId,
        cohort,
      }),
    [uuid, cohort],
  )

  const handleError = useCallback(
    (analyticsData) => {
      dispatch(setPaymentAnimationStatus(AnimationState.ERROR))
      dispatch(setIsPaymentStatusShown(true))
      logFailedPayPalPayment({
        ...analyticsData,
      })
    },
    [dispatch],
  )

  const handleSuccess = useCallback(
    (analyticsData) => {
      dispatch(setPaymentAnimationStatus(AnimationState.SUCCESS))
      dispatch(setIsPaymentStatusShown(true))
      logSuccessfulPayPalPayment({ ...analyticsData })
    },
    [dispatch],
  )

  return { createSubscription, handleError, handleSuccess }
}
