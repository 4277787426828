import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { createProductId } from 'helpers/createProductId'

import {
  selectUpgradeSubscriptionFullPrice,
  selectUpgradeSubscriptionPeriodName,
  selectUpgradeSubscriptionPeriodQuantity,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsEvent,
} from 'services/googleAnalytics.service'

import { ScreenName } from 'root-constants'

type TReturnPurchaseAnalytics = {
  sendGeneralPurchaseEvents: (shouldPurchaseShownBeLogged?: boolean) => void
}

export const usePurchaseUpgradeAnalytics = (): TReturnPurchaseAnalytics => {
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)
  const price = useSelector(selectUpgradeSubscriptionFullPrice)
  const periodQuantity = useSelector(selectUpgradeSubscriptionPeriodQuantity)
  const periodName = useSelector(selectUpgradeSubscriptionPeriodName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const screenName = ScreenName.LIVECHAT_UPGRADE

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price,
      }),
    [periodName, periodQuantity, price],
  )

  useEffect(() => {
    if (!periodName && !periodQuantity && !price) return
    eventLogger.logSalePageShown({ productIds: [productId], screenName })
  }, [productId, periodName, periodQuantity, screenName, price])

  const sendGeneralPurchaseEvents = useCallback(
    (shouldPurchaseShownBeLogged = true) => {
      if (shouldPurchaseShownBeLogged) {
        eventLogger.logPurchaseShown({
          productId,
          screenName,
          stripeAccountId,
          stripeAccountName,
        })
      }

      window.fbq('track', 'AddToCart', {}, { eventID: uuid })
      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })

      window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
      window.ttq.track('AddToCart', { event_id: uuid })

      window.snaptr &&
        window.snaptr('track', 'ADD_CART', {
          user_email: isPersonalDataAllowed ? email : '',
        })

      window.obApi && window.obApi('track', 'Add To Cart')
      window._tfa && window._tfa.push({ notify: 'event', name: 'add_to_cart' })

      !!window.gtag &&
        window.gtag(
          GoogleAnalyticsAction.EVENT,
          GoogleAnalyticsEvent.ADD_TO_CART,
          {
            items: [{ id: productId, price }],
          },
        )
      !!window.gtag &&
        window.gtag(
          GoogleAnalyticsAction.EVENT,
          GoogleAnalyticsEvent.BEGIN_CHECKOUT,
          {
            items: [{ id: productId, price }],
          },
        )
    },
    [
      uuid,
      price,
      email,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
      isPersonalDataAllowed,
    ],
  )

  return { sendGeneralPurchaseEvents }
}
