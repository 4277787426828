import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserGoal } from 'root-redux/selects/common'

import { UserGoal } from 'root-constants'

import { StyledPersonalPurchaseSubtitle as S } from './PersonalPurchaseSubtitle.style'

type TProp = {
  className?: string
}

export const PersonalPurchaseSubtitle: FC<TProp> = ({ className = '' }) => {
  const userGoal = useSelector(selectUserGoal)
  const { t } = useTranslation()

  const subtitle = useMemo(() => {
    switch (userGoal) {
      case UserGoal.WRINKLE_FREE_SKIN:
        return t`purchasePersonal.subtitle.wrinkles`
      case UserGoal.REDUCE_FACE_FAT:
        return t`purchasePersonal.subtitle.reduceFat`
      case UserGoal.BOTH_OPTIONS:
        return t`purchasePersonal.subtitle.both`
      default:
        return t`purchasePersonal.subtitle.both`
    }
  }, [userGoal, t])

  return <S.Subtitle className={className}>{subtitle}</S.Subtitle>
}
