import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { StyledSecurityInfo as S } from './SecurityInfoV2.styles'

type TProps = {
  price: string
  periodQuantity?: number
  className?: string
}

export const SecurityInfoV2: React.FC<TProps> = ({
  price,
  periodQuantity = 1,
  className = '',
}) => {
  const { t } = useTranslation()
  const contactFormLink = getSupportFormLink()

  return (
    <S.Wrapper className={className}>
      <S.Container>
        <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
        <S.Text>{t`purchase1.securityInfo.textFirst`}</S.Text>
        <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
        <S.Text>{t`purchase1.securityInfo.textSecond`}</S.Text>
        <S.Title>{t`purchase1.securityInfo.titleLuvlyPlan`}</S.Title>
        <S.Text>
          {t('purchase1.securityInfo.textLuvlyPlan', {
            price,
            count: periodQuantity,
          })}{' '}
          <TermsOfUseLink />.
        </S.Text>
        <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>
        <S.Text>
          <a
            className="not-decorated"
            target="_blank"
            rel="noopener noreferrer"
            href={contactFormLink}
          >
            {t`commonComponents.contactSupport`}
          </a>
        </S.Text>
      </S.Container>
    </S.Wrapper>
  )
}
