import React, { useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import spinnerSvg from 'assets/images/sprite/spinner-gray.svg'

import { CDN_FOLDER_LINK, I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledPreparePlan as S } from './PreparePlan.styles'
import { QUESTION, REDIRECT_DELAY, SLIDES_INFO } from './constants'

export const PreparePlan: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const language = useSelector(selectLanguage)
  const cohortToUse = useCohortToUse()
  const { isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextPage = useCallback(
    () => setTimeout(() => handleContinue(''), REDIRECT_DELAY),
    [handleContinue],
  )

  return (
    <div>
      <Container>
        <S.SpinnerContainer>
          <S.Circle svg={spinnerSvg} height={55} />
          <S.Text>
            <Trans
              i18nKey="onboarding.preparePlan.spinnerText"
              components={[<br />]}
            />
          </S.Text>
        </S.SpinnerContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          pagination={false}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onReachEnd={handleNextPage}
        >
          {SLIDES_INFO.map(({ id, imagePath, textPath }) => (
            <SwiperSlide key={id}>
              <S.ImageContainer>
                <S.Image
                  src={`${CDN_FOLDER_LINK}${imagePath}_${language}.png`}
                  alt="slide"
                />
              </S.ImageContainer>
              <S.SlideText isLargeSize={isTiktokCohort}>
                <Trans
                  i18nKey={textPath}
                  context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                />
              </S.SlideText>
            </SwiperSlide>
          ))}
        </Carousel>
      </Container>
    </div>
  )
}
