import { UserGoal } from 'root-constants'

export const USER_GOAL_CONFIG_MAP = {
  [UserGoal.BOTH_OPTIONS]: {
    currentLevelIndicator: 1,
    goalLevelIndicator: 4,
    goalText: 'purchase7.facialCondition.goals.collagenLevel',
  },
  [UserGoal.REDUCE_FACE_FAT]: {
    currentLevelIndicator: 4,
    goalLevelIndicator: 1,
    goalText: 'purchase7.facialCondition.goals.faceFatLevel',
  },
  [UserGoal.WRINKLE_FREE_SKIN]: {
    currentLevelIndicator: 4,
    goalLevelIndicator: 1,
    goalText: 'purchase7.facialCondition.goals.wrinklesLevel',
  },
}

export const LEVEL_INDICATION_BLANK = [1, 2, 3, 4, 5]

export const ANIMATION_PATH = '/animations/user-result-animation'
