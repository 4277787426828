import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 60px;
    padding: 14px;
    background-color: ${Color.WHITE};
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 14px;
  `,
  Title: styled.h2`
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 240px;
    background-color: ${Color.WHITE};
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Content: styled.div`
    padding: 60px 5px 10px;
    border: 1px solid #c4c4c4;
  `,
  Text: styled.p<TLocaleStylesProps>`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? 'center' : 'left'};

    & > a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -68px;
    right: -12px;
    width: 92px;
  `,
}
