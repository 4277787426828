import React, { FC } from 'react'

import featuredImagePng from 'assets/images/featured.png'

import { StyledFeaturedBlock as S } from './FeaturedBlock.style'

export const FeaturedBlock: FC = () => {
  return (
    <S.Wrapper>
      <picture>
        <img src={featuredImagePng} alt="body" />
      </picture>
    </S.Wrapper>
  )
}
