import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  LOGIN_METHOD_INSTRUCTION_LINK_MAP,
  LoginMethod,
} from 'root-constants'

export const getDeepLink = (
  loginMethod: LoginMethod,
  customToken: string | null,
): string =>
  LOGIN_METHOD_INSTRUCTION_LINK_MAP[loginMethod].replace(
    CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
    customToken || '',
  )
