import { useCallback, useMemo } from 'react'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

import { createProductId } from 'helpers/createProductId'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { ScreenName } from 'root-constants'

import { useLogSalePageShown } from './useLogSalePageShown'
import { usePurchaseStore } from './usePurchaseStore'

type TReturnPurchaseAnalytics = {
  sendGeneralPurchaseEvents: (shouldPurchaseShownBeLogged?: boolean) => void
}

export const usePurchaseAnalytics = (
  screenName: ScreenName = ScreenName.PURCHASE,
): TReturnPurchaseAnalytics => {
  const {
    uuid,
    periodName,
    periodQuantity,
    price,
    subscriptions,
    selectedSubscription,
    trialPrice,
    email,
    stripeAccountId,
    stripeAccountName,
  } = usePurchaseStore()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  useLogSalePageShown({ subscriptions, screenName })

  const sendGeneralPurchaseEvents = useCallback(
    (shouldPurchaseShownBeLogged = true) => {
      if (shouldPurchaseShownBeLogged) {
        eventLogger.logPurchaseShown({
          productId,
          screenName,
          stripeAccountId,
          stripeAccountName,
        })
      }

      window.fbq('track', 'AddToCart', {}, { eventID: uuid })
      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })

      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
      window.ttq && window.ttq.track('AddToCart', { event_id: uuid })

      window.rdt &&
        window.rdt('track', 'AddToCart', {
          email: isPersonalDataAllowed ? email : '',
          externalId: uuid,
        })

      window.snaptr &&
        window.snaptr('track', 'ADD_CART', {
          user_email: isPersonalDataAllowed ? email : '',
        })

      window.obApi && window.obApi('track', 'Add To Cart')
      window._tfa && window._tfa.push({ notify: 'event', name: 'add_to_cart' })

      selectedSubscription &&
        googleAnalyticsLogger.logAddingToCart(selectedSubscription)
      googleAnalyticsLogger.logCheckoutStarted(subscriptions)
    },
    [
      uuid,
      email,
      selectedSubscription,
      subscriptions,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
      isPersonalDataAllowed,
    ],
  )

  return { sendGeneralPurchaseEvents }
}
