import { useMemo } from 'react'

import { Cohort } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useActiveCohortIdentifier = () => {
  const cohortToUse = useCohortToUse()

  const {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isTiktokCohort,
    isLuvlyIntro2TCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isStartPageVideoCohort,
    isChatCohort,
    isIntroSaleCohort,
  } = useMemo(
    () => ({
      isLuvlyIntroFastCohort: cohortToUse === Cohort.LUVLY_INTRO_FAST,
      isTiktokCohort: cohortToUse === Cohort.LUVLY_6,
      isCosmeticCohort: cohortToUse === Cohort.LUVLY_COSMETIC_SCANNER,
      isSeniorCohort:
        cohortToUse === Cohort.LUVLY_12 || cohortToUse === Cohort.LUVLY_12_SALE,
      isLuvlyIntro2TCohort: cohortToUse === Cohort.LUVLY_INTRO2T,
      isWellnessBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE,
      isLuvly14Cohort: cohortToUse === Cohort.LUVLY_14,
      isStartPageVideoCohort:
        cohortToUse === Cohort.LUVLY_15 ||
        cohortToUse === Cohort.LUVLY_16 ||
        cohortToUse === Cohort.LUVLY_17 ||
        cohortToUse === Cohort.LUVLY_18,
      isChatCohort: cohortToUse === Cohort.LUVLY_CHAT,
      isIntroSaleCohort:
        cohortToUse === Cohort.LUVLY_INTRO_SALE ||
        cohortToUse === Cohort.LUVLY_12_SALE,
    }),
    [cohortToUse],
  )

  return {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isTiktokCohort,
    isLuvlyIntro2TCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isStartPageVideoCohort,
    isChatCohort,
    isIntroSaleCohort,
  }
}
