import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomerReviews } from 'components/CustomerReviews'
import {
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { UserGoalGraph } from 'components/UserGoalGraph'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BottomButtonContainer } from 'common-styles'
import { TIKTOK_FLOW_CONTEXT } from 'root-constants'

import { StyledShortProgramDescription as S } from './ShortProgramDescriptionTiktok.styles'
import { QUESTIONS } from './constants'

export const ShortProgramDescriptionTiktok: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS[pageId],
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <S.Wrapper>
      <Container>
        <S.TitleContainer>
          <Trans
            i18nKey="onboarding.shortProgramDescription.question.default"
            context={TIKTOK_FLOW_CONTEXT}
            components={[<br />]}
          />
        </S.TitleContainer>
        <S.DescriptionContainer>
          <PageTitleDescription>
            <Trans
              i18nKey="onboarding.shortProgramDescription.description"
              components={[<br />]}
              context={TIKTOK_FLOW_CONTEXT}
            />
          </PageTitleDescription>
        </S.DescriptionContainer>
        <UserGoalGraph pageId={pageId} />
        <CustomerReviews
          customerReviews={DEFAULT_CUSTOMER_REVIEWS_V2}
          viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
        />
      </Container>
      <BottomButtonContainer>
        <Button onClick={handleNextClick}>
          {t`onboarding.shortProgramDescription.goToNextButtonText`}
        </Button>
      </BottomButtonContainer>
    </S.Wrapper>
  )
}
