import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserGoal } from 'root-redux/selects/common'

import { UserGoal } from 'root-constants'

import { StyledPersonalPurchaseTitle as S } from './PersonalPurchaseTitle.style'

type TProp = {
  className?: string
}

export const PersonalPurchaseTitle: FC<TProp> = ({ className = '' }) => {
  const userGoal = useSelector(selectUserGoal)
  const { t } = useTranslation()

  const title = useMemo(() => {
    switch (userGoal) {
      case UserGoal.WRINKLE_FREE_SKIN:
        return t`purchasePersonal.title.wrinkles`
      case UserGoal.REDUCE_FACE_FAT:
        return t`purchasePersonal.title.reduceFat`
      case UserGoal.BOTH_OPTIONS:
        return t`purchasePersonal.title.both`
      default:
        return t`purchasePersonal.title.both`
    }
  }, [userGoal, t])

  return <S.Title className={className}>{title}</S.Title>
}
