import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage, selectUserGoal } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useKeywordValue } from 'hooks/useKeywordValue'

import { addDaysToCurrentDate, getDateSequence } from 'helpers/date'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  DAYS_NUMBER,
  I18N_CONTEXT_COHORT_MAP,
  Language,
  USER_GOALS_GRAPH,
  USER_GOALS_TITLE,
  UserGoal,
} from 'root-constants'

import { StyledUserGoalGraph as S } from './UserGoalGraph.styles'
import {
  COSMETIC_SCANNER_IMAGE_PATH,
  GOOGLE_FUNNEL_IMAGE_PATH,
  IMAGE_PATH,
} from './constants'

type TProps = {
  pageId: PageId
}

export const UserGoalGraph: React.FC<TProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const cohortToUse = useCohortToUse()
  const { isCosmeticCohort, isTiktokCohort, isLuvlyIntro2TCohort } =
    useActiveCohortIdentifier()

  const keyword = useKeywordValue()

  const [userGoalTitle, userGoalGraph] = useMemo(() => {
    const initialGoal = t(USER_GOALS_TITLE[UserGoal.WRINKLE_FREE_SKIN])
    if (!userGoal) return [initialGoal, initialGoal]

    const goalTitle = t(USER_GOALS_TITLE[userGoal], {
      context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
    })
    const goalGraph = t(USER_GOALS_GRAPH[userGoal], {
      context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
    })
    return [goalTitle, goalGraph]
  }, [cohortToUse, t, userGoal])

  const goalDate = useMemo(() => {
    const daysNumber = DAYS_NUMBER[pageId]
    return addDaysToCurrentDate(daysNumber, language)
  }, [pageId, language])

  const isShortGoalText = useMemo(() => {
    if (isLuvlyIntro2TCohort && language === Language.FR) {
      return false
    }

    return !userGoal || userGoal === UserGoal.REDUCE_FACE_FAT
  }, [isLuvlyIntro2TCohort, language, userGoal])

  const isStaticDate = useMemo(
    () => pageId === PageId.SHORT_PROGRAM_DESCRIPTION,
    [pageId],
  )

  const dateSequence = useMemo(() => {
    const daysNumber = DAYS_NUMBER[pageId]
    return getDateSequence(daysNumber, language)
  }, [pageId, language])

  const imageLink = useMemo(() => {
    if (isCosmeticCohort) {
      return `${CDN_FOLDER_LINK}${COSMETIC_SCANNER_IMAGE_PATH}_${language}.png`
    }

    return keyword
      ? `${CDN_FOLDER_LINK}${GOOGLE_FUNNEL_IMAGE_PATH}.png`
      : `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`
  }, [isCosmeticCohort, keyword, language])

  return (
    <>
      <S.GoalDateContainer>
        <S.Goal>
          {t('onboarding.shortProgramDescription.goalDate', {
            goal: userGoalTitle,
            context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
          })}
        </S.Goal>
        {isStaticDate ? (
          <S.GoalDate>
            {t('onboarding.shortProgramDescription.goalDateCounter', {
              day: goalDate.day,
              month: goalDate.month,
            })}
          </S.GoalDate>
        ) : (
          <S.AnimationContainer>
            {dateSequence.map(({ day, month }) => (
              <S.GoalDate key={day}>
                {t('onboarding.shortProgramDescription.goalDateCounter', {
                  day,
                  month,
                })}
              </S.GoalDate>
            ))}
          </S.AnimationContainer>
        )}
      </S.GoalDateContainer>
      {!isCosmeticCohort && !isTiktokCohort && (
        <S.ProgramDescription>
          <Trans
            i18nKey="commonComponents.programDescription"
            components={[<br />]}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </S.ProgramDescription>
      )}
      <S.GraphContainer>
        <S.Graph src={imageLink} alt="graph" />
        <S.GoalText
          isShortGoalText={isShortGoalText}
          isFinalGoal={isCosmeticCohort}
        >
          {userGoalGraph}
        </S.GoalText>
      </S.GraphContainer>
    </>
  )
}
