import blondeWomen from 'assets/images/result-screen-blonde-woman.png'
import redHeadWomen from 'assets/images/result-screen-redhead-woman.png'

import { UserGoal } from 'root-constants'

export const QUESTION = 'Your personalized plan is ready!'

export const GRAPH_PATH = '/animations/result-graph-lose-weight'
export const WOMEN_IMAGE = {
  [UserGoal.WRINKLE_FREE_SKIN]: blondeWomen,
  [UserGoal.BOTH_OPTIONS]: blondeWomen,
  [UserGoal.REDUCE_FACE_FAT]: redHeadWomen,
}
