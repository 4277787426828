import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import {
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { NumberPurchasePlans } from 'modules/purchase/components/NumberPurchasePlans'
import { PersonalPurchaseBenefits } from 'modules/purchase/components/PersonalPurchaseBenefits'
import { PersonalPurchaseBonus } from 'modules/purchase/components/PersonalPurchaseBonus'
import { PersonalPurchasePoster } from 'modules/purchase/components/PersonalPurchasePoster'
import { PersonalPurchaseReviews } from 'modules/purchase/components/PersonalPurchaseReviews'
import { PersonalPurchaseSubtitle } from 'modules/purchase/components/PersonalPurchaseSubtitle'
import { PersonalPurchaseTimer } from 'modules/purchase/components/PersonalPurchaseTimer'
import { PersonalPurchaseTitle } from 'modules/purchase/components/PersonalPurchaseTitle'
import { ProfileSummary } from 'modules/purchase/components/ProfileSummary'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledPurchasePersonalEmail as S } from './PurchasePersonalEmail.styles'
import { REVIEWS } from './constants'

export const PurchasePersonalEmail = () => {
  const dispatch: TAppDispatch = useDispatch()
  const hasVatInfo = useVatInfo()
  const { pathname, search } = useLocation()
  const { t } = useTranslation()
  const { arePricesReady } = usePricesStatus()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)
  const {
    cohort,
    periodName,
    periodQuantity,
    price,
    hasCancelOffer,
    language,
  } = usePurchaseStore()

  const hasPaypalButton = useMemo(() => language === Locale.ENGLISH, [language])

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.EMAIL_OFFER,
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.EMAIL_OFFER))

    const vatTag = hasVatInfo
      ? SubscriptionTagsType.TAX
      : SubscriptionTagsType.NO_TAX

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, vatTag))
  }, [dispatch, hasVatInfo])

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch, setIsCheckoutShown])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.EMAIL_OFFER,
    })

    if (hasCancelOffer) {
      dispatch(setSubscriptionListAction([]))
      goTo({ pathname: PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER, search })
      return
    }

    setIsCheckoutShown(false)
  }, [
    dispatch,
    setIsCheckoutShown,
    pathname,
    cohort,
    productId,
    search,
    hasCancelOffer,
  ])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.Container>
            <PersonalPurchaseTitle />
            <PersonalPurchaseSubtitle />
            <NumberPurchasePlans />
          </S.Container>

          <PersonalPurchaseTimer />

          <PersonalPurchasePoster />
          <S.Container>
            <PersonalPurchaseBenefits />
            <PersonalPurchaseBonus />
            <SubscriptionsIntroOfferBlock />

            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>

            <PurchaseDisclaimer />

            <FeaturedBlock />
            <MoneyBackGuarantee />
            <ProfileSummary />
            <S.Subtitle>{t('purchase1.goalSubtitle')}</S.Subtitle>
            <UserGoalGraph pageId={PageId.PURCHASE} />
            <PersonalPurchaseReviews reviews={REVIEWS} />
            <S.PersonalEmailCustomerReviews
              customerReviews={DEFAULT_CUSTOMER_REVIEWS_V2}
              viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
            />

            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>

            <SecurityInfo />
          </S.Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
          hasPaypalButton={hasPaypalButton}
        />
      )}
    </>
  )
}
