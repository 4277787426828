import styled from 'styled-components'

import gift from 'assets/images/gift.svg'

import { Color } from 'root-constants'

interface IBounsItem {
  color: string
}

export const StyledPersonalPurchaseBonus = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  `,
  TitleContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    color: ${Color.BLUE_DARK};
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  `,
  Icon: styled.div`
    width: 45px;
    height: 45px;
    background-image: url(${gift});
  `,
  BonusContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  BonusItem: styled.div<IBounsItem>`
    display: flex;
    width: 320px;
    align-items: center;
    border-radius: 16px;
    border: 2px solid ${({ color }) => color};

    &:first-child {
      margin-bottom: 16px;
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  `,
  Image: styled.img`
    width: 86px;
    height: 114px;
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    margin-right: 16px;
  `,
  Text: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
}
