import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import {
  CURRENCY_SYMBOLS,
  PURCHASE_DISCLAIMER,
} from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodQuantity,
} from 'modules/purchase/redux/selects/common'

import appStoreIcon from 'assets/images/apple.png'
import googlePlayIcon from 'assets/images/google.png'
import starSvg from 'assets/images/sprite/star.svg'

import { StyledRatingInfo as S } from './RatingInfo.styles'

export const RatingInfo: React.FC = () => {
  const { t } = useTranslation()
  const price = useSelector(selectSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)
  const contactFormLink = getSupportFormLink()

  const disclaimerTextPath = useMemo(() => {
    if (!periodQuantity) return ''
    return t(PURCHASE_DISCLAIMER[periodQuantity])
  }, [t, periodQuantity])

  return (
    <S.Wrapper>
      <S.AppMarketContainer>
        <S.MarketItemContainer>
          <S.Image src={googlePlayIcon} alt="googlePlay" />
          <S.Label>{t`purchase1.ratingInfo.googlePlay`}</S.Label>
        </S.MarketItemContainer>
        <S.MarketItemContainer>
          <S.Image src={appStoreIcon} alt="appStore" />
          <S.Label>{t`purchase1.ratingInfo.appStore`}</S.Label>
        </S.MarketItemContainer>
      </S.AppMarketContainer>
      <S.RatingContainer>
        <S.Rating>
          <SvgImage svg={starSvg} />
          <SvgImage svg={starSvg} />
          <SvgImage svg={starSvg} />
          <SvgImage svg={starSvg} />
          <SvgImage svg={starSvg} />
        </S.Rating>
        <S.RatingDescription>
          <Trans
            i18nKey="purchase1.ratingInfo.ratingDescription"
            components={[<strong />]}
          />
        </S.RatingDescription>
      </S.RatingContainer>
      <S.DownloadsDescriptionText>
        <Trans
          i18nKey="purchase1.ratingInfo.downloadsText"
          components={[<strong />]}
        />
      </S.DownloadsDescriptionText>
      <S.Disclaimer>
        <Trans
          i18nKey={disclaimerTextPath}
          values={{
            price,
            currency: CURRENCY_SYMBOLS[currency],
            context: currency,
          }}
          components={[<br />]}
        />{' '}
        <TermsOfUseLink /> {t`commonComponents.or`}{' '}
        <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
          {t`commonComponents.contactSupportDisclaimer`}
        </a>
      </S.Disclaimer>
    </S.Wrapper>
  )
}
