import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { CustomerReviews } from 'components/CustomerReviews'
import {
  DEFAULT_CUSTOMER_REVIEWS,
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { NavigationButtons } from 'components/NavigationButtons'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { UserGoalGraph } from 'components/UserGoalGraph'

import { selectUserGoal } from 'root-redux/selects/common'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useKeywordValue } from 'hooks/useKeywordValue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { UserGoal } from 'root-constants'

import { StyledShortProgramDescription as S } from './ShortProgramDescription.styles'
import {
  QUESTIONS,
  SHORT_PROGRAM_DESCRIPTION_CONTEXT_TITLE_MAP,
  SHORT_PROGRAM_DESCRIPTION_DEFAULT_TITLE_TEXT,
} from './constants'

export const ShortProgramDescription: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const goal = useSelector(selectUserGoal)
  const keyword = useKeywordValue()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS[pageId],
    nextPagePath,
  })

  const keywordPageTitle = useMemo(() => {
    switch (goal) {
      case UserGoal.WRINKLE_FREE_SKIN:
        return t(`onboarding.shortProgramDescription.question.first.${keyword}`)
      case UserGoal.REDUCE_FACE_FAT:
        return t(
          `onboarding.shortProgramDescription.question.second.${keyword}`,
        )
      case UserGoal.BOTH_OPTIONS:
        return t(`onboarding.shortProgramDescription.question.both.${keyword}`)
      default:
        return t(`onboarding.shortProgramDescription.question.default`)
    }
  }, [goal, t, keyword])

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const pageTitle = useMemo(
    () =>
      t(
        SHORT_PROGRAM_DESCRIPTION_CONTEXT_TITLE_MAP[cohortToUse] ||
          SHORT_PROGRAM_DESCRIPTION_DEFAULT_TITLE_TEXT,
      ),
    [cohortToUse, t],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          {keyword ? keywordPageTitle : pageTitle}
        </S.TitleContainer>
        <S.DescriptionContainer>
          <PageTitleDescription>
            <Trans
              i18nKey="onboarding.shortProgramDescription.description"
              components={[<br />]}
            />
          </PageTitleDescription>
        </S.DescriptionContainer>
        <UserGoalGraph pageId={pageId} />
        <CustomerReviews
          customerReviews={
            keyword ? DEFAULT_CUSTOMER_REVIEWS_V2 : DEFAULT_CUSTOMER_REVIEWS
          }
          viewMoreCustomerReviews={
            keyword
              ? DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2
              : DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS
          }
        />
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        goToNextButtonText={t`onboarding.shortProgramDescription.goToNextButtonText`}
        disabled={false}
        buttonHeight={55}
      />
    </div>
  )
}
