import fireIcon from 'assets/images/fire-emoji.png'
import girlSkinIcon from 'assets/images/girl-skin-emoji.png'
import sparklesIcon from 'assets/images/sparkles-emoji.png'

export const QUESTION = 'Select your goal'

export const OPTION_VALUES = {
  GET_RID: 'get_rid_of_wrinkles',
  REDUCE_FAT: 'reduce_face_fat',
  BOTH_OPTIONS: 'both_options',
}

export const GOAL_ANSWERS_MAP = {
  [OPTION_VALUES.BOTH_OPTIONS]: {
    text: 'onboarding.goal.tightenSkin',
    icon: girlSkinIcon,
  },
  [OPTION_VALUES.REDUCE_FAT]: {
    text: 'onboarding.goal.loseFat',
    icon: fireIcon,
  },
  [OPTION_VALUES.GET_RID]: {
    text: 'onboarding.goal.getRid2',
    icon: sparklesIcon,
  },
}
