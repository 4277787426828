import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import {
  getUpgradeSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { TAppDispatch } from 'root-redux/store/store'

import { CancelOfferWhatYouGet } from 'modules/purchase/components/CancelOfferWhatYouGet'
import { HolidaysCancelOfferTestimonials } from 'modules/purchase/components/HolidaysCancelOfferTestimonials'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { UpgradeSubscriptionItem } from 'modules/purchase/components/UpgradeSubscriptionItem'
import { CURRENCY_SYMBOLS, MONTHS_IN_A_YEAR } from 'modules/purchase/constants'
import { usePricesStatus } from 'modules/purchase/hooks'
import { usePurchaseUpgradeAnalytics } from 'modules/purchase/hooks/usePurchaseUpgradeAnalytics'
import {
  DISCLAIMERS,
  YEARLY_SUBSCRIPTIONS_DISCLAIMERS,
} from 'modules/purchase/pages/PurchaseUpgrade/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseUpgrade,
} from 'modules/purchase/redux/actions/common'
import {
  selectUpgradeSubscriptionCurrency,
  selectUpgradeSubscriptionFullPrice,
  selectUpgradeSubscriptionPeriodName,
  selectUpgradeSubscriptionPeriodQuantity,
  selectUpgradeSubscriptionPrice,
} from 'modules/purchase/redux/selects/common'

import bgImg from 'assets/images/upgrade-bg.png'

import { CENTS_IN_DOLLAR, ScreenName, TimeInterval } from 'root-constants'

import { StyledPurchaseUpgrade as S } from './PurchaseUpgrade.styles'

export const PurchaseUpgrade: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const amountToPay = useSelector(selectUpgradeSubscriptionPrice)
  const fullPrice = useSelector(selectUpgradeSubscriptionFullPrice)
  const currency = useSelector(selectUpgradeSubscriptionCurrency)
  const periodQuantity = useSelector(selectUpgradeSubscriptionPeriodQuantity)
  const periodName = useSelector(selectUpgradeSubscriptionPeriodName)

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const { sendGeneralPurchaseEvents } = usePurchaseUpgradeAnalytics()

  const { arePricesReady } = usePricesStatus()

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(getUpgradeSubscriptionListAction())
  }, [dispatch])

  useEffect(() => {
    if (amountToPay && periodQuantity) {
      dispatch(
        sendUserConfigAction({
          subscription_upgrade_price: amountToPay / CENTS_IN_DOLLAR,
          subscription_upgrade_duration:
            periodName === TimeInterval.YEAR
              ? periodQuantity * MONTHS_IN_A_YEAR
              : periodQuantity,
        }),
      )
    }
  }, [amountToPay, dispatch, periodName, periodQuantity])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const upgradePlan = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    sendGeneralPurchaseEvents(false)

    addToCart()
  }, [addToCart, sendGeneralPurchaseEvents, dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      <S.Root>
        <S.Header />
        <S.Hero src={bgImg} alt="upgrade-bg" />

        <Container>
          <UpgradeSubscriptionItem />
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
          </S.ButtonContainer>
        </Container>

        <CancelOfferWhatYouGet
          buttonTitle={t('actions.getStartedNow')}
          handleShowCheckout={upgradePlan}
        />
        <S.HolidaysCancelOfferReviews />

        <Container>
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
          </S.ButtonContainer>
        </Container>

        <HolidaysCancelOfferTestimonials />

        <S.Container>
          <UpgradeSubscriptionItem />
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
          </S.ButtonContainer>
          <S.SecurityInfo
            withPricing
            disclaimerText={t(
              periodName === TimeInterval.YEAR
                ? YEARLY_SUBSCRIPTIONS_DISCLAIMERS[periodQuantity]
                : DISCLAIMERS[periodQuantity],
              {
                amountToPay: amountToPay / CENTS_IN_DOLLAR,
                fullPrice,
                currency: CURRENCY_SYMBOLS[currency],
              },
            )}
            hasTermsOfUse
          />
        </S.Container>
      </S.Root>
      <PaymentWaitingModal
        resetErrorCallback={addToCart}
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
      />
    </>
  )
}
