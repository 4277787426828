import styled from 'styled-components'

import presaleBg from 'assets/images/presale-plan-bg.png'

import { Color } from 'root-constants'

export const StyledPresale = {
  Header: styled.h2`
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: ${Color.PRIMARY};
    margin-bottom: 8px;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
  `,
  PlanCard: styled.div`
    background-image: url(${presaleBg});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    max-width: 360px;
    min-height: 532px;
    margin: 0 auto;
    padding: 0 20px 52px;

    img {
      width: 100%;
      border-bottom: 1px solid ${Color.WHITE};
    }
  `,
  OptionsList: styled.div`
    padding: 8px 12px;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.WHITE};
  `,
  OptionTitle: styled.div`
    min-width: 114px;
    margin-right: 8px;
    font-weight: 500;
  `,
  OptionValue: styled.div`
    font-weight: 400;
  `,
  ProgramBenefitsTitle: styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${Color.DARK};
  `,
  ButtonContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 40px;

    button {
      font-size: 18px;
    }
  `,
}
