import React, { FC } from 'react'
import { Trans } from 'react-i18next'

import { StyledNumberPurchasePlans as S } from './NumberPurchasePlans.style'

type TProps = {
  classNames?: string
}

export const NumberPurchasePlans: FC<TProps> = ({ classNames = '' }) => {
  return (
    <S.Container className={classNames}>
      <Trans
        i18nKey="purchasePersonal.numberPurchasePlans"
        components={[<strong />]}
      />
    </S.Container>
  )
}
