import { UserAge } from 'root-constants'

export const TRACKED_BREAKPOINTS: number[] = [16, 33, 50, 66, 83, 100]

export const NUMBER_ONE_APP_IMAGE_PATH = '/images/face-yoga-app-number-one'

export const HIGH_PRICES_AGES: UserAge[] = [
  UserAge.THIRTY,
  UserAge.FOURTY,
  UserAge.FIFTY,
]

export const ES_REGION_TAG = 'es'

export const DEFAULT_DISCOUNT = 50
export const CANCEL_DEFAULT_DISCOUNT = 60
