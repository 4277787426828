import React from 'react'
import { Trans } from 'react-i18next'

import { StyledCancelOfferDiscountInfo as S } from './CancelOfferDiscountInfo.styles'

export const CancelOfferDiscountInfo = () => {
  return (
    <S.Root data-testid="cancel-offer-container">
      <Trans
        i18nKey="purchase7.cancelOfferDiscount"
        components={[<i />, <strong />]}
      />
    </S.Root>
  )
}
