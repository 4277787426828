import styled from 'styled-components'

import safetyBadge from 'assets/images/safety-badge.png'
import warningFingerIcon from 'assets/images/warning-finger-emoji.png'

import { Color } from 'root-constants'

export const StyledPersonalizedEmail = {
  Container: styled.div`
    margin: 0 auto 16px;
    max-width: 360px;
    padding: 0 20px;
  `,
  TitleContainer: styled.header`
    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  InputContainer: styled.div`
    position: relative;
    margin: 40px 0 24px;
  `,
  Disclaimer: styled.div`
    padding: 8px;
    background-color: #f1eaff;
    border-radius: 10px;
    margin-bottom: 16px;
  `,
  DisclaimerData: styled.div`
    padding-left: 24px;
    position: relative;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${warningFingerIcon}) center no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
  DisclaimerTitle: styled.h3`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  DisclaimerDescription: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.LIGHT};
  `,
  Secure: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.LIGHT};
    padding-left: 28px;
    position: relative;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${safetyBadge}) center no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  `,
  ButtonContainer: styled.div`
    position: relative;
    padding: 40px 0;
  `,
}
