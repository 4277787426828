import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'

import { resetErrorAction, stopFetching } from 'root-redux/actions/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PaymentMethod } from 'modules/purchase/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'
import { TPaymentRequestButton } from 'modules/purchase/types'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR } from 'root-constants'

import { StyledStripeSeparatePaymentRequestButton as S } from './StripeSeparatePaymentRequestButton.styles'

type TProps = {
  buttonHeight?: number
  borderRadius?: number
  setAlternativePaymentMethodCallback?: (
    paymentMethod: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY,
  ) => void
}

export const StripeSeparatePaymentRequestButton: React.FC<TProps> = ({
  buttonHeight = 55,
  borderRadius = 12,
  setAlternativePaymentMethodCallback,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedPrice = useSelector(selectSubscriptionFullPrice)
  const currency = useSelector(selectCurrency)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const [paymentRequest, setPaymentRequest] = useState<any>(null)
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const planPeriodDescription = useMemo(() => {
    if (!periodQuantity) return ''

    return t('purchase1.paymentForm.planDescription', {
      context: periodQuantity,
      periodQuantity,
      periodName,
    })
  }, [t, periodQuantity, periodName])

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe?.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: planPeriodDescription,
        amount: calculatedPrice,
      },
    })

    pr?.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)

        if (setAlternativePaymentMethodCallback) {
          result.applePay
            ? setAlternativePaymentMethodCallback(PaymentMethod.APPLE_PAY)
            : setAlternativePaymentMethodCallback(PaymentMethod.GOOGLE_PAY)
        }
      }
      dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    })

    pr?.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )

      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({ notify: 'event', name: 'start_checkout' })
    })
  }, [
    dispatch,
    stripe,
    elements,
    planPeriodDescription,
    calculatedPrice,
    currentPageId,
    currency,
    setAlternativePaymentMethodCallback,
  ])

  const handleButtonClick = useCallback(() => {
    const shownButtonType = buttonTypes?.applePay
      ? PaymentMethod.APPLE_PAY
      : PaymentMethod.GOOGLE_PAY

    eventLogger.logPaymentMethodSelected(shownButtonType)
  }, [buttonTypes])

  return (
    <div>
      {paymentRequest && (
        <S.Wrapper buttonHeight={buttonHeight} borderRadius={borderRadius}>
          <PaymentRequestButtonElement
            onClick={handleButtonClick}
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  height: `${buttonHeight}px`,
                },
              },
            }}
          />
        </S.Wrapper>
      )}
    </div>
  )
}
