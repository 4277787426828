import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { Locale } from 'root-constants'

import { StyledLongPaywallCustomerReviews as S } from './LongPaywallCustomerReviews.styles'
import { LongPaywallCustomerReview } from './Review'

export type TReview = {
  name: string
  icon: string
  review: string
  emoji?: string[]
}

type TLongPaywallCustomerReviewsProps = {
  title: string
  customerReviews: TReview[]
  additionalCustomerReviews: TReview[]
}

export const LongPaywallCustomerReviews: React.FC<
  TLongPaywallCustomerReviewsProps
> = ({
  title,
  customerReviews,
  additionalCustomerReviews,
}: TLongPaywallCustomerReviewsProps) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const [areAdditionalReviewsShown, setAreAdditionalReviewsShown] =
    useState(false)

  const handleViewMoreClick = useCallback(
    () => setAreAdditionalReviewsShown(true),
    [],
  )

  return (
    <S.Wrapper isEnLocaleStyle={isEnLocale}>
      <S.Title isEnLocaleStyle={isEnLocale}>{t(title)}</S.Title>
      {customerReviews.map((review) => (
        <LongPaywallCustomerReview
          key={review.name}
          name={review.name}
          review={review.review}
          emoji={review.emoji}
        />
      ))}
      {!areAdditionalReviewsShown ? (
        <S.ViewMoreLinkContainer>
          <S.ViewMoreLink onClick={handleViewMoreClick}>
            {t`onboarding.shortProgramDescription.viewMoreLink`}
          </S.ViewMoreLink>
        </S.ViewMoreLinkContainer>
      ) : (
        <>
          {additionalCustomerReviews.map((review) => (
            <LongPaywallCustomerReview
              key={review.name}
              name={review.name}
              review={review.review}
              emoji={review.emoji}
            />
          ))}
        </>
      )}
    </S.Wrapper>
  )
}
