import { IRequestOptions, IResponseResult } from 'models/api.model'
import { TAnswers } from 'models/common.model'
import { IInAppPurchaseUserData } from 'models/subscriptions.model'
import {
  IBindUserResponseRaw,
  IGetUserStatusResponseRaw,
} from 'models/user.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod, TEST_ENV_QUERY_PARAM } from 'root-constants'

export class UserApi {
  private api: ApiService
  private readonly baseUrlV2 = 'v2/leads'

  constructor(api: ApiService) {
    this.api = api
  }

  bindUser(payload: {
    token: string
    uuid: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token: payload.token,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>(
      `${this.baseUrlV2}/${payload.uuid}/${APP_NAME}/signup`,
      options,
    )
  }

  savePlanAdditions({
    uuid,
    planAdditions,
  }: {
    uuid: string
    planAdditions: string[]
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { addons: planAdditions },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/addons`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<string>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}`,
      options,
    )
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/facebook`,
      options,
    )
  }

  saveUserAnswers({
    uuid,
    answers,
    isFinished = false,
    isSkipped = false,
  }: {
    uuid: string
    answers: TAnswers
    isFinished?: boolean
    isSkipped?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: answers,
        version: '2.0',
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/quiz`,
      options,
    )
  }

  saveUserConfig({
    uuid,
    config,
  }: {
    uuid: string
    config: Record<
      string,
      string | boolean | number | string[] | Record<string, string>
    >
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        config,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/config`,
      options,
    )
  }

  saveUserEmail({
    uuid,
    email,
    consentRequired,
  }: {
    uuid: string
    email: string
    consentRequired?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email,
        consent_required: consentRequired,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/email`,
      options,
    )
  }

  saveUserEmailConsent({
    uuid,
    consented,
  }: {
    uuid: string
    consented: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        consented,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/email/consent`,
      options,
    )
  }

  getUserUUIDAndSecret(
    token: string,
  ): Promise<IResponseResult<{ secret: string; uuid: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { token },
    }

    return this.api.makeRequest<{ secret: string; uuid: string }>(
      `v1/manage/login`,
      options,
    )
  }

  getUUID({
    cohort,
    appId,
    locale,
    promoCode = '',
    utm,
  }: {
    cohort: string
    appId: string
    locale: string
    promoCode?: string
    utm: {
      [key: string]: string
    }
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        locale,
        app_id: appId,
        app_name: APP_NAME,
        utm,
        promo_code: promoCode,
        is_test_environment:
          document.location.search.includes(TEST_ENV_QUERY_PARAM),
      },
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      this.baseUrlV2,
      options,
    )
  }

  saveUserTTCLID({
    ttclid,
    uuid,
  }: {
    ttclid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ttclid },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/tiktok`, options)
  }

  saveUserRDTCID({
    rdtcid,
    uuid,
  }: {
    rdtcid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { rdt_cid: rdtcid },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/reddit`, options)
  }

  saveUserSCCID({
    sccid,
    scid,
    uuid,
  }: {
    sccid: string
    scid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { sccid, uuid_c1: scid },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/snapchat`, options)
  }

  saveUserAddress({
    uuid,
    inAppPurchaseUserData: {
      country,
      firstName,
      lastName,
      address,
      optionalAddress,
      city,
      zipCode,
      phone,
      state,
    },
  }: {
    uuid: string
    inAppPurchaseUserData: IInAppPurchaseUserData
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        address_line1: address,
        city,
        country,
        first_name: firstName,
        last_name: lastName,
        phone,
        state,
        zip_code: zipCode,
        ...(optionalAddress && {
          address_line2: optionalAddress,
        }),
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${APP_NAME}/address`,
      options,
    )
  }
}
