import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'

import {
  selectHasInAppOffer,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectIsInAppAvailable } from 'root-redux/selects/user'

import { UpsellSubscriptionItemSwitchers } from 'modules/purchase/components/UpsellSubscriptionItemSwitchers'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName, UpsellProduct } from 'root-constants'

import { StyledUpsellSubscriptionBlockSwitchers as S } from './UpsellSubscriptionBlockSwitchers.styles'
import {
  MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
  NUMBER_OF_SINGLE_SUBSCRIPTION,
} from './constants'

type TPageProps = {
  makeUpsell: () => void
}

export const UpsellSubscriptionBlockSwitchers: React.FC<TPageProps> = ({
  makeUpsell,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const subscriptions = useSelector(selectSubscriptionList)
  const currency = useSelector(selectCurrency)
  const isInAppAvailable = useSelector(selectIsInAppAvailable)
  const hasInAppOffer = useSelector(selectHasInAppOffer)

  const [selectedPrices, setSelectedPrices] = useState<string[]>([])
  const [isSwitchersOn, setIsSwitchersOn] = useState(false)

  const doubleChinAndFaceLifting = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.MIX,
  )
  // Default subscription on this page
  const doubleChinSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.CHIN_SHAPER,
  )
  const faceLiftingSubscription = subscriptions.find(
    (subscription) => subscription.product === UpsellProduct.FACE_LIFTING,
  )
  const isSecondaryButtonVisible = useMemo(
    () => selectedPrices.length < MAX_NUMBER_OF_SELECTED_SUBSCRIPTIONS,
    [selectedPrices],
  )

  // Set default subscription to store and local state
  useEffect(() => {
    if (doubleChinSubscription) {
      dispatch(setSelectedSubscriptionAction(doubleChinSubscription))
      setSelectedPrices((current) => [...current, doubleChinSubscription?.id])
    }
  }, [dispatch, doubleChinSubscription])

  useEffect(() => {
    const isSingleSubscriptionSelected =
      selectedPrices.length === NUMBER_OF_SINGLE_SUBSCRIPTION
    if (isSingleSubscriptionSelected) {
      const [selectedPriceId] = selectedPrices
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === selectedPriceId,
      )
      dispatch(
        setSelectedSubscriptionAction(selectedSubscription as ISubscription),
      )

      return
    }

    doubleChinAndFaceLifting &&
      dispatch(setSelectedSubscriptionAction(doubleChinAndFaceLifting))
  }, [subscriptions, selectedPrices, doubleChinAndFaceLifting, dispatch])

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const isPriceSelected = selectedPrices.includes(value)
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      if (!isPriceSelected) {
        selectedSubscription &&
          eventLogger.logUpsellSubscriptionEnable(selectedSubscription?.product)
        setSelectedPrices((current) => [...current, value])

        return
      }
      selectedSubscription &&
        eventLogger.logUpsellSubscriptionDisable(selectedSubscription?.product)
      setSelectedPrices((current) => [...current.filter((id) => id !== value)])
    },
    [selectedPrices, subscriptions],
  )

  const handleContinue = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)
    goTo({
      pathname:
        isInAppAvailable && hasInAppOffer
          ? PageId.IN_APP_PAYWALL
          : PageId.FINISHING_TOUCHES,
      search,
    })
  }, [search, isInAppAvailable, hasInAppOffer])

  const handleContinueWithAll = useCallback(() => {
    setIsSwitchersOn(true)
    doubleChinAndFaceLifting &&
      dispatch(setSelectedSubscriptionAction(doubleChinAndFaceLifting))
    makeUpsell()
  }, [doubleChinAndFaceLifting, makeUpsell, dispatch])

  return (
    <>
      <S.Discount>
        <S.DiscountBadge>
          <Trans
            i18nKey="upsell.discount.badge"
            components={{ discount: <div /> }}
          />
        </S.DiscountBadge>
        <S.DiscountText>
          <Trans
            i18nKey="upsell.discount.text"
            values={{
              price: doubleChinAndFaceLifting?.mainPrices.fullPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
            components={[<br />]}
          />
        </S.DiscountText>
      </S.Discount>

      {doubleChinSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={doubleChinSubscription.id}
          onChange={handleChange}
          key={doubleChinSubscription.id}
          subscription={doubleChinSubscription}
          isSelected={
            selectedPrices.includes(doubleChinSubscription.id) || isSwitchersOn
          }
        />
      )}

      {faceLiftingSubscription && (
        <UpsellSubscriptionItemSwitchers
          value={faceLiftingSubscription.id}
          onChange={handleChange}
          key={faceLiftingSubscription.id}
          subscription={faceLiftingSubscription}
          isSelected={
            selectedPrices.includes(faceLiftingSubscription.id) || isSwitchersOn
          }
        />
      )}
      <S.ButtonContainer>
        <Button
          data-testid="upsell-accept-all-btn"
          onClick={handleContinueWithAll}
        >
          {t`upsell.actions.acceptAll`}
        </Button>
      </S.ButtonContainer>

      {isSecondaryButtonVisible && (
        <S.ButtonContainer>
          <S.SecondaryButton
            onClick={!selectedPrices.length ? handleContinue : makeUpsell}
          >
            {!selectedPrices.length
              ? t('upsell.actions.withoutCourses')
              : t('upsell.actions.acceptSelected')}
          </S.SecondaryButton>
        </S.ButtonContainer>
      )}
      <S.BottomActions>
        <S.ButtonContainer>
          {!selectedPrices.length && (
            <Button onClick={handleContinueWithAll}>
              {t`upsell.buttonText2`}
            </Button>
          )}
          {!!selectedPrices.length && (
            <Button onClick={makeUpsell}>{t`upsell.buttonText`}</Button>
          )}
        </S.ButtonContainer>
        <S.LinkContainer>
          <S.Link onClick={handleContinue}>{t`upsell.noThanksLink`}</S.Link>
        </S.LinkContainer>
      </S.BottomActions>
    </>
  )
}
