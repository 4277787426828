import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import PartyEmoji from 'assets/images/party-popper_emoji.png'
import TargetGoalGraph from 'assets/images/target-goal-graph.png'

import { BottomButtonContainer } from 'common-styles'

import { StyledTargetGoal as S } from './TargetGoal.styles'
import { QUESTION } from './constants'

export const TargetGoal: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <S.Wrapper>
      <S.PageTitle>{t`onboarding.targetGoal.question`}</S.PageTitle>
      <S.TagLine>
        {t`onboarding.targetGoal.tagLine`}
        <S.Emoji src={PartyEmoji} alt="party-emoji" />
      </S.TagLine>
      <S.ImageContainer>
        <S.GraphImage src={TargetGoalGraph} alt="target-goal-graph" />
      </S.ImageContainer>
      <S.Description>{t`onboarding.targetGoal.description`}</S.Description>
      <BottomButtonContainer>
        <Button onClick={handleNextClick}>
          {t`onboarding.targetGoal.action`}
        </Button>
      </BottomButtonContainer>
    </S.Wrapper>
  )
}
