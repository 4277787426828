import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import personalPurchaseBonus from 'assets/images/personal-purchase-bonus.png'

import { StyledPersonalPurchaseBonus as S } from './PersonalPurchaseBonus.style'

export const PersonalPurchaseBonus: FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.TitleContainer>
        <S.Title>{t`purchasePersonal.bonusTitle`}</S.Title>
        <S.Icon />
      </S.TitleContainer>
      <S.BonusContainer>
        <S.BonusItem color="#9687E033">
          <S.Image src={personalPurchaseBonus} />
          <S.Text>{t`purchasePersonal.bonusText`}</S.Text>
        </S.BonusItem>
      </S.BonusContainer>
    </S.Wrapper>
  )
}
