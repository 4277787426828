import styled from 'styled-components'

export const StyledPersonalPurchaseCustomerReviews = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
  `,
}
