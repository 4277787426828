import exercisesFromExperts from 'assets/images/exercises-from-experts.png'
import skincareChallenges from 'assets/images/skincare-challenges.png'
import videoCourses from 'assets/images/video-courses.png'
import yogaProgram from 'assets/images/yoga-program.png'

export const PLAN_BENEFITS = [
  {
    id: '1',
    img: yogaProgram,
    textPath: 'presale.yogaProgram',
  },
  {
    id: '2',
    img: exercisesFromExperts,
    textPath: 'presale.exercisesFromExperts',
  },
  {
    id: '3',
    img: videoCourses,
    textPath: 'presale.videoCourses',
  },
  {
    id: '4',
    img: skincareChallenges,
    textPath: 'presale.skincareChallenges',
  },
]
