import dayjs from 'dayjs'
import enLocale from 'dayjs/locale/en'
import esLocale from 'dayjs/locale/es'
import frLocale from 'dayjs/locale/fr'
import itLocale from 'dayjs/locale/it'

import { DATES_NUMBER_FOR_ANIMATION } from 'root-constants'

interface IDateFormat {
  day: string
  month: string
}

const LOCALES_MAP = {
  en: enLocale,
  fr: frLocale,
  es: esLocale,
  it: itLocale,
}

export const getDateFromTimeStamp = (
  timeStamp: number,
  language: string,
): string =>
  timeStamp
    ? dayjs.unix(timeStamp).locale(LOCALES_MAP[language]).format('MMMM D, YYYY')
    : ''

export const addDaysToCurrentDate = (
  days: number,
  language: string,
): IDateFormat => {
  const activeLocale = LOCALES_MAP[language]
  const calculatedDate = dayjs().locale(activeLocale).add(days, 'day')
  const day = calculatedDate.format('D')
  const month = calculatedDate.format('MMMM')

  return { month, day }
}

export const getDateSequence = (days: number, language = 'en'): IDateFormat[] =>
  Array.from({ length: DATES_NUMBER_FOR_ANIMATION }, (item, i) =>
    addDaysToCurrentDate(days + i, language),
  )
