import styled, { css } from 'styled-components'

import chevronRight from 'assets/images/chevron-right-coral.png'

import { Color, MediaBreakpoint } from 'root-constants'

type TLivechatStylesProps = {
  isLivechatUpsell: boolean
}

export const StyledUpsellGuasha = {
  Root: styled.div<TLivechatStylesProps>`
    ${({ isLivechatUpsell }) =>
      isLivechatUpsell
        ? css`
            margin-top: -15px;
          `
        : css`
            padding-top: 4px;
          `}
  `,
  Wrapper: styled.div`
    padding-bottom: 40px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  SkipButton: styled.button`
    all: unset;
    display: flex;
    align-items: center;
    margin: 0 auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    color: ${Color.PRIMARY};
    position: absolute;
    top: 13px;
    right: 10px;
    z-index: 5;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: url(${chevronRight}) no-repeat center;
      background-size: contain;
      /* position: absolute;
      top: 50%;
      left: 17px;
      transform: translateY(-50%); */
    }
  `,
  Hero: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    z-index: 1;
  `,

  GallerySubtitle: styled.h3`
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  `,
  CTAImage: styled.img`
    display: block;
    max-width: 328px;
    width: 100%;
    object-fit: cover;
    object-position: center top;
    border-radius: 12px;
    margin: 0 auto 24px;
    max-height: 192px;
  `,
  CTA: styled.h3`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;

    & > strong {
      color: ${Color.PRIMARY};
    }
  `,
}
