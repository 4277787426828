import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CustomerReviews } from 'components/CustomerReviews'

import { selectAnswers } from 'root-redux/selects/common'

import {
  AGE_VALUES,
  CUSTOMER_REVIEWS,
  CUSTOMER_REVIEWS_MORE,
} from 'modules/purchase/pages/PersonalPurchase/constants'

import { PageId } from 'page-constants'

import { StyledPersonalPurchaseCustomerReviews as S } from './PersonalPurchaseCustomerReviews.style'

export const PersonalPurchaseCustomerReviews: FC = () => {
  const userAnswer = useSelector(selectAnswers)

  const age = useMemo(() => {
    if (!userAnswer || !userAnswer.age) return AGE_VALUES.TWENTY

    return (
      Object.values(AGE_VALUES).find(
        (option) => option === userAnswer[PageId.AGE],
      ) || AGE_VALUES.TWENTY
    )
  }, [userAnswer])

  const reviews = useMemo(() => {
    if (age > AGE_VALUES.SIXTY) return CUSTOMER_REVIEWS[AGE_VALUES.SIXTY]
    return CUSTOMER_REVIEWS[age] || CUSTOMER_REVIEWS[AGE_VALUES.TWENTY]
  }, [age])

  const moreReviews = useMemo(() => {
    if (age > AGE_VALUES.SIXTY) return CUSTOMER_REVIEWS_MORE[AGE_VALUES.SIXTY]
    return (
      CUSTOMER_REVIEWS_MORE[age] || CUSTOMER_REVIEWS_MORE[AGE_VALUES.TWENTY]
    )
  }, [age])

  return (
    <S.Wrapper>
      <CustomerReviews
        customerReviews={reviews}
        viewMoreCustomerReviews={moreReviews}
      />
    </S.Wrapper>
  )
}
