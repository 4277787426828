import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import {
  selectDynamicDiscount,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import {
  Locale,
  MINIMUM_COUNT_OF_MONTH,
  SubscriptionProduct,
} from 'root-constants'

import { StyledIntroOfferSelectSubscriptionItem as S } from './IntroOfferSelectSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const IntroOfferSelectSubscriptionItem: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const { isIntroSaleCohort } = useActiveCohortIdentifier()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)
  const language = useSelector(selectLanguage)

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const isMxnCurrency = useMemo(() => currency === Currency.MXN, [currency])

  const getCalculatedPrice = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo) return fullPrice

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, taxAmount],
  )

  const getMonthlyPeriodKey = useMemo(() => {
    return mainPrices.periodQuantity > MINIMUM_COUNT_OF_MONTH
      ? 'purchaseIntroOffer.multiMonthlyPeriod'
      : 'purchaseIntroOffer.monthlyPeriod'
  }, [mainPrices.periodQuantity])

  const getOldPrice = useCallback(
    ({
      price,
      isFormattedDiscount,
    }: {
      price: number
      isFormattedDiscount?: boolean
    }) => {
      const discount = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT
      const calculatedOldPrice =
        price / ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

      if (!isFormattedDiscount) {
        return getCalculatedPrice(calculatedOldPrice).toFixed(2)
      }

      return (
        Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      ).toFixed(2)
    },
    [dynamicDiscount?.amount, getCalculatedPrice],
  )

  const oldPrices = useMemo(() => {
    if (isIntroSaleCohort) {
      return {
        oldDailyPrice: getOldPrice({ price: trialPrices.daily }),
        oldSubscriptionPrice: getOldPrice({
          price: trialPrices.fullPrice,
          isFormattedDiscount: true,
        }),
      }
    }
    return {
      oldDailyPrice: getCalculatedPrice(trialPrices.oldPrices.daily),
      oldSubscriptionPrice:
        trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
          ? getCalculatedPrice(trialPrices.oldPrices.fullPrice)
          : getCalculatedPrice(mainPrices.fullPrice),
    }
  }, [
    getCalculatedPrice,
    getOldPrice,
    isIntroSaleCohort,
    mainPrices.fullPrice,
    trialPrices,
  ])

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge>{t`purchaseIntroOffer.subscription.mostPopularBadge`}</S.MostPopularBadge>
      )}
      <S.Content
        isSelected={isSelected}
        isDefault={isDefault}
        isEnLocaleStyle={isEnLocale}
      >
        <S.PlanContainer isEnLocaleStyle={isEnLocale}>
          <S.PlanPeriod isSelected={isSelected}>
            {trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
              ? t('purchaseIntroOffer.weeklyPeriod', {
                  trialPeriodDays: trialPrices.durationDays,
                })
              : t(getMonthlyPeriodKey, {
                  count: mainPrices.periodQuantity,
                })}
          </S.PlanPeriod>
          <S.PricesComparisonWrapper>
            <S.PreviousPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.oldPrice"
                values={{
                  oldPrice: oldPrices.oldSubscriptionPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>

            <S.CurrentPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: getCalculatedPrice(trialPrices.fullPrice),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
            {!isMxnCurrency && (
              <S.OldestPrice>
                <Trans
                  i18nKey="purchaseIntroOffer.subscription.oldestPrice"
                  values={{
                    oldestPrice: oldPrices.oldDailyPrice,
                    currency: CURRENCY_SYMBOLS[currency],
                  }}
                />
              </S.OldestPrice>
            )}
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.CustomPrice isSelected={isSelected} isMxnCurrency={isMxnCurrency}>
          <S.PriceValue isMxnCurrency={isMxnCurrency}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getCalculatedPrice(trialPrices.daily),
            })}
          </S.PriceValue>
          <S.Period>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
