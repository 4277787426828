import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscription,
  selectSubscriptionFullPrice,
} from 'modules/purchase/redux/selects/common'

import liftingProgram from 'assets/images/face-lifting-program.png'
import morningFace from 'assets/images/morning-face-massage.png'

import { UpsellProduct } from 'root-constants'

import { StyledUpsellWithTimerBenefits as S } from './UpsellWithTimerBenefits.styles'
import { BENEFITS_MAP } from './constants'

type TUpsellProduct =
  | UpsellProduct.CHIN_SHAPER
  | UpsellProduct.FACE_LIFTING
  | UpsellProduct.MIX

export const UpsellWithTimerBenefits: React.FC = () => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const subscription = useSelector(selectSubscription)
  const contactFormLink = getSupportFormLink()

  const isBothProductsActive = useMemo(
    () => subscription?.product === UpsellProduct.MIX,
    [subscription],
  )

  const isChinShaperActive = useMemo(
    () => subscription?.product === UpsellProduct.CHIN_SHAPER,
    [subscription],
  )

  const isFaceLiftingActive = useMemo(
    () => subscription?.product === UpsellProduct.FACE_LIFTING,
    [subscription],
  )

  return (
    <>
      <S.BenefitsWrapper>
        <S.BenefitsSubtitle>{t`upsellWithTimer.benefits.title`}</S.BenefitsSubtitle>
        <S.List>
          {BENEFITS_MAP[
            (subscription?.product as TUpsellProduct) || UpsellProduct.MIX
          ].map(({ id, text }) => (
            <S.ListItem key={id}>
              <Trans i18nKey={text} components={[<strong />]} />
            </S.ListItem>
          ))}
        </S.List>
      </S.BenefitsWrapper>
      <S.Details>
        {(isBothProductsActive || isChinShaperActive) && (
          <S.Detailed>
            <S.DetailedInfo>
              <S.DetailedTitle>{t`upsell.benefits.details.title1`}</S.DetailedTitle>
              <S.DetailedDescription>
                {t`upsell.benefits.details.description1`}
              </S.DetailedDescription>
            </S.DetailedInfo>
            <S.DetailedPicture src={morningFace} alt="" />
          </S.Detailed>
        )}

        {(isBothProductsActive || isFaceLiftingActive) && (
          <S.Detailed>
            <S.DetailedPicture src={liftingProgram} alt="" />
            <S.DetailedInfo>
              <S.DetailedTitle>{t`upsell.benefits.details.title2`}</S.DetailedTitle>
              <S.DetailedDescription>
                {t`upsell.benefits.details.description2`}
              </S.DetailedDescription>
            </S.DetailedInfo>
          </S.Detailed>
        )}
      </S.Details>
      <S.Disclaimer>
        <Trans
          i18nKey="upsell.disclaimer"
          values={{
            currentPrice,
            currency: CURRENCY_SYMBOLS[currency],
            context: currency,
          }}
          components={[<br />]}
        />{' '}
        <TermsOfUseLink /> {t`commonComponents.or`}{' '}
        <S.SupportLink
          target="_blank"
          rel="noopener noreferrer"
          href={contactFormLink}
        >
          {t`commonComponents.contactSupportDisclaimer`}
        </S.SupportLink>
      </S.Disclaimer>
    </>
  )
}
