import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import { selectActionList, selectScreenName } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CardPaymentFormSeparateCheckout } from 'modules/purchase/components/CardPaymentFormSeparateCheckout'
import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import {
  PaymentWaitingModal,
  PrimerPaymentWaitingModal,
} from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
  PaymentMethod,
  PaymentSystem,
  PrimerFormVariants,
} from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { Cohort, ScreenName, SubscriptionProduct } from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import {
  DYNAMIC_DISCOUNT_DEFAULT_TEXT,
  PAYMENT_METHODS_IMAGES,
} from './constants'

type TCheckoutExtended = {
  paymentSystem?: PaymentSystem
  handleCloseCheckout: () => void
  hasPaypalButton?: boolean
}

type TAlternativePaymentMethod =
  | PaymentMethod.APPLE_PAY
  | PaymentMethod.GOOGLE_PAY

export const CheckoutSeparateMethods: React.FC<TCheckoutExtended> = ({
  paymentSystem = PaymentSystem.STRIPE,
  handleCloseCheckout,
  hasPaypalButton = false,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)
  const hasVatInfo = useVatInfo()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const { isIntroSaleCohort } = useActiveCohortIdentifier()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [alternativePaymentMethod, setAlternativePaymentMethod] =
    useState<TAlternativePaymentMethod | null>(null)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)

  useScrollToTop()

  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
    taxAmount,
    discountAmount,
    discountPercentage,
  } = usePurchaseStore()

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const isCardSelected = useMemo(
    () => selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
    [selectedPaymentMethod],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  const getCalculatedPrice = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo) return fullPrice

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, taxAmount],
  )

  useLayoutEffect(() => {
    if (alternativePaymentMethod) {
      setSelectedPaymentMethod(alternativePaymentMethod)
      return
    }
    setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
  }, [alternativePaymentMethod])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }, [])

  const getPriceWithFormattedDiscount = useCallback(
    (subscriptionPrice: number, discount: number) => {
      const calculatedOldPrice =
        subscriptionPrice /
        ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

      return (
        Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      )
    },
    [getCalculatedPrice],
  )

  const subscription = useMemo(() => {
    if (isIntroSaleCohort && selectedSubscription) {
      const percentage = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT
      const totalAmount = getPriceWithFormattedDiscount(
        selectedSubscription.trialPrices.fullPrice,
        percentage,
      )

      return {
        percentage,
        totalAmount: totalAmount.toFixed(2),
        discountAmount: (
          totalAmount - selectedSubscription.trialPrices.fullPrice
        ).toFixed(2),
      }
    }

    if (selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount:
          selectedSubscription.trialPrices.durationDays ===
          SubscriptionProduct.SEVEN_DAY
            ? getCalculatedPrice(
                selectedSubscription.trialPrices.oldPrices.fullPrice,
              )
            : getCalculatedPrice(selectedSubscription.mainPrices.fullPrice),
        discountAmount,
      }
    }

    return {
      percentage: discountPercentage || 50,
      totalAmount: 0,
      discountAmount,
    }
  }, [
    discountAmount,
    discountPercentage,
    dynamicDiscount?.amount,
    getCalculatedPrice,
    getPriceWithFormattedDiscount,
    isIntroSaleCohort,
    selectedSubscription,
  ])

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {alternativePaymentMethod
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          {alternativePaymentMethod && (
            <S.PaymentMethodsWrapper>
              <S.PaymentMethod
                isSelected={isCardSelected}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <S.PaymentMethodText>
                  {t('checkoutExtended.creditCard')}
                </S.PaymentMethodText>
                <S.PaymentMethodImage
                  src={
                    PAYMENT_METHODS_IMAGES[PaymentMethod.CREDIT_CARD][
                      isCardSelected ? 'active' : 'inactive'
                    ]
                  }
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
              <S.PaymentMethod
                isSelected={!isCardSelected}
                onClick={() => setPaymentMethod(alternativePaymentMethod)}
              >
                <S.AlternativePaymentMethodImage
                  src={
                    PAYMENT_METHODS_IMAGES[alternativePaymentMethod][
                      isCardSelected ? 'inactive' : 'active'
                    ]
                  }
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
            </S.PaymentMethodsWrapper>
          )}

          <S.PriceDescription>
            <S.PriceInfo>
              <S.PriceBlockText>
                {t('checkoutExtended.personalizedPlan')}
              </S.PriceBlockText>
              <S.PriceBlockText>
                {subscription.totalAmount} {currencyLabel}
              </S.PriceBlockText>
            </S.PriceInfo>
            <S.PriceInfo>
              <S.PriceBlockText>
                {isIntroSaleCohort ? (
                  dynamicDiscount?.checkout || DYNAMIC_DISCOUNT_DEFAULT_TEXT
                ) : (
                  <Trans
                    i18nKey="checkoutExtended.introductoryDiscount"
                    values={{
                      discountPercentage: subscription.percentage,
                    }}
                  />
                )}
              </S.PriceBlockText>
              <S.TotalDiscount>
                -{getCalculatedPrice(discountAmount)} {currencyLabel}
              </S.TotalDiscount>
            </S.PriceInfo>
          </S.PriceDescription>

          <S.DailyPayment>
            <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
            <S.DailyAmount>
              {getCalculatedPrice(selectedSubscription.trialPrices.daily)}{' '}
              {currencyLabel}
            </S.DailyAmount>
          </S.DailyPayment>

          <S.TotalPayment>
            <S.TotalDescription>
              <S.TotalText>
                <Trans
                  i18nKey={
                    hasVatInfo
                      ? t('checkoutExtended.totalWithVat')
                      : t('checkoutExtended.total')
                  }
                  components={[<br />]}
                />
              </S.TotalText>
              <S.TotalAmount>
                <Trans
                  i18nKey="checkoutExtended.totalAmount"
                  values={{
                    totalAmount: selectedSubscription.trialPrices.fullPrice,
                    currency: currencyLabel,
                    context: selectedSubscription.trialPrices.durationDays,
                  }}
                />
              </S.TotalAmount>
            </S.TotalDescription>
            <S.SaveText>
              <Trans
                i18nKey="checkoutExtended.savedAmount"
                values={{
                  savedAmount: subscription.discountAmount,
                  currency: currencyLabel,
                  discountPercentage: subscription.percentage,
                }}
              />
            </S.SaveText>
          </S.TotalPayment>

          <S.PaymentFormWrapper>
            {paymentSystem === PaymentSystem.PRIMER && (
              <PrimerPaymentForm
                variant={PrimerFormVariants.SEPARATED}
                separatedVariantProps={{
                  isCreditCardSelected:
                    selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
                  setAlternativePaymentMethodCallback:
                    setAlternativePaymentMethod,
                }}
              />
            )}
            {paymentSystem === PaymentSystem.STRIPE && (
              <S.StripePaymentWrapper>
                <S.StripePaymentContainer
                  isVisible={
                    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
                  }
                >
                  <CardPaymentFormSeparateCheckout />
                </S.StripePaymentContainer>

                <S.StripePaymentContainer
                  isVisible={
                    selectedPaymentMethod !== PaymentMethod.CREDIT_CARD
                  }
                >
                  <StripeSeparatePaymentRequestButton
                    setAlternativePaymentMethodCallback={
                      setAlternativePaymentMethod
                    }
                  />
                </S.StripePaymentContainer>
              </S.StripePaymentWrapper>
            )}
            {hasPaypalButton && (
              <S.PayPalContainer
                hasNegativeOffset={
                  paymentSystem === PaymentSystem.PRIMER &&
                  selectedPaymentMethod === PaymentMethod.CREDIT_CARD
                }
              >
                <Separator />
                <PayPalButton />
              </S.PayPalContainer>
            )}
          </S.PaymentFormWrapper>

          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        {paymentSystem === PaymentSystem.STRIPE && (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
        {paymentSystem === PaymentSystem.PRIMER && (
          <PrimerPaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
      </S.Root>
    )
  )
}
