import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTargetGoal = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    margin: 0 auto;
    padding: 0 10px;
  `,
  PageTitle: styled.h1`
    color: ${Color.DARK_SECONDARY};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 56px;
    text-align: center;
  `,
  TagLine: styled.p`
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 32px;
    background: linear-gradient(312deg, #ff8276 5.25%, #e0ff83 98.13%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 8px;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 306/154;
    margin: 0 auto 32px;
  `,
  GraphImage: styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
  `,
  Description: styled.p`
    color: ${Color.LIGHT};
    font-size: 14px;
    line-height: 22px;
  `,
}
