import styled, { css } from 'styled-components'

import greenCheckbox from 'assets/images/accept-red-checkbox.png'
import arrowRight from 'assets/images/arrow-forward-gray.png'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
}

type TCurrencyProps = {
  isMxnCurrency: boolean
}

export const StyledSelectSubscriptionItemV2 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps>`
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    position: relative;
    ${({ isDefault }) =>
      isDefault
        ? css`
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
          `
        : css`
            border-radius: 16px;
          `};

    ${({ isSelected }) =>
      isSelected
        ? css`
            border: 1px solid ${Color.PRIMARY};
            background-color: ${Color.WHITE};
            box-shadow: 0px 8px 20px 0px #e8e8e8;
          `
        : css`
            border: 1px solid #dadadd;
            background-color: ${Color.CORAL_GRAY};
          `};

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1px solid ${Color.GRAY_SECONDARY};
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;

      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${greenCheckbox});
          background-size: 20px 20px;
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanContainer: styled.div`
    padding-left: 32px;
  `,
  MostPopularBadge: styled.div<TProps>`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    background-color: ${({ isSelected }) =>
      isSelected ? Color.PRIMARY : '#dadadd'};
  `,
  PlanPeriod: styled.p<TProps>`
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;

    ${({ isSelected }) =>
      isSelected
        ? css`
            color: ${Color.BLUE_DARK};
            font-weight: 600;
          `
        : css`
            color: ${Color.GRAY};
            font-weight: 500;
          `};
  `,
  PricesComparisonWrapper: styled.div<TCurrencyProps>`
    display: flex;
    align-items: center;
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    line-height: 20px;
  `,
  PreviousPrice: styled.span`
    display: flex;
    align-items: center;
    font-weight: 400;
    color: ${Color.GRAY};
    margin-right: 2px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;

    &:after {
      content: '';
      display: block;
      background-image: url(${arrowRight});
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      margin-left: 2px;
    }
  `,
  CurrentPrice: styled.span<TProps>`
    font-weight: 500;
    color: ${({ isSelected }) => (isSelected ? Color.BLUE_DARK : Color.GRAY)};
  `,
  CustomPrice: styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  OldDailyPrice: styled.span`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${Color.GRAY};
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  PriceValue: styled.span<TProps & TCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '18px' : '24px')};
    font-weight: 500;
    line-height: 28px;
    color: ${({ isSelected }) => (isSelected ? Color.BLUE_DARK : Color.GRAY)};
  `,
  Period: styled.span`
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: ${Color.GRAY};
  `,
}
