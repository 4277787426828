import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectHasInAppOffer } from 'root-redux/selects/common'
import {
  selectIsInAppAvailable,
  selectIsUpsellAvailable,
  selectUserCountryCode,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { UpsellBenefits } from 'modules/purchase/components/UpsellBenefits'
import { UpsellSubscriptionBlockSwitchers } from 'modules/purchase/components/UpsellSubscriptionBlockSwitchers'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Country,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellPaywallSwitchers as S } from './UpsellPaywallSwitchers.styles'

export const UpsellPaywallSwitchers: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)
  const isUpsellAvailable = useSelector(selectIsUpsellAvailable)
  const isInAppAvailable = useSelector(selectIsInAppAvailable)
  const hasInAppOffer = useSelector(selectHasInAppOffer)

  const { search } = useLocation()
  const { fetchingActionsList, subscriptions } = usePurchaseStore()

  const { arePricesReady } = usePricesStatus()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const tags = useMemo(() => {
    const regionAndVatTag: string =
      userCountryCode === Country.USA
        ? SubscriptionTagsType.USD
        : SubscriptionTagsType.WORLDWIDE

    return `${regionAndVatTag},${SubscriptionTagsType.NO_TAX}`
  }, [userCountryCode])

  useLayoutEffect(() => {
    if (isUpsellAvailable) {
      dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
      setIsPricesStartedFetching(true)
      return
    }
    if (isInAppAvailable && hasInAppOffer) {
      goTo({ pathname: PageId.IN_APP_PAYWALL, search })
    }

    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [
    search,
    isUpsellAvailable,
    dispatch,
    tags,
    isInAppAvailable,
    hasInAppOffer,
  ])

  useEffect(() => {
    const upsellSubscription = subscriptions.find(
      (subscription) => subscription?.product === UpsellProduct.MIX,
    )
    dispatch(setSelectedSubscriptionAction(upsellSubscription as ISubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname:
          isInAppAvailable && hasInAppOffer
            ? PageId.IN_APP_PAYWALL
            : PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    hasInAppOffer,
    isInAppAvailable,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)
    }
  }, [subscriptions, isPricesStartedFetching, arePricesReady])

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction(ScreenName.UPSELL_SWITCHER))
  }, [dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Root>
      <Container>
        <S.Title>
          <Trans i18nKey="upsell.title" components={[<br />]} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="upsell.subtitle" components={[<br />]} />
        </S.Subtitle>

        <UpsellSubscriptionBlockSwitchers makeUpsell={makeUpsell} />

        <UpsellBenefits />
        {isUpsellInProgress && <Spinner />}
      </Container>
    </S.Root>
  )
}
