import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import {
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectUserGoal } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'

import { LuvlyBenefits } from 'modules/purchase/components/LuvlyBenefits'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { PurchaseTimerMarissa } from 'modules/purchase/components/PurchaseTimerMarissa'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import {
  I18N_CONTEXT_COHORT_MAP,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledPurchaseVariant4 as S } from './PurchaseVariant4.styles'
import { MAIN_GOAL, SUBTITLE, TITLE } from './constant'

export const PurchaseVariant4: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { pathname } = useLocation()
  const hasVatInfo = useVatInfo()
  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const goal = useSelector(selectUserGoal)

  const { cohort, periodName, periodQuantity, price } = usePurchaseStore()
  const { arePricesReady } = usePricesStatus()
  const cohortToUse = useCohortToUse()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))

    const vatTag = hasVatInfo
      ? SubscriptionTagsType.TAX
      : SubscriptionTagsType.NO_TAX

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, vatTag))
  }, [dispatch, hasVatInfo])

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.PURCHASE,
    })
    setIsCheckoutShown(false)
  }, [dispatch, setIsCheckoutShown, pathname, cohort, productId])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <div>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.Title>
            <Trans
              i18nKey={TITLE[goal || MAIN_GOAL.BOTH_OPTIONS]}
              components={[<br />]}
            />
          </S.Title>
          <S.Subtitle>
            <Trans
              i18nKey={SUBTITLE[goal || MAIN_GOAL.BOTH_OPTIONS]}
              components={[<br />]}
            />
          </S.Subtitle>
          <S.DiscountBlock>
            <S.Discount>
              <Trans
                i18nKey="purchase4.discount"
                components={[<br />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.Discount>
          </S.DiscountBlock>
          <S.Subtitle ref={elemForComparisonRef}>
            {t('purchaseIntroOffer.goalSubtitle')}
          </S.Subtitle>
          <UserGoalGraph pageId={PageId.PURCHASE} />
          <PurchaseTimerMarissa elemForComparisonRef={elemForComparisonRef} />
          <LuvlyBenefits />
          <SubscriptionsIntroOfferBlock />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <PurchaseDisclaimer marginBottom={40} />
          <MoneyBackGuarantee />
          <CustomerReviews
            customerReviews={DEFAULT_CUSTOMER_REVIEWS_V2}
            viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
          />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <SecurityInfo />
        </S.Wrapper>
      )}

      {isCheckoutShown && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
    </div>
  )
}
