import React, { useCallback } from 'react'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { PRIMER_APPLE_PAY_ID } from 'modules/purchase/constants'

export const useInitPrimerApplePay = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const initApplePayButton = useCallback(
    async (callback?: () => void) => {
      const paymentMethodManager =
        await primerRef.current?.createPaymentMethodManager(
          PaymentMethodType.APPLE_PAY,
        )
      if (!paymentMethodManager) return

      const applePayButton = paymentMethodManager.createButton()

      await applePayButton.render(PRIMER_APPLE_PAY_ID, {
        style: {
          buttonType: 'plain',
          buttonColor: 'black',
        },
      })

      applePayButton.addEventListener(EventTypes.CLICK, () => {
        callback && callback()
      })
    },
    [primerRef],
  )

  return {
    initApplePayButton,
  }
}
