import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { setScreenNameAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { CardPaymentForm } from 'modules/purchase/components/CardPaymentForm'
import { ConfirmCheckoutModal } from 'modules/purchase/components/ConfirmCheckoutModal'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PersonalOfferTimer } from 'modules/purchase/components/PersonalOfferTimer'
import { SecurityInfoV2 } from 'modules/purchase/components/SecurityInfoV2'
import { CURRENCY_SYMBOLS, Y_OFFSET } from 'modules/purchase/constants'
import { usePurchaseAnalytics, usePurchaseStore } from 'modules/purchase/hooks'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import spark from 'assets/images/sprite/spark.svg'
import graph from 'assets/images/user-goal-graph-v3.png'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { PersonalizedPlan } from '../../components/PersonalizedPlan'
import { StyledPurchaseVariant3 as S } from './PurchaseVariant3.styles'
import { BENEFITS_LIST, CUSTOMER_REVIEWS } from './constants'

export const PurchaseVariant3: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const elemForComparisonRef = useRef<HTMLDivElement>(null)
  const selectedSubscription = useSelector(selectSubscription)
  const { search } = useLocation()
  const { currency, trialPrice, price, periodQuantity } = usePurchaseStore()
  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)
  const paymentContainerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!selectedSubscription) {
      replaceHistory({ pathname: PageId.CHOOSE_TRIAL_PRICE, search })
    }
  }, [selectedSubscription, search])

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))
  }, [dispatch])

  useEffect(() => {
    const { current: elem } = paymentContainerRef
    if (elem && isCheckoutShown) {
      const yCoordinate =
        elem.getBoundingClientRect().top + window.scrollY - Y_OFFSET
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }, [isCheckoutShown])

  const handleOpenConfirmModal = useCallback(() => {
    setIsConfirmModalShown(true)
  }, [])

  const handleCloseConfirmModal = useCallback(() => {
    setIsConfirmModalShown(false)
    setIsCheckoutShown(true)
    sendGeneralPurchaseEvents()
  }, [sendGeneralPurchaseEvents])

  return (
    <>
      <S.Wrapper>
        <S.Title>{t('purchase3.title')}</S.Title>
        <S.TitleDescription>
          <Trans i18nKey="purchase3.subtitle" components={[<br />]} />
        </S.TitleDescription>
      </S.Wrapper>
      <Container>
        <S.List>
          {BENEFITS_LIST.map((benefit) => (
            <S.ListOption key={benefit.id}>
              <img src={benefit.icon} height="40" alt="icon" />
              {t(benefit.key)}
            </S.ListOption>
          ))}
        </S.List>
        <PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
      </Container>
      <S.UserGoalGraphEmail
        isHeaderVisible={false}
        pageId={PageId.PURCHASE_VARIANT_3}
        graph={graph}
      />
      <PersonalOfferTimer />
      <Container>
        <S.Subscriptions>
          <S.Subtitle ref={elemForComparisonRef}>
            {t('purchase3.todayPrice')}
          </S.Subtitle>
          <S.Subscription>
            <span>{t('purchase3.weekPrice')}</span>
            <span>
              {CURRENCY_SYMBOLS[currency]}
              {trialPrice}
            </span>
          </S.Subscription>
          <S.SubscriptionInfo>
            <SvgImage svg={spark} width={16} />
            <p>{t('purchase3.weekPriceDescription')}</p>
            <SvgImage svg={spark} width={16} />
          </S.SubscriptionInfo>
          <S.Subtitle>{t('purchase3.afterTrial')}</S.Subtitle>
          <S.Subscription>
            <span>
              {t('purchase3.monthPlan', { interval: periodQuantity })}
            </span>
            <span>
              {CURRENCY_SYMBOLS[currency]}
              {price}
            </span>
          </S.Subscription>
        </S.Subscriptions>
        <S.CustomerReviews
          customerReviews={CUSTOMER_REVIEWS}
          className="no-border"
        />

        {isCheckoutShown ? (
          <>
            <S.Payment ref={paymentContainerRef}>
              <S.Subtitle>
                {t('purchase1.choicePaymentMethod.title')}
              </S.Subtitle>
              <S.PaymentDescription>
                {t('purchase3.paymentDescription', {
                  price: `${CURRENCY_SYMBOLS[currency]}${trialPrice}`,
                })}
              </S.PaymentDescription>
              <CardPaymentForm isCardPaymentShown isRatingShown={false} />
              <PaymentRequestButton
                isSeparatorVisible
                buttonHeight={50}
                marginBottom={0}
              />
            </S.Payment>
            <SecurityInfoV2 price={`${CURRENCY_SYMBOLS[currency]}${price}`} />
          </>
        ) : (
          <S.ButtonContainer>
            <Button
              data-testid="start-plan-btn"
              onClick={handleOpenConfirmModal}
            >
              {t`actions.startMyPlan`}
            </Button>
          </S.ButtonContainer>
        )}
      </Container>
      <PaymentWaitingModal
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
      />
      <ConfirmCheckoutModal
        isShown={isConfirmModalShown}
        onClose={handleCloseConfirmModal}
      />
    </>
  )
}
