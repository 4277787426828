import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserRDTCID } from 'root-redux/actions/user'
import { selectLanguage, selectRedditPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { Locale } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useRedditPixelInitialization = (): void => {
  const { search } = document.location
  const dispatch = useDispatch()
  const redditPixelId = useSelector(selectRedditPixelId)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const URLParams = new URLSearchParams(search)
  const rdtcid = URLParams.get('rdt_cid')

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const shouldRedditPixelBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length && isEnglishLang) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAdvertizingAndMarketingCookie &&
        isEnglishLang)
    )
  }, [
    isEUUser,
    userCookieConsentAnswer,
    hasAdvertizingAndMarketingCookie,
    isEnglishLang,
  ])

  const isPersonalDataAllowed = useMemo(() => {
    return (
      (isEUUser && hasFunctionalCookie && isEnglishLang) ||
      (isEUUser && !isEnglishLang) ||
      !isEUUser
    )
  }, [isEUUser, isEnglishLang, hasFunctionalCookie])

  useLayoutEffect(() => {
    if (!redditPixelId || shouldRedditPixelBePaused || !userCountryCode) return

    const script = document.createElement('script')
    script.text = `!function(w,d)
    {if(!w.rdt){var p=w.rdt=function(){p.sendEvent?
    p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var
    t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var
    s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}
    (window,document);`
    script.async = true
    document.head.appendChild(script)

    window.rdt &&
      window.rdt('init', redditPixelId, {
        email: isPersonalDataAllowed ? email : '',
        externalId: uuid,
      })
    window.rdt && window.rdt('track', 'PageVisit')

    if (rdtcid && uuid) dispatch(sendUserRDTCID(rdtcid))
  }, [
    email,
    redditPixelId,
    uuid,
    shouldRedditPixelBePaused,
    userCountryCode,
    isPersonalDataAllowed,
    rdtcid,
    dispatch,
  ])
}
