export const CUSTOMER_REVIEWS = [
  {
    id: '1',
    authorPath: 'purchaseChatBot.customerReviews.firstAuthor',
    textPath: 'presale.firstCustomerReview',
  },
  {
    id: '2',
    authorPath: 'purchaseChatBot.customerReviews.secondAuthor',
    textPath: 'purchaseChatBot.customerReviews.secondReview',
  },
  {
    id: '3',
    authorPath: 'purchaseChatBot.customerReviews.thirdAuthor',
    textPath: 'purchaseChatBot.customerReviews.thirdReview',
  },
]
