import styled from 'styled-components'

import { Color } from 'root-constants'

export const ProgramBenefitsCarousel = {
  CarouselContainer: styled.div`
    max-width: 360px;
    margin: 0 auto 24px;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
    }
  `,
  CardContainer: styled.div`
    position: relative;
    padding-top: 14px;
  `,
  Text: styled.p`
    position: absolute;
    top: 50%;
    left: 44px;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    max-width: 180px;
    color: ${Color.WHITE};
  `,
}
