export const enum SubscriptionDuration {
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  ONE_YEAR = 12,
}

export const enum YearlySubscriptionDuration {
  TWO_YEARS = 2,
}

export const DISCLAIMERS = {
  [SubscriptionDuration.SIX_MONTHS]: 'purchaseUpgrade.disclaimer.sixMonths',
  [SubscriptionDuration.NINE_MONTHS]: 'purchaseUpgrade.disclaimer.nineMonths',
  [SubscriptionDuration.ONE_YEAR]: 'purchaseUpgrade.disclaimer.oneYear',
}

export const YEARLY_SUBSCRIPTIONS_DISCLAIMERS = {
  [YearlySubscriptionDuration.TWO_YEARS]: 'purchaseUpgrade.disclaimer.twoYears',
}
