import styled from 'styled-components'

import { CustomerReviews } from 'components/CustomerReviews'
import { UserGoalGraphEmail } from 'components/UserGoalGraphEmail'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseVariant3 = {
  Wrapper: styled.div`
    background-color: ${Color.SANDY};
    padding: 24px 20px 18px;
    margin: -16px auto 24px;
    max-width: 360px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
    }
  `,
  Title: styled.h2`
    text-align: center;
    margin-bottom: 16px;
  `,
  TitleDescription: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  List: styled.div`
    margin: 28px 0 22px;
  `,
  ListOption: styled.div`
    display: flex;
    margin-bottom: 20px;

    img {
      margin-right: 16px;
    }
  `,
  UserGoalGraphEmail: styled(UserGoalGraphEmail)`
    margin: 32px auto 24px;
    padding: 0 4px;
    max-width: 360px;

    span {
      top: 46px;

      @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
        top: 52px;
        left: 166px;
      }
    }
  `,
  Subscriptions: styled.div`
    margin: 32px 0 24px;
  `,
  Subtitle: styled.p`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid ${Color.GRAY_LIGHT};
  `,
  Subscription: styled.div`
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${Color.GRAY_LIGHT};
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  `,
  SubscriptionInfo: styled.div`
    display: flex;
    margin: 8px 0 24px;

    svg {
      min-width: 16px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${Color.LIGHT};
      text-align: center;
      padding: 0 11px;
    }
  `,
  PaymentDescription: styled.p`
    margin: 6px 0 16px;
  `,
  Payment: styled.div`
    margin: 32px 0 28px;
  `,
  ButtonContainer: styled.div`
    margin-top: 32px;
    padding-bottom: 30px;
  `,
  CustomerReviews: styled(CustomerReviews)``,
}
