import styled from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import greenCheckbox from 'assets/images/green-checkbox.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
}

type TCurrencyProps = {
  isMxnCurrency: boolean
}

export const StyledIntroOfferSelectSubscriptionItem = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps & TLocaleStylesProps>`
    display: flex;
    align-items: center;
    padding-right: 5px;
    min-height: 80px;
    border-radius: 20px;
    border: ${({ isSelected }) =>
      isSelected ? `2px solid #89cc45` : `2px solid #c4c4c4`};

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: ${({ isEnLocaleStyle }) => (isEnLocaleStyle ? '16px' : '12px')};
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${greenCheckbox});
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanContainer: styled.div<TLocaleStylesProps>`
    width: 100%;
    padding-left: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '44px' : '40px'};
  `,
  MostPopularBadge: styled.div`
    z-index: 1;
    border-radius: 8px;
    background: linear-gradient(97.03deg, #70cd1c 3.15%, #a2ea35 88.83%);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    left: 44px;
    transform: translateY(-50%);
    padding: 4px 8px;
    color: ${Color.WHITE};
    font-size: 10px;
    line-height: 12px;
  `,
  PlanPeriod: styled.p<TProps>`
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${({ isSelected }) => (isSelected ? Color.DARK : Color.LIGHT)};
    padding-right: 2px;
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 45px;
  `,
  PricesComparisonWrapper: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  PreviousPrice: styled.span<TCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: 500;
    line-height: 18px;
    color: ${Color.LIGHT};
    padding-right: 4px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;
  `,
  OldestPrice: styled.span`
    font-size: 16px;
    font-weight: 500;
    color: ${Color.LIGHT};
    flex: 1;
    padding: 2px 6px;
    text-align: end;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  CustomPrice: styled.p<TProps & TCurrencyProps>`
    display: flex;
    flex-direction: column;
    align-items: ${({ isMxnCurrency }) =>
      isMxnCurrency ? 'flex-end' : 'flex-start'};
    min-width: 98px;
    padding: ${({ isMxnCurrency }) =>
      isMxnCurrency ? '10px 6px' : '10px 6px 10px 24px'};
    object-fit: contain;
    color: ${Color.LIGHT};
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});

    ${({ isSelected }) =>
      isSelected &&
      `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
        
        & > span:first-child {
          color: ${Color.WHITE};
        }
    `};
  `,
  CurrentPrice: styled.span<TCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '12px' : '14px')};
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PriceValue: styled.span<TCurrencyProps>`
    font-size: ${({ isMxnCurrency }) => (isMxnCurrency ? '14px' : '18px')};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  `,
  Period: styled.span`
    font-size: 8px;
    font-weight: 500;
    line-height: 8px;
  `,
}
