import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { ConfettiBackdrop } from 'components/ConfettiBackdrop'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import introduceExpert from 'assets/images/introduce-expert.png'

import { DelayedBottomContainer } from 'common-styles'

import { StyledWelcomeLikeChat as S } from './WelcomeLikeChat.styles'
import { QUESTION } from './constants'

export const WelcomeLikeChat: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <Container fullHeight>
      {chatStep === ChatStep.SHOW_OPTIONS && <ConfettiBackdrop />}
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={DELAY_BEFORE_SHOW}
        hasJustNowLabel={
          chatStep === ChatStep.EXPERT_QUESTION || chatStep === ChatStep.IMAGE
        }
      >
        <Trans
          i18nKey="onboarding.welcomeLikeChat.introduceExpert"
          components={[<br />, <strong />]}
        />
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        isImage
        hasHeader={false}
        delayBeforeShow={2000}
        onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
      >
        <img src={introduceExpert} alt="introduce-expert" />
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={3000}
        onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
        hasJustNowLabel
      >
        <Trans
          i18nKey="onboarding.welcomeLikeChat.startJourney"
          components={{
            emoji: <S.Emoji />,
          }}
        />
      </ChatMessage>
      <DelayedBottomContainer
        isShown={chatStep === ChatStep.SHOW_OPTIONS}
        pinedToBottom
      >
        <Button onClick={handleNextClick}>{t('actions.letsDoIt')}</Button>
      </DelayedBottomContainer>
    </Container>
  )
}
