import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ConnectToDBProgressBar } from 'components/ConnectToDBProgressBar'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Gender,
  LUVLY_INTRO_FAST_CONTEXT,
  Locale,
} from 'root-constants'

import { StyledPhotoResult as S } from './PhotoResult.styles'
import { IMAGE_AFTER_PATH, IMAGE_BEFORE_PATH, QUESTION } from './constants'

export const PhotoResult: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const language = useSelector(selectLanguage)
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter } = useCounter(true, {
    duration: 10000,
    callback: handleNextClick,
  })

  const gender: Gender = useMemo(
    () => userAnswers?.[PageId.GENDER] || Gender.FEMALE,
    [userAnswers],
  )

  const isEsLocale = useMemo(() => language === Locale.SPANISH, [language])

  const images = useMemo(
    () =>
      isEsLocale && isLuvlyIntroFastCohort
        ? {
            before: `${CDN_FOLDER_LINK}${IMAGE_BEFORE_PATH}_${gender}_${language}.jpg`,
            after: `${CDN_FOLDER_LINK}${IMAGE_AFTER_PATH}_${gender}_${language}.jpg`,
          }
        : {
            before: `${CDN_FOLDER_LINK}${IMAGE_BEFORE_PATH}_${gender}_en.jpg`,
            after: `${CDN_FOLDER_LINK}${IMAGE_AFTER_PATH}_${gender}_en.jpg`,
          },
    [gender, isEsLocale, isLuvlyIntroFastCohort, language],
  )

  const reviewAuthorText = useMemo(
    () =>
      gender === Gender.MALE
        ? t`onboarding.photoResult.reviewAuthorMale`
        : t('onboarding.photoResult.reviewAuthorFemale', {
            context:
              isEsLocale && isLuvlyIntroFastCohort
                ? LUVLY_INTRO_FAST_CONTEXT
                : '',
          }),
    [gender, isEsLocale, isLuvlyIntroFastCohort, t],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>{t`onboarding.photoResult.question`}</PageTitle>
      </S.TitleContainer>
      <Container>
        <S.ImageContainer>
          <S.Card>
            <S.Image src={images.before} alt="photo-result-before" />
          </S.Card>
          <S.Card>
            <S.Image src={images.after} alt="photo-result-after" />
          </S.Card>
        </S.ImageContainer>
        <S.ReviewContainer>
          <S.ReviewText>{t`onboarding.photoResult.reviewText`}</S.ReviewText>
          <S.ReviewAuthor>{reviewAuthorText}</S.ReviewAuthor>
        </S.ReviewContainer>
        <S.ProgressbarContainer>
          <ConnectToDBProgressBar value={counter}>
            {t`onboarding.photoResult.connectToDB`}
          </ConnectToDBProgressBar>
        </S.ProgressbarContainer>
      </Container>
    </div>
  )
}
