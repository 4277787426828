import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import firstExpertPicture from 'assets/images/face-yoga-expert-1.png'
import secondExpertPicture from 'assets/images/face-yoga-expert-2.png'
import thirdExpertPicture from 'assets/images/face-yoga-expert-3.png'
import fourthExpertPicture from 'assets/images/face-yoga-expert-4.png'
import securityIcon from 'assets/images/security-icon.png'

import { goBack } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledFaceYogaExperts as S } from './FaceYogaExperts.styles'
import { QUESTION } from './constants'

export const FaceYogaExperts: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isTiktokCohort, isCosmeticCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.ImageContainer>
        <S.Icon src={securityIcon} alt="" />
      </S.ImageContainer>
      <S.Title>
        <Trans
          i18nKey="onboarding.faceYogaExperts.question"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          components={[<br />]}
        />
      </S.Title>
      <Container>
        {isCosmeticCohort ? (
          <S.Card>
            <S.CardPicture src={fourthExpertPicture} alt="" />
            <S.CardInfo>
              <S.CardTitle>{t`onboarding.faceYogaExperts.expertName4`}</S.CardTitle>
              <S.CardDescription>{t`onboarding.faceYogaExperts.expertDescription4`}</S.CardDescription>
            </S.CardInfo>
          </S.Card>
        ) : (
          <S.Card>
            <S.CardPicture src={firstExpertPicture} alt="" />
            <S.CardInfo>
              <S.CardTitle>{t`onboarding.faceYogaExperts.expertName1`}</S.CardTitle>
              <S.CardDescription isTiktokStyles={isTiktokCohort}>
                {t`onboarding.faceYogaExperts.expertDescription1`}
              </S.CardDescription>
            </S.CardInfo>
          </S.Card>
        )}
        <S.Card>
          <S.CardPicture src={secondExpertPicture} alt="" />
          <S.CardInfo>
            <S.CardTitle>{t`onboarding.faceYogaExperts.expertName2`}</S.CardTitle>
            <S.CardDescription isTiktokStyles={isTiktokCohort}>
              {t`onboarding.faceYogaExperts.expertDescription2`}
            </S.CardDescription>
          </S.CardInfo>
        </S.Card>
        <S.Card>
          <S.CardPicture src={thirdExpertPicture} alt="" />
          <S.CardInfo>
            <S.CardTitle>{t`onboarding.faceYogaExperts.expertName3`}</S.CardTitle>
            <S.CardDescription isTiktokStyles={isTiktokCohort}>
              <Trans
                i18nKey="onboarding.faceYogaExperts.expertDescription3"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                components={[<br />]}
              />
            </S.CardDescription>
          </S.CardInfo>
        </S.Card>
      </Container>
      {!isTiktokCohort ? (
        <NavigationButtons onBackClick={goBack} onNextClick={handleNextClick} />
      ) : (
        <S.BottomButtonContainer>
          <Button onClick={handleNextClick}>{t`actions.continue`}</Button>
        </S.BottomButtonContainer>
      )}
    </div>
  )
}
