import React, { useCallback } from 'react'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { PRIMER_GOOGLE_PAY_ID } from 'modules/purchase/constants'

export const useInitPrimerGooglePay = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const initGooglePayButton = useCallback(
    async (callback?: () => void) => {
      const paymentMethodManager =
        await primerRef.current?.createPaymentMethodManager(
          PaymentMethodType.GOOGLE_PAY,
        )
      if (!paymentMethodManager) return

      const googlePayButton = paymentMethodManager.createButton()

      await googlePayButton.render(PRIMER_GOOGLE_PAY_ID, {
        style: {
          buttonType: 'short',
          buttonColor: 'white',
        },
      })

      googlePayButton.addEventListener(EventTypes.CLICK, () => {
        callback && callback()
      })
    },
    [primerRef],
  )

  return {
    initGooglePayButton,
  }
}
