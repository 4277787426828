import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import harvardMedicalSchoolLogo from 'assets/images/harvard-medical-school-logo.png'
import northwesternUniversityLogo from 'assets/images/northwestern-university-logo.png'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import { CDN_FOLDER_LINK, I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledReverseYourWrinkles as S } from './ReverseYourWrinkles.styles'
import { IMAGE_PATH, QUESTION } from './constants'

export const ReverseYourWrinkles: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const { isTiktokCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const graphImageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`,
    [language],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>
          {t('onboarding.reverseYourWrinkles.question', {
            context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
          })}
        </PageTitle>
      </S.TitleContainer>
      <Container>
        <S.ImageContainer>
          <S.Graph src={graphImageLink} alt="" />
        </S.ImageContainer>
        <S.Card>
          <S.CardIcon src={harvardMedicalSchoolLogo} alt="" />
          <S.CardText>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option1"
              components={[<strong />]}
            />
          </S.CardText>
        </S.Card>
        <S.Card>
          <S.CardIcon src={northwesternUniversityLogo} alt="" />
          <S.CardText>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option2"
              components={[<strong />]}
            />
          </S.CardText>
        </S.Card>
      </Container>
      {!isTiktokCohort ? (
        <NavigationButtons onBackClick={goBack} onNextClick={handleNextClick} />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick}>{t`actions.continue`}</Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
