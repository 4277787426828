import styled from 'styled-components'

import { datesAnimation } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledUserGoalGraphEmail = {
  GoalDateContainer: styled.div`
    text-align: center;
    margin-bottom: 10px;
  `,
  Goal: styled.p`
    position: relative;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    background: linear-gradient(90deg, #e7e581 0%, #fb9679 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  AnimationContainer: styled.div`
    height: 25px;
    overflow: hidden;
  `,
  GoalDate: styled.p`
    height: 45px;
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    background: linear-gradient(90deg, #e7e581 0%, #fb9679 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:first-child {
      animation: ${datesAnimation} 5s linear;
    }
  `,
  ProgramDescription: styled.p`
    margin-bottom: 37px;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
  `,
  GraphContainer: styled.div`
    position: relative;
    margin-bottom: 24px;
    aspect-ratio: 320/148;
  `,
  Graph: styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 14px;
    image-rendering: -webkit-optimize-contrast;
  `,
  GoalText: styled.span`
    position: absolute;
    top: 42px;
    left: 142px;
    padding: 0 3px;
    border-radius: 6px;
    background-color: #9be42e;
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      top: 50px;
      font-size: 14px;
      padding: 0 9px;
    }
  `,
}
