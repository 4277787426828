import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getDateSequence } from 'helpers/date'

import Graph from 'assets/images/user-goal-graph.png'

import { PageId } from 'page-constants'
import { DAYS_NUMBER } from 'root-constants'

import { StyledUserGoalGraphEmail as S } from './UserGoalGraphEmail.styles'

interface IProps {
  pageId: PageId
  className?: string
  isHeaderVisible?: boolean
  graph?: string
}

export const UserGoalGraphEmail: React.FC<IProps> = ({
  pageId,
  isHeaderVisible = true,
  className = '',
  graph = Graph,
}) => {
  const { t } = useTranslation()

  const dateSequence = useMemo(() => {
    const daysNumber = DAYS_NUMBER[pageId]
    return getDateSequence(daysNumber)
  }, [pageId])

  return (
    <>
      {isHeaderVisible && (
        <>
          <S.GoalDateContainer>
            <S.Goal>
              {t`onboarding.shortProgramDescription.goalDateEmailVariant`}
            </S.Goal>
            <S.AnimationContainer key={pageId}>
              {dateSequence.map(({ day, month }) => (
                <S.GoalDate key={`${pageId}${day}${month}`}>
                  {day} {month}
                </S.GoalDate>
              ))}
            </S.AnimationContainer>
          </S.GoalDateContainer>
          <S.ProgramDescription>
            <Trans
              i18nKey="commonComponents.programDescription"
              components={[<br />]}
            />
          </S.ProgramDescription>
        </>
      )}
      <S.GraphContainer className={className}>
        <S.Graph src={graph} alt="graph" />
        <S.GoalText>
          {t`onboarding.shortProgramDescription.glowingSkin`}
        </S.GoalText>
      </S.GraphContainer>
    </>
  )
}
