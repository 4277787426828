import styled from 'styled-components'

import { HolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews'

import { Color } from 'root-constants'

export const StyledPersonalPurchaseReviews = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & .swiper {
      padding-bottom: 36px;
      margin-bottom: 24px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: ${Color.CREAMY};
    }

    & .swiper-pagination-bullet {
      background: #ff8276;
    }
  `,
  HeaderContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  `,
  Subtitle: styled.span`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    & > strong {
      color: ${Color.PRIMARY};
      font-size: 17px;
      font-weight: 600;
      line-height: 22px;
    }
  `,
  Card: styled.div`
    max-width: 320px;
    padding-bottom: 16px;
    box-shadow: 0 8px 20px #f2e6e1;
    border-radius: 16px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  `,
  Image: styled.img`
    vertical-align: top;
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GRAY};
    text-align: center;
    margin-bottom: 8px;
    padding: 0 16px;
  `,
  HolidaysCancelOfferReviews: styled(HolidaysCancelOfferReviews)`
    max-width: 360px;
    padding: 0;
  `,
}
