import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledObTags = {
  Root: styled.div``,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin-bottom: 18px;
  `,
  Tags: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px 12px;
  `,
  Tag: styled.div`
    border-radius: 32px;
    border: 1px solid #c6d3ff;
    background: #e4eaff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    padding: 12px 16px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    transition: 0.15s;
    letter-spacing: -0.15px;

    input:checked + & {
      background: #ff8276;
      border-color: #e85e47;
      color: #fff;
    }
  `,
}
