import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import {
  AGE_VALUES,
  PERSONAL_POSTER,
} from 'modules/purchase/pages/PersonalPurchase/constants'

import { PageId } from 'page-constants'

import { StyledPersonalPurchasePoster as S } from './PersonalPurchasePoster.style'

type TProp = {
  className?: string
}

export const PersonalPurchasePoster: FC<TProp> = ({ className = '' }) => {
  const answers = useSelector(selectAnswers)
  const goalAnswer = useMemo(() => answers?.[PageId.INTRO], [answers])

  const age = useMemo(() => {
    if (!answers || !answers.age) return AGE_VALUES.TWENTY

    return (
      Object.values(AGE_VALUES).find(
        (option) => option === answers[PageId.AGE],
      ) || AGE_VALUES.TWENTY
    )
  }, [answers])

  const poster = useMemo(() => {
    switch (age) {
      case AGE_VALUES.TWENTY:
        return PERSONAL_POSTER[age]

      case AGE_VALUES.FIFTY:
      case AGE_VALUES.SIXTY:
      case AGE_VALUES.SEVENTY:
        return PERSONAL_POSTER[AGE_VALUES.FIFTY]

      default:
        return PERSONAL_POSTER[age][goalAnswer]
    }
  }, [age, goalAnswer])

  return (
    <S.ResultImageContainer className={className}>
      <S.ResultImage src={poster} alt="poster" />
    </S.ResultImageContainer>
  )
}
