import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'
import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSkinTypeExplanation as S } from './SkinTypeExplanation.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const SkinTypeExplanation: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userAnswers = useSelector(selectAnswers)
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort, isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_TYPE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (includeNavigation) {
        setAnswer(value)
        return
      }

      dispatch(
        setAnswersAction({
          answers: value,
          pageId: PageId.SKIN_TYPE_EXPLANATION,
        }),
      )
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [
      dispatch,
      handleChange,
      handleContinue,
      includeNavigation,
      setIsAnswersDisabled,
    ],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.SKIN_TYPE,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange],
  )

  useLayoutEffect(() => {
    const skinTypeAnswer = userAnswers?.[PageId.SKIN_TYPE]
    const skinTypeExplanationAnswer =
      userAnswers?.[PageId.SKIN_TYPE_EXPLANATION]

    if (
      userAnswers &&
      skinTypeAnswer !== OPTION_VALUES.DONT_KNOW &&
      !skinTypeExplanationAnswer
    ) {
      replaceHistory(nextPagePath)
    }
  }, [userAnswers, nextPagePath])

  const handleNextClick = useCallback(() => {
    dispatch(
      setAnswersAction({
        answers: answer,
        pageId: PageId.SKIN_TYPE_EXPLANATION,
      }),
    )
    handleContinue(answer)
  }, [dispatch, answer, handleContinue])

  const handleSkipLinkClick = useCallback(
    () => handleContinue(OPTION_VALUES.DONT_KNOW),
    [handleContinue],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.skinTypeExplanation.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.NORMAL}
          >
            <AnswerButton
              height={
                isSeniorCohort || isTiktokCohort
                  ? AnswerButtonHeight.L
                  : AnswerButtonHeight.M
              }
            >
              <Trans
                i18nKey="onboarding.skinTypeExplanation.normal"
                components={[<strong />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.DRY}
          >
            <AnswerButton
              height={
                isSeniorCohort || isTiktokCohort
                  ? AnswerButtonHeight.L
                  : AnswerButtonHeight.M
              }
            >
              <Trans
                i18nKey="onboarding.skinTypeExplanation.dry"
                components={[<strong />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.OILY}
          >
            <AnswerButton
              height={
                isSeniorCohort || isTiktokCohort
                  ? AnswerButtonHeight.L
                  : AnswerButtonHeight.M
              }
            >
              <Trans
                i18nKey="onboarding.skinTypeExplanation.oily"
                components={[<strong />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.COMBINATION}
          >
            <AnswerButton
              height={
                isSeniorCohort || isTiktokCohort
                  ? AnswerButtonHeight.L
                  : AnswerButtonHeight.M
              }
            >
              <Trans
                i18nKey="onboarding.skinTypeExplanation.combination"
                components={[<strong />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
        {isTiktokCohort && (
          <S.LinkContainer>
            <S.Link onClick={handleSkipLinkClick}>
              {t`onboarding.skinTypeExplanation.stillNotSure`}
            </S.Link>
          </S.LinkContainer>
        )}
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
