import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWhatIsFaceYoga as S } from './WhatIsFaceYogaTiktok.styles'
import { QUESTION } from './constants'

export const WhatIsFaceYogaTiktok: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <S.Wrapper>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.whatIsFaceYogaTiktok.question"
              components={[<strong />, <br />]}
            />
          </PageTitle>
        </S.TitleContainer>
        <S.Description>
          <Trans
            i18nKey="onboarding.whatIsFaceYogaTiktok.description"
            components={[<br />]}
          />
        </S.Description>
        <S.BenefitsContainer>
          <S.Items>
            <S.Item>
              <Trans
                i18nKey="onboarding.whatIsFaceYogaTiktok.option1"
                components={[<br />]}
              />
            </S.Item>
            <S.Item>{t('onboarding.whatIsFaceYogaTiktok.option2')}</S.Item>
            <S.Item>{t('onboarding.whatIsFaceYogaTiktok.option3')}</S.Item>
            <S.Item>{t('onboarding.whatIsFaceYogaTiktok.option4')}</S.Item>
          </S.Items>
        </S.BenefitsContainer>
      </Container>
      <S.BottomButtonContainer>
        <Button onClick={handleNextClick}>{t`actions.continue`}</Button>
      </S.BottomButtonContainer>
    </S.Wrapper>
  )
}
