import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import {
  DEFAULT_CUSTOMER_REVIEWS,
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectCurrentVariantParentCohort } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { useKeywordValue } from 'hooks/useKeywordValue'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { LuvlyBenefits } from 'modules/purchase/components/LuvlyBenefits'
import { LuvlyBenefitsV2 } from 'modules/purchase/components/LuvlyBenefitsV2'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseSubtitle } from 'modules/purchase/components/PurchaseSubtitle'
import { PurchaseTitleV2 } from 'modules/purchase/components/PurchaseTitleV2'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'
import {
  CURRENCY_SYMBOLS,
  PURCHASE_DISCLAIMER,
  PURCHASE_DISCLAIMER_WITHOUT_TAXES,
} from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { PersonalizedPlan } from '../../components/PersonalizedPlan'
import { StyledPurchaseVariant2 as S } from './PurchaseVariant2.styles'

export const PurchaseVariant2: React.FC = () => {
  const { t } = useTranslation()
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const dispatch: TAppDispatch = useDispatch()
  const { pathname, search } = useLocation()
  const keyword = useKeywordValue()
  const hasVatInfo = useVatInfo()
  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const {
    cohort,
    periodName,
    periodQuantity,
    price,
    taxAmount,
    currency,
    hasCancelOffer,
    language,
  } = usePurchaseStore()
  const { arePricesReady } = usePricesStatus()
  const cohortToUse = parentCohort || cohort

  const hasPaypalButton = useMemo(() => language === Locale.ENGLISH, [language])

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const localizationKeys = useMemo(() => {
    if (hasVatInfo) {
      return PURCHASE_DISCLAIMER_WITHOUT_TAXES
    }
    return PURCHASE_DISCLAIMER
  }, [hasVatInfo])

  const disclaimerTextPath = useMemo(() => {
    if (!periodQuantity) return ''

    return localizationKeys[periodQuantity]
  }, [localizationKeys, periodQuantity])

  const screenName = useMemo(() => {
    const isEmailCohort = cohortToUse === Cohort.LUVLY_EMAIL_7
    return isEmailCohort ? ScreenName.EMAIL_OFFER : ScreenName.PURCHASE
  }, [cohortToUse])

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(screenName)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(screenName))

    const vatTag = hasVatInfo
      ? SubscriptionTagsType.TAX
      : SubscriptionTagsType.NO_TAX

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, vatTag))
  }, [dispatch, hasVatInfo, screenName])

  useDefaultSubscription()

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    if (hasCancelOffer) {
      dispatch(setSubscriptionListAction([]))
      goTo({ pathname: PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER, search })
      return
    }
    setIsCheckoutShown(false)
  }, [
    dispatch,
    setIsCheckoutShown,
    pathname,
    cohort,
    productId,
    screenName,
    search,
    hasCancelOffer,
  ])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <PurchaseTitleV2 />
          <PurchaseSubtitle />
          <PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
          <S.Subtitle ref={elemForComparisonRef}>
            {t('purchase1.goalSubtitle')}
          </S.Subtitle>
          <UserGoalGraph pageId={PageId.PURCHASE} />
          {keyword ? <LuvlyBenefitsV2 /> : <LuvlyBenefits />}
          <SubscriptionsBlock />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <MoneyBackGuarantee />
          <CustomerReviews
            customerReviews={
              keyword ? DEFAULT_CUSTOMER_REVIEWS_V2 : DEFAULT_CUSTOMER_REVIEWS
            }
            viewMoreCustomerReviews={
              keyword
                ? DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2
                : DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS
            }
          />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <SecurityInfo />

          <S.Disclaimer>
            <Trans
              i18nKey={disclaimerTextPath}
              values={{
                price: getCalculatedPrice(price),
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              }}
              components={[<br />]}
            />{' '}
            <TermsOfUseLink />.
          </S.Disclaimer>
        </S.Wrapper>
      )}

      {isCheckoutShown && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
          hasPaypalButton={hasPaypalButton}
        />
      )}
    </>
  )
}
