import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import {
  DEFAULT_CUSTOMER_REVIEWS_V2,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'

import { LuvlyBenefits } from 'modules/purchase/components/LuvlyBenefits'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { PurchaseSubtitle } from 'modules/purchase/components/PurchaseSubtitle'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout, CheckoutPrimer } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UserGoal,
} from 'root-constants'

import { StyledPurchaseIntroOffer as S } from './PurchaseIntroOffer.styles'

export const PurchaseIntroOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { pathname } = useLocation()
  const hasVatInfo = useVatInfo()
  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const { cohort, periodName, periodQuantity, price, goal } = usePurchaseStore()
  const { isPrimerActive } = useABTest()

  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const { arePricesReady } = usePricesStatus()

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const purchaseTitle = () => {
    if (goal === UserGoal.BOTH_OPTIONS) {
      return t('purchaseIntroOffer.titleSecond')
    }

    return t('purchaseIntroOffer.titleFirst')
  }

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))

    const pricesTags = `${
      hasVatInfo ? SubscriptionTagsType.TAX : SubscriptionTagsType.NO_TAX
    }`

    dispatch(
      getSubscriptionListAction(SubscriptionListType.PURCHASE, pricesTags),
    )
  }, [dispatch, hasVatInfo])

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.PURCHASE,
    })
    setIsCheckoutShown(false)
  }, [dispatch, setIsCheckoutShown, pathname, cohort, productId])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.Title>{purchaseTitle()}</S.Title>
          <PurchaseSubtitle />
          <S.PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
          <S.Subtitle ref={elemForComparisonRef}>
            {t('purchaseIntroOffer.goalSubtitle')}
          </S.Subtitle>
          <UserGoalGraph pageId={PageId.PURCHASE} />
          <LuvlyBenefits />
          <SubscriptionsIntroOfferBlock />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <PurchaseDisclaimer />
          <MoneyBackGuarantee />
          <CustomerReviews
            customerReviews={DEFAULT_CUSTOMER_REVIEWS_V2}
            viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
          />
          {!isCheckoutShown && (
            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>
          )}
          <SecurityInfo />
        </S.Wrapper>
      )}
      {isCheckoutShown && !isPrimerActive && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
      {isCheckoutShown && isPrimerActive && (
        <CheckoutPrimer
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
    </>
  )
}
