import styled from 'styled-components'

import { CustomerReviews } from 'components/CustomerReviews'

import { Color } from 'root-constants'

export const StyledPurchasePersonalEmail = {
  Wrapper: styled.div``,
  Container: styled.div`
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px 40px;

    &:first-of-type {
      padding: 0 20px 8px;
    }
  `,
  Subtitle: styled.span`
    width: 290px;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PersonalEmailCustomerReviews: styled(CustomerReviews)`
    margin-bottom: 16px;
  `,
  ButtonContainer: styled.div`
    width: 100%;
    margin-bottom: 24px;
  `,
}
