import React, { useMemo } from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { PurchaseIntroOffer } from '../PurchaseIntroOffer'
import { PurchaseVariant7 } from '../PurchaseVariant7'
import { LONG_PAYWALL_COHORTS } from './constants'

export const PurchaseProvider: React.FC = () => {
  const cohortToUse = useCohortToUse()

  const isCohortWithLongPaywall = useMemo(
    () => cohortToUse && LONG_PAYWALL_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  return isCohortWithLongPaywall ? <PurchaseVariant7 /> : <PurchaseIntroOffer />
}
