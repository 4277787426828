import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useTimerForTarget } from 'modules/purchase/hooks'

import { StyledPersonalOfferTimer as S } from './PersonalOfferTimer.styles'

export const PersonalOfferTimer: React.FC = () => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.TimerContainer>
      <S.TimerText>
        {t`purchase1.personalizedPlanDescription.timerTextSecond`}
      </S.TimerText>
      <strong ref={timerElementRef} />
    </S.TimerContainer>
  )
}
