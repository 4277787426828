import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPersonalPurchaseSubtitle = {
  Subtitle: styled.h2`
    max-width: 258px;
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 16px;
  `,
}
