import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectHasInAppOffer,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import {
  selectIsInAppAvailable,
  selectIsUpsellAvailable,
  selectUserCountryCode,
  selectUserPaymentCurrency,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { UpsellWithTimerBenefits } from 'modules/purchase/components/UpsellWithTimerBenefiits'
import { CURRENCY_SYMBOLS, FIVE_MINUTES } from 'modules/purchase/constants'
import {
  usePricesStatus,
  usePurchaseStore,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import doubleChinImage from 'assets/images/double-chin-upsell-option.png'
import faceLiftingImage from 'assets/images/face-lifting-upsell-option.png'
import plus from 'assets/images/plus.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Country,
  OptionType,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellWithTimer as S } from './UpsellWithTimer.styles'
import {
  UPSELL_BOTH_OPTIONS_DISCOUNT,
  UPSELL_SINGLE_OPTION_DISCOUNT,
} from './constants'

export const UpsellWithTimer: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { fetchingActionsList } = usePurchaseStore()
  const { arePricesReady } = usePricesStatus()
  const { search } = useLocation()
  const userCountryCode = useSelector(selectUserCountryCode)
  const currency = useSelector(selectUserPaymentCurrency)
  const isInAppAvailable = useSelector(selectIsInAppAvailable)
  const hasInAppOffer = useSelector(selectHasInAppOffer)
  const subscription = useSelector(selectSubscription)
  const isUpsellAvailable = useSelector(selectIsUpsellAvailable)
  const subscriptions = useSelector(selectSubscriptionList)
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)

  const [isPricesStartedFetching, setIsPricesStartedFetching] =
    useState<boolean>(false)

  useTimerForTarget(timerElementRef, FIVE_MINUTES.IN_SECONDS)

  const tags = useMemo(() => {
    const regionAndVatTag: string =
      userCountryCode === Country.USA
        ? SubscriptionTagsType.USD
        : SubscriptionTagsType.WORLDWIDE

    return `${regionAndVatTag},${SubscriptionTagsType.NO_TAX}`
  }, [userCountryCode])

  useLayoutEffect(() => {
    if (isUpsellAvailable) {
      dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
      setIsPricesStartedFetching(true)
      return
    }
    if (isInAppAvailable && hasInAppOffer) {
      goTo({ pathname: PageId.IN_APP_PAYWALL, search })
    }

    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [
    search,
    isUpsellAvailable,
    dispatch,
    tags,
    isInAppAvailable,
    hasInAppOffer,
  ])

  useLayoutEffect(() => {
    const bothProductsSubscription = subscriptions.find(
      (item) => item.product === UpsellProduct.MIX,
    )
    if (bothProductsSubscription) {
      dispatch(setSelectedSubscriptionAction(bothProductsSubscription))
    }
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)
    }
  }, [subscriptions, isPricesStartedFetching, arePricesReady])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname:
          isInAppAvailable && hasInAppOffer
            ? PageId.IN_APP_PAYWALL
            : PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    arePricesReady,
    hasInAppOffer,
    isInAppAvailable,
    isPricesStartedFetching,
    search,
    subscriptions,
  ])

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction(ScreenName.UPSELL_TIMER))
  }, [dispatch])

  const doubleChinAndFaceLifting = useMemo(
    () => subscriptions.find((item) => item.product === UpsellProduct.MIX),
    [subscriptions],
  )

  const doubleChinSubscription = useMemo(
    () =>
      subscriptions.find((item) => item.product === UpsellProduct.CHIN_SHAPER),
    [subscriptions],
  )

  const faceLiftingSubscription = useMemo(
    () =>
      subscriptions.find((item) => item.product === UpsellProduct.FACE_LIFTING),
    [subscriptions],
  )

  const handleChange = useCallback(
    (value: ISubscription) => {
      dispatch(setSelectedSubscriptionAction(value))
    },
    [dispatch],
  )

  const getPriceLabel = useCallback(
    (amount: number) => `${CURRENCY_SYMBOLS[currency]}${amount}`,
    [currency],
  )

  const getBothOptionsCalculatedOldPrice = useCallback(
    (price: number, discount: number) => {
      const oldPrice = (price / ((100 - discount) / 100)).toFixed(2)
      return getPriceLabel(Number(oldPrice))
    },
    [getPriceLabel],
  )

  const handleContinueWithoutPurchase = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_SWITCHER)
    goTo({
      pathname:
        isInAppAvailable && hasInAppOffer
          ? PageId.IN_APP_PAYWALL
          : PageId.FINISHING_TOUCHES,
      search,
    })
  }, [search, isInAppAvailable, hasInAppOffer])

  if (
    !doubleChinAndFaceLifting ||
    !doubleChinSubscription ||
    !faceLiftingSubscription
  ) {
    return null
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <Container>
      <S.Title>{t('upsellWithTimer.title')}</S.Title>
      <S.Description>{t('upsellWithTimer.description')}</S.Description>
      <S.TimerContainer>
        <S.TimerContainerText>
          {t('purchase7.duplicateTimer')} <strong ref={timerElementRef} />{' '}
          {t('purchase7.min')}
        </S.TimerContainerText>
      </S.TimerContainer>
      <Option
        type={OptionType.RADIO}
        name="subscription"
        value={doubleChinAndFaceLifting.id}
        onChange={() => handleChange(doubleChinAndFaceLifting)}
        withoutMargin
      >
        <S.ProductItem
          isSelected={doubleChinAndFaceLifting.id === subscription?.id}
        >
          <S.Discount>
            <Trans
              i18nKey="upsellWithTimer.off"
              values={{
                value: UPSELL_BOTH_OPTIONS_DISCOUNT,
              }}
            />
          </S.Discount>
          <S.ProductDescription
            isSelected={doubleChinAndFaceLifting.id === subscription?.id}
          >
            <S.ProductName image={doubleChinImage}>
              {t('upsellWithTimer.options.chinShaper')}
            </S.ProductName>
            <S.PlusImage src={plus} alt="plus-image" />
            <S.ProductName image={faceLiftingImage}>
              {t('upsellWithTimer.options.faceLifting')}
            </S.ProductName>
            <S.ProductPrices>
              <S.ProductOldPrice>
                {getBothOptionsCalculatedOldPrice(
                  doubleChinAndFaceLifting.mainPrices.fullPrice,
                  UPSELL_BOTH_OPTIONS_DISCOUNT,
                )}
              </S.ProductOldPrice>
              <S.ProductCurrentPrice>
                {getPriceLabel(doubleChinAndFaceLifting.mainPrices.fullPrice)}
              </S.ProductCurrentPrice>
            </S.ProductPrices>
          </S.ProductDescription>
        </S.ProductItem>
      </Option>
      <Option
        type={OptionType.RADIO}
        name="subscription"
        value={doubleChinSubscription.id}
        onChange={() => handleChange(doubleChinSubscription)}
        withoutMargin
      >
        <S.ProductItem
          isSelected={doubleChinSubscription.id === subscription?.id}
        >
          <S.Discount>
            <Trans
              i18nKey="upsellWithTimer.off"
              values={{
                value: UPSELL_SINGLE_OPTION_DISCOUNT,
              }}
            />
          </S.Discount>
          <S.ProductDescription
            isSelected={doubleChinSubscription.id === subscription?.id}
          >
            <S.ProductName image={doubleChinImage}>
              {t('upsellWithTimer.options.chinShaper')}
            </S.ProductName>
            <S.ProductPrices>
              <S.ProductOldPrice>
                {getPriceLabel(
                  doubleChinSubscription.mainPrices.oldPrices.fullPrice,
                )}
              </S.ProductOldPrice>
              <S.ProductCurrentPrice>
                {getPriceLabel(doubleChinSubscription.mainPrices.fullPrice)}
              </S.ProductCurrentPrice>
            </S.ProductPrices>
          </S.ProductDescription>
        </S.ProductItem>
      </Option>
      <Option
        type={OptionType.RADIO}
        name="subscription"
        value={faceLiftingSubscription.id}
        onChange={() => handleChange(faceLiftingSubscription)}
        withoutMargin
      >
        <S.ProductItem
          isSelected={faceLiftingSubscription.id === subscription?.id}
        >
          <S.Discount>
            <Trans
              i18nKey="upsellWithTimer.off"
              values={{
                value: UPSELL_SINGLE_OPTION_DISCOUNT,
              }}
            />
          </S.Discount>
          <S.ProductDescription
            isSelected={faceLiftingSubscription.id === subscription?.id}
          >
            <S.ProductName image={faceLiftingImage}>
              {t('upsellWithTimer.options.faceLifting')}
            </S.ProductName>
            <S.ProductPrices>
              <S.ProductOldPrice>
                {getPriceLabel(
                  faceLiftingSubscription.mainPrices.oldPrices.fullPrice,
                )}
              </S.ProductOldPrice>
              <S.ProductCurrentPrice>
                {getPriceLabel(faceLiftingSubscription.mainPrices.fullPrice)}
              </S.ProductCurrentPrice>
            </S.ProductPrices>
          </S.ProductDescription>
        </S.ProductItem>
      </Option>
      <UpsellWithTimerBenefits />
      <Button onClick={makeUpsell}>{t`upsell.buttonText`}</Button>
      <S.LinkContainer>
        <S.Link onClick={handleContinueWithoutPurchase}>
          {t`upsell.noThanksLink`}
        </S.Link>
      </S.LinkContainer>
      {isUpsellInProgress && <Spinner />}
    </Container>
  )
}
