import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import dayjs from 'dayjs'

import {
  END_WORKING_HOURS,
  START_WORKING_HOURS,
} from 'components/LiveChat/constants'

import {
  selectCurrentVariantParentCohort,
  selectLiveChatWorkingHours,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionTrialPeriod,
  selectIsDiscountApplied,
  selectIsDownloadVisited,
  selectUserPaymentCurrency,
  selectUserPaymentMethod,
  selectUserStatus,
} from 'root-redux/selects/user'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { Currency, PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  selectCurrency,
  selectIsSubscriptionUpgraded,
} from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import {
  Cohort,
  INTRO_EMAIL_COHORTS,
  LIVECHAT_ENABLED_COHORTS,
  LIVECHAT_UPGRADE_COHORTS,
  Locale,
  SEVEN_DAY_TRIAL_DURATION,
  TRIAL_SUBSCRIPTION_AVAILABLE_COHORTS,
} from 'root-constants'

export const useLiveChatAvailability = (): {
  hasLiveChat: boolean
  isLiveChatVisible: boolean
} => {
  const location = useLocation()
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const currency = useSelector(selectCurrency)
  const currencyToUse = paymentCurrency || currency
  const paymentMethod = useSelector(selectUserPaymentMethod)
  const isDownloadVisited = useSelector(selectIsDownloadVisited)
  const isDiscountApplied = useSelector(selectIsDiscountApplied)
  const liveChatWorkingHours = useSelector(selectLiveChatWorkingHours)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const isUpgraded = useSelector(selectIsSubscriptionUpgraded)
  const userStatus = useSelector(selectUserStatus)

  const { cohort, language } = usePurchaseStore()

  const cohortToUse = parentCohort || cohort

  const currentPageId = useMemo(
    () => getPageIdFromPathName(location.pathname),
    [location.pathname],
  )
  const isEmailCohort = useMemo(
    () => INTRO_EMAIL_COHORTS.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const currentTime = Number(dayjs().tz('Europe/Kiev').format('H'))

  const isWorkingHours = useMemo(() => {
    if (liveChatWorkingHours) {
      if (liveChatWorkingHours.startTime > liveChatWorkingHours.endTime) {
        return (
          Number(liveChatWorkingHours.startTime) <= currentTime ||
          Number(liveChatWorkingHours.endTime) > currentTime
        )
      }

      return (
        Number(liveChatWorkingHours.startTime) <= currentTime &&
        currentTime < Number(liveChatWorkingHours.endTime)
      )
    }

    return START_WORKING_HOURS <= currentTime || END_WORKING_HOURS > currentTime
  }, [liveChatWorkingHours, currentTime])

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const isSupportedCurrency = useMemo(
    () =>
      currencyToUse === Currency.USD ||
      currencyToUse === Currency.EUR ||
      currencyToUse === Currency.GBP,
    [currencyToUse],
  )

  const isUpgradeCohort = useMemo(
    () => LIVECHAT_UPGRADE_COHORTS.includes((parentCohort || cohort) as Cohort),
    [cohort, parentCohort],
  )

  const isLiveChatVisible = useMemo(() => {
    if (isUpgradeCohort) return false

    if (isEmailCohort) {
      return currentPageId === PageId.PURCHASE_INTRO_OFFER
    }

    return getPageIdFromPathName(location.pathname) === PageId.DOWNLOAD
  }, [isUpgradeCohort, location.pathname, isEmailCohort, currentPageId])

  const isTrialSubscription = useMemo(() => {
    const isCohortIncludesTrialSubscription =
      TRIAL_SUBSCRIPTION_AVAILABLE_COHORTS.includes(
        (parentCohort || cohort) as Cohort,
      )

    return (
      isCohortIncludesTrialSubscription &&
      trialPeriodDays === SEVEN_DAY_TRIAL_DURATION
    )
  }, [cohort, parentCohort, trialPeriodDays])

  const isPaypalPaymentMethod = useMemo(() => {
    if (isEmailCohort) return false
    return paymentMethod === PaymentMethod.PAYPAL
  }, [paymentMethod, isEmailCohort])

  const isLiveChatEnabledCohort = useMemo(() => {
    const isLiveChatCohort = LIVECHAT_ENABLED_COHORTS.includes(
      cohortToUse as Cohort,
    )

    return isUpgradeCohort || isLiveChatCohort || isEmailCohort
  }, [cohortToUse, isUpgradeCohort, isEmailCohort])

  const isLiveChatAvailable = useMemo(() => {
    if (isUpgradeCohort) {
      return (
        getPageIdFromPathName(location.pathname) === PageId.PURCHASE_UPGRADE &&
        isUpgraded
      )
    }

    if (isEmailCohort) {
      return (
        currentPageId === PageId.PURCHASE_INTRO_OFFER ||
        currentPageId === PageId.PURCHASE_INTRO_CANCEL_OFFER
      )
    }

    return (
      (currentPageId === PageId.LOGIN || currentPageId === PageId.DOWNLOAD) &&
      (userStatus?.hasSubscription as boolean)
    )
  }, [
    currentPageId,
    isUpgradeCohort,
    isUpgraded,
    location.pathname,
    isEmailCohort,
    userStatus?.hasSubscription,
  ])

  const hasLiveChat = useMemo(
    () =>
      isWorkingHours &&
      isEnglishLang &&
      isSupportedCurrency &&
      !isPaypalPaymentMethod &&
      (isEmailCohort || !isDiscountApplied) &&
      isLiveChatEnabledCohort &&
      (isUpgradeCohort || !isDownloadVisited) &&
      !isTrialSubscription &&
      isLiveChatAvailable,
    [
      isUpgradeCohort,
      isWorkingHours,
      isEnglishLang,
      isSupportedCurrency,
      isPaypalPaymentMethod,
      isEmailCohort,
      isDiscountApplied,
      isLiveChatEnabledCohort,
      isDownloadVisited,
      isTrialSubscription,
      isLiveChatAvailable,
    ],
  )

  return { hasLiveChat, isLiveChatVisible }
}
