import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import dayjs from 'dayjs'

import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  sendFacebookParamsAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { TAppDispatch } from 'root-redux/store/store'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import arrow from 'assets/images/arrow.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { OptionType, SubscriptionListType } from 'root-constants'

import { StyledChooseTrialPrice as S } from './ChooseTrialPrice.styles'

export const ChooseTrialPrice: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { subscriptions } = usePurchaseStore()
  const dispatch: TAppDispatch = useDispatch()
  const infoRef = useRef<HTMLParagraphElement>(null)

  const [priceId, setPriceId] = useState<string | null>(null)

  const optionProps = useMemo(
    () => ({
      type: OptionType.RADIO,
      name: 'priceOption',
      onChange: (id: string) => {
        setPriceId(id)
      },
    }),
    [],
  )

  const { arePricesReady } = usePricesStatus()

  const highestTrialPriceId = useMemo(
    () => subscriptions.find((subs) => subs.isDefault)?.id,
    [subscriptions],
  )

  useEffect(() => {
    highestTrialPriceId && setPriceId(highestTrialPriceId)
  }, [highestTrialPriceId])

  useEffect(() => eventLogger.logTrialPricePageShown(), [])

  useEffect(() => {
    if (priceId && infoRef.current) {
      infoRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [priceId])

  useLayoutEffect(() => {
    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE))
  }, [dispatch])

  const handleNextClick = useCallback(() => {
    dispatch(sendFacebookParamsAction())

    const selectedSubscription = subscriptions.find(
      (subscription) => subscription.id === priceId,
    )

    if (selectedSubscription) {
      dispatch(setSelectedSubscriptionAction(selectedSubscription))
      dispatch(sendUserAnswersAction(null))
      dispatch(setSubscriptionListAction([selectedSubscription]))

      eventLogger.logTrialPricePageCompleted(
        selectedSubscription.trialPrices.fullPrice,
        selectedSubscription.currency,
      )

      goTo({
        pathname: PageId.PURCHASE_VARIANT_3,
        search,
      })
    }
  }, [subscriptions, priceId, dispatch, search])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      <S.TitleContainer>
        <S.Title>{t('purchase3.chooseTrialPrice.title')}</S.Title>
        <S.Description>
          <Trans
            i18nKey="purchase3.chooseTrialPrice.description"
            components={{ strong: <strong />, break: <p /> }}
          />
        </S.Description>
      </S.TitleContainer>
      <Container>
        <S.OptionTitle>
          {t('purchase3.chooseTrialPrice.subtitle')}
        </S.OptionTitle>
        <S.OptionsContainer>
          {subscriptions.map((subscription) => (
            <Option
              {...optionProps}
              key={subscription.id}
              value={subscription.id}
            >
              <S.OptionButton isSelected={priceId === subscription.id}>
                {CURRENCY_SYMBOLS[subscription.currency]}
                {subscription.trialPrices.fullPrice}
              </S.OptionButton>
            </Option>
          ))}
        </S.OptionsContainer>
        <S.DescriptionContainer isSelected={priceId === highestTrialPriceId}>
          <S.OptionDescription>
            {t('purchase3.chooseTrialPrice.optionDescription')}
          </S.OptionDescription>
          <S.Image src={arrow} height={42} />
        </S.DescriptionContainer>
        {priceId === highestTrialPriceId && (
          <S.Highlighted>
            {t('purchase3.chooseTrialPrice.thankYou')}
          </S.Highlighted>
        )}
        <S.Button disabled={!priceId} onClick={handleNextClick}>
          {t('actions.continue')}
        </S.Button>
        <S.Info ref={infoRef}>
          {t('purchase3.chooseTrialPrice.date', {
            date: dayjs().format('MMM YYYY'),
          })}
        </S.Info>
      </Container>
    </>
  )
}
