import styled from 'styled-components'

import primaryCheck from 'assets/images/primary-check.png'

import { BottomButtonContainer as ButtonContainer } from 'common-styles'
import { Color } from 'root-constants'

export const StyledWhatIsFaceYoga = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  TitleContainer: styled.div`
    margin-bottom: 28px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Description: styled.p`
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  BenefitsContainer: styled.div`
    width: 100%;
    border-radius: 16px;
    padding: 16px;
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
  `,
  Items: styled.ul``,
  Item: styled.li`
    flex-basis: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.LIGHT};
    padding-left: 28px;
    margin-bottom: 12px;
    position: relative;

    &::before {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      background: url(${primaryCheck}) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  BottomButtonContainer: styled(ButtonContainer)`
    padding: 40px 16px;
  `,
}
