import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Glasses } from 'components/Glasses'
import { NavigationButtons } from 'components/NavigationButtons'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledDailyWater as S } from './DailyWater.styles'
import { QUESTION } from './constants'

export const DailyWater: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [selectedQuantity, setSelectedQuantity] = useState<string>('')
  const cohortToUse = useCohortToUse()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort, isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(selectedQuantity),
    [selectedQuantity, handleContinue],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer hasLargeOffset={isTiktokCohort}>
          <PageTitle>
            <Trans
              i18nKey="onboarding.dailyWater.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        {!isSeniorCohort && !isTiktokCohort && (
          <S.DescriptionContainer>
            <PageTitleDescription>
              {t`onboarding.dailyWater.description`}
            </PageTitleDescription>
          </S.DescriptionContainer>
        )}
        <S.GlassesContainer>
          <Glasses
            selectedQuantity={selectedQuantity}
            setSelectedQuantity={setSelectedQuantity}
          />
        </S.GlassesContainer>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!selectedQuantity}
        />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!selectedQuantity}>
            {t`actions.continue`}
          </Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
