import { UpsellProduct } from 'root-constants'

export const CHIN_SHAPER_BENEFITS = [
  { id: 1, text: 'upsellWithTimer.benefits.option1' },
  { id: 2, text: 'upsellWithTimer.benefits.option2' },
  { id: 3, text: 'upsellWithTimer.benefits.option4' },
]

export const FACE_LIFTING_BENEFITS = [
  { id: 1, text: 'upsellWithTimer.benefits.option5' },
  { id: 2, text: 'upsellWithTimer.benefits.option3' },
  { id: 3, text: 'upsellWithTimer.benefits.option6' },
]

export const BOTH_OPTIONS_BENEFITS = [
  { id: 1, text: 'upsellWithTimer.benefits.option1' },
  { id: 2, text: 'upsellWithTimer.benefits.option2' },
  { id: 3, text: 'upsellWithTimer.benefits.option4' },
  { id: 4, text: 'upsellWithTimer.benefits.option5' },
  { id: 5, text: 'upsellWithTimer.benefits.option6' },
]

export const BENEFITS_MAP = {
  [UpsellProduct.CHIN_SHAPER]: CHIN_SHAPER_BENEFITS,
  [UpsellProduct.FACE_LIFTING]: FACE_LIFTING_BENEFITS,
  [UpsellProduct.MIX]: BOTH_OPTIONS_BENEFITS,
}
