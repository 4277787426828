import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { CDN_FOLDER_LINK, Locale } from 'root-constants'

import { StyledLongPaywallUserBenefits as S } from './LongPaywallUserBenefits.styles'
import { DAILY_SCHEDULE_IMAGE_PATH, USER_BENEFITS } from './constants'

export const LongPaywallUserBenefits: React.FC = () => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  return (
    <S.Wrapper>
      <S.Subtitle isEnLocaleStyle={isEnLocale}>
        {t('purchase7.userBenefits.title')}
      </S.Subtitle>
      <S.BenefitCardsWrapper>
        {USER_BENEFITS.map(({ id, image, bgColor, text }) => (
          <S.BenefitCard key={id}>
            <S.BenefitImage src={image} alt="benefit-image" />
            <S.Benefit bgColor={bgColor} isEnLocaleStyle={isEnLocale}>
              {t(text)}
            </S.Benefit>
          </S.BenefitCard>
        ))}
      </S.BenefitCardsWrapper>
      <S.Subtitle isEnLocaleStyle={isEnLocale}>
        {t('purchase7.dailyRitualsSubtitle')}
      </S.Subtitle>
      <S.DailyScheduleImage
        src={`${CDN_FOLDER_LINK}${DAILY_SCHEDULE_IMAGE_PATH}_${language}.png`}
        alt="daily-schedule"
      />
    </S.Wrapper>
  )
}
