import { PaymentMethodType } from '@primer-io/checkout-web'

import { Color, SubscriptionProduct } from 'root-constants'

export const FIFTEEN_MINUTES_IN_SECONDS = 900
export const FIVE_MINUTES = {
  IN_SECONDS: 300,
  AS_STRING: '5:00',
}
export const TEN_MINUTES = {
  IN_SECONDS: 600,
  AS_STRING: '10:00',
}
export const Y_OFFSET = 60
export const Y_OFFSET_CANCEL_OFFER = 10

export const MONTHS_IN_A_YEAR = 12

export const DEFAULT_DYNAMIC_DISCOUNT = 50
export const ONE_HUNDRED_PERCENTAGE = 100
export const DECORATING_DISCOUNT = 0.01

export const enum SubscriptionPeriod {
  MONTHLY = 1,
  QUARTERLY = 3,
  SEMI_ANNUALLY = 6,
}

export const enum AnimationState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_CARDHOLDER_NAME = ' '

export const DEFAULT_ERROR_DESCRIPTION =
  'Please check your payment information or contact your bank and try again, or try a new payment method.'

export const enum StripeErrorCode {
  CARD_DECLINED = 'card_declined',
}

export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  PRIMER = 'primer',
  STRIPE = 'stripe',
}

export type TCardPaymentSystem = PaymentSystem.PRIMER | PaymentSystem.STRIPE

export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  MXN = 'mxn',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.MXN]: 'MXN',
}

export const PURCHASE_DISCLAIMER = {
  [SubscriptionPeriod.MONTHLY]: 'purchase1.ratingInfo.monthlySubscription',
  [SubscriptionPeriod.QUARTERLY]: 'purchase1.ratingInfo.quarterlySubscription',
  [SubscriptionPeriod.SEMI_ANNUALLY]:
    'purchase1.ratingInfo.semiAnnuallySubscription',
}

export const PURCHASE_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionPeriod.MONTHLY]:
    'purchase1.ratingInfo.monthlySubscriptionWithoutTaxes',
  [SubscriptionPeriod.QUARTERLY]:
    'purchase1.ratingInfo.quarterlySubscriptionWithoutTaxes',
  [SubscriptionPeriod.SEMI_ANNUALLY]:
    'purchase1.ratingInfo.semiAnnuallySubscriptionWithoutTaxes',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscription',
  [SubscriptionProduct.ONE_MONTH]: 'purchaseIntroOffer.oneMonthSubscription',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscription',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxes',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxes',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxes',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscriptionSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionSale',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxesSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxesSale',
}

export const CARDHOLDER_NAME_MIN_LENGTH = 1
export const enum PrimerErrorCode {
  CARD_REQUIRED = 'cardRequired',
  CARD_IS_INCOMPLETE = 'cardIncomplete',
  CARD_IS_INVALID = 'cardInvalid',
  EXPIRY_REQUIRED = 'expiryRequired',
  CARD_IS_EXPIRED = 'cardExpired',
  CARD_EXPIRY_YEAR_INVALID = 'expiryYearInvalid',
  CARD_EXPIRY_MONTH_INVALID = 'expiryMonthInvalid',
  CVV_IS_INCOMPLETE = 'cvvIncomplete',
  CVV_IS_INVALID = 'cvvInvalid3',
}

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'purchase1.insufficientFunds',
  PAYMENT_FAILED: 'purchase1.insufficientFunds',
  DECLINED: 'purchaseIntroOffer.declinedError',
  AUTHENTICATION_REQUIRED: 'purchaseIntroOffer.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'purchaseIntroOffer.withdrawLimit',
  EXPIRED_CARD: 'purchaseIntroOffer.expiredCard',
  COMMON_ERROR: 'login.somethingWentWrongError',
  ERROR: 'login.somethingWentWrongError',
  INVALID_CARD_NUMBER: 'login.somethingWentWrongError',
  LOST_OR_STOLEN_CARD: 'login.somethingWentWrongError',
  SUSPECTED_FRAUD: 'login.somethingWentWrongError',
  UNKNOWN: 'login.somethingWentWrongError',
  REFER_TO_CARD_ISSUER: 'login.somethingWentWrongError',
  DO_NOT_HONOR: 'login.somethingWentWrongError',
  ISSUER_TEMPORARILY_UNAVAILABLE: 'login.somethingWentWrongError',
}

export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'
export const PRIMER_CARD_NUMBER_ID = 'checkout-card-number-input'
export const PRIMER_CARD_EXPIRY_ID = 'checkout-card-expiry-input'
export const PRIMER_CARD_CVC_ID = 'checkout-card-cvv-input'
export const PRIMER_GOOGLE_PAY_ID = 'google-pay-button'
export const PRIMER_APPLE_PAY_ID = 'apple-pay-button'

export const PRIMER_PAYMENT_METHODS_MAP = {
  [PaymentMethodType.PAYMENT_CARD]: PaymentMethod.CREDIT_CARD,
  [PaymentMethodType.APPLE_PAY]: PaymentMethod.APPLE_PAY,
  [PaymentMethodType.GOOGLE_PAY]: PaymentMethod.GOOGLE_PAY,
  [PaymentMethodType.PAYPAL]: PaymentMethod.PAYPAL,
}

export const enum PrimerFormVariants {
  DEFAULT = 'default',
  SEPARATED = 'separated',
}

export const PRIMER_FORM_COMMON_STYLES = {
  input: {
    base: {
      paddingLeft: '0',
      borderRadius: '16px',
      color: Color.DARK,
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '50px',
      fontSmoothing: 'antialiased',
      placeholder: {
        color: '#81848c',
        fontWeight: '400',
      },
    },
    error: {
      color: Color.ERROR,
      caretColor: Color.DARK,
      focus: {
        color: Color.DARK,
        caretColor: Color.DARK,
      },
    },
  },
}

export const INITIAL_PRIMER_CONFIG = {
  card: {
    cardholderName: {
      required: false,
    },
  },
  style: {
    stylesheets: [
      {
        href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
      },
    ],
  },
}

export const CARDHOLDER_NAME_REGEX =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
