import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import {
  selectCurrentVariant,
  selectCurrentVariantParentCohort,
  selectIsInContextShown,
} from 'root-redux/selects/common'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { browserHistory } from 'browser-history'
import {
  COHORT_QUERY_PARAM,
  Cohort,
  CurrentEnvironment,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  Language,
  SUPPORTED_LOCALES,
  SUPPORTED_LOCALES_MAP,
} from 'root-constants'

export const useLanguage = (isUnsubscribeFlow?: boolean): void => {
  const { pathname, search } = document.location
  const dispatch = useDispatch()
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const isInContextShown = useSelector(selectIsInContextShown)

  const isDevEnvironment = getCurrentEnv() === CurrentEnvironment.DEV

  const isInContextActive = useMemo(
    () => isDevEnvironment && isInContextShown,
    [isDevEnvironment, isInContextShown],
  )

  useEffect(() => {
    if (!variant) return
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''
    const cohort = URLParams.get(COHORT_QUERY_PARAM)

    const supportedLocales = isUnsubscribeFlow
      ? SUPPORTED_LOCALES
      : SUPPORTED_LOCALES_MAP[(parentCohort as Cohort) || (cohort as Cohort)]
    if (!supportedLocales?.includes(langQueryParam)) {
      dispatch(
        setLanguageAction(
          isInContextActive ? Language.IN_CONTEXT : INITIAL_LANGUAGE,
        ),
      )
      browserHistory.replace({
        pathname,
        search: URLParams.toString(),
      })
      return
    }

    dispatch(
      setLanguageAction(
        isInContextActive ? Language.IN_CONTEXT : langQueryParam,
      ),
    )
  }, [
    dispatch,
    pathname,
    search,
    isUnsubscribeFlow,
    parentCohort,
    variant,
    isInContextActive,
  ])
}
