import styled from 'styled-components'

import optionIconUrl from 'assets/images/check-icon-green-2.svg'
import bgWhatYouGetUrl from 'assets/images/luvly-benefits-bg.png'

import { Color } from 'root-constants'

export const StyledUpsellWithTimerBenefits = {
  BenefitsWrapper: styled.div`
    margin: 0 -20px;
    padding: 30px 28px 75px 36px;
    background-image: url(${bgWhatYouGetUrl});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  `,
  BenefitsSubtitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
    padding: 0 5px 0 34px;

    & > strong {
      font-weight: 500;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }
  `,
  Details: styled.div`
    max-width: 320px;
    margin: 0 auto 24px auto;
  `,
  Detailed: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }
  `,
  DetailedInfo: styled.div`
    flex-grow: 1;
    &:first-child {
      margin-right: 16px;
    }
  `,
  DetailedPicture: styled.img`
    min-width: 88px;

    &:first-child {
      margin-right: 16px;
    }
  `,
  DetailedTitle: styled.h3`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  DetailedDescription: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.LIGHT};
  `,
  Disclaimer: styled.div`
    max-width: 320px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 14px;
    padding: 16px 8px;
    margin: 0 auto 24px auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.LIGHT};
    text-align: center;

    a {
      color: #0091ff;
      text-decoration: underline;
    }
  `,
  SupportLink: styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0091ff;
    text-decoration: underline;
  `,
}
