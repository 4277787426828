/* eslint-disable max-lines */
import { paymentApi, userApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  getUserStatusAction,
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserOnboardingEmail,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import {
  PRIMER_PAYMENT_ERRORS,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { logSuccessfulPayment } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectInAppId,
  selectInAppPurchaseUserData,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
} from 'modules/purchase/redux/selects/common'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { IInAppPurchaseUserData } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import {
  Cohort,
  IN_APP_META_DATA_MAP,
  IN_APP_PRODUCT_EVENT_NAMES_MAP,
  InAppProduct,
  ScreenName,
  UPSELL_PRODUCT_EVENT_NAMES_MAP,
  UpsellProduct,
} from 'root-constants'

const MODULE_NAME = 'UPSELL'

export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`
export const CHARGE_IN_APP = `${MODULE_NAME}/CHARGE_IN_APP`
export const CHARGE_WELLNESS_BUNDLE = `${MODULE_NAME}/CHARGE_WELLNESS_BUNDLE`
export const SET_IN_APP_PURCHASE_USER_DATA = `${MODULE_NAME}/SET_INAPP_PURCHASE_USER_DATA`

export const makeUpsellAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const priceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentSystem = selectUserPaymentSystem(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort

    dispatch(startFetching(MAKE_UPSELL))

    try {
      const commonPurchaseStartedParams =
        getStripePurchaseStartedEventParams(state)
      const commonPurchaseSuccessParams =
        getStripePurchaseSuccessEventParams(state)
      const commonPurchaseFailedParams =
        getStripePurchaseFailedEventParams(state)

      eventLogger.logPurchaseStarted({
        ...commonPurchaseStartedParams,
        paymentMethod: PaymentMethod.CREDIT_CARD,
        paymentSystem,
        screenName,
        productName: productId,
      })

      const upsellResponse =
        paymentSystem === PaymentSystem.PRIMER
          ? await paymentApi.makePrimerUpsell({
              uuid,
              priceId,
              trialPriceId,
              productId,
              cohort,
            })
          : await paymentApi.makeUpsell({
              uuid,
              priceId,
              trialPriceId,
              productId,
              cohort,
            })

      if (upsellResponse.success && upsellResponse.data) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          productId: upsellResponse.data.subscription.product_id,
          price: currentPrice,
          trialPeriodDays,
          subscriptionId: upsellResponse.data.subscription.subscription_id,
          paymentMethod: PaymentMethod.CREDIT_CARD,
          screenName,
          paymentSystem,
          productName: productId,
        })

        dispatch(
          sendUserConfigAction({
            upsell_product: `${
              UPSELL_PRODUCT_EVENT_NAMES_MAP[productId as UpsellProduct]
            }, ${currentPrice}`,
          }),
        )

        dispatch(getUserStatusAction(uuid))
        dispatch(stopFetching(MAKE_UPSELL))
        return
      }

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: { type: upsellResponse.data },
        paymentMethod: PaymentMethod.CREDIT_CARD,
        screenName,
        paymentSystem,
        productName: productId,
      })

      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(MAKE_UPSELL))
    }
  }

export const makeWellnessBundleUpsellAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const priceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentSystem = selectUserPaymentSystem(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort

    dispatch(startFetching(CHARGE_WELLNESS_BUNDLE))

    try {
      const commonPurchaseStartedParams =
        getStripePurchaseStartedEventParams(state)
      const commonPurchaseSuccessParams =
        getStripePurchaseSuccessEventParams(state)
      const commonPurchaseFailedParams =
        getStripePurchaseFailedEventParams(state)

      eventLogger.logPurchaseStarted({
        ...commonPurchaseStartedParams,
        paymentMethod: PaymentMethod.CREDIT_CARD,
        paymentSystem,
        screenName,
        productName: productId,
      })

      const upsellResponse =
        paymentSystem === PaymentSystem.PRIMER
          ? await paymentApi.makePrimerUpsell({
              uuid,
              priceId,
              trialPriceId,
              productId,
              cohort,
            })
          : await paymentApi.makeUpsell({
              uuid,
              priceId,
              trialPriceId,
              productId,
              cohort,
            })

      if (upsellResponse.success && upsellResponse.data) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          productId: upsellResponse.data.subscription.product_id,
          price: currentPrice,
          trialPeriodDays,
          subscriptionId: upsellResponse.data.subscription.subscription_id,
          paymentMethod: PaymentMethod.CREDIT_CARD,
          screenName,
          paymentSystem,
          productName: productId,
        })

        dispatch(getUserStatusAction(uuid))
        dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: { type: upsellResponse.data },
        paymentMethod: PaymentMethod.CREDIT_CARD,
        screenName,
        paymentSystem,
        productName: productId,
      })

      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
    } catch (error: any) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
    }
  }

export const setInAppPurchaseUserDataAction = (
  payload: IInAppPurchaseUserData,
): IAction<IInAppPurchaseUserData> => ({
  type: SET_IN_APP_PURCHASE_USER_DATA,
  payload,
})

export function inAppPurchaseAction(screenName: ScreenName): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const selectedInAppId = selectInAppId(state)
    const inAppPurchaseUserData = selectInAppPurchaseUserData(state)
    const email = selectUserOnboardingEmail(state)
    const currency = selectCurrency(state)
    const selectedSubscription = selectSubscription(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort

    dispatch(startFetching(CHARGE_IN_APP))

    eventLogger.logInAppPurchaseStarted({
      price: selectedSubscription?.trialPrices.fullPrice || 0,
      currency,
      productId:
        IN_APP_PRODUCT_EVENT_NAMES_MAP[
          selectedSubscription?.product as InAppProduct
        ],
      screenName,
    })

    try {
      const response = await paymentApi.createPrimerInAppPurchase({
        uuid,
        planId: Number(selectedInAppId),
        cohort,
      })

      if (
        response.success &&
        response?.data?.inapp?.payment_id &&
        selectedSubscription
      ) {
        eventLogger.logGuashaInAppPurchaseCompleted({
          ...inAppPurchaseUserData,
          transactionId: response.data.inapp.payment_id,
          email,
          price: selectedSubscription.trialPrices.fullPrice,
          sku: IN_APP_META_DATA_MAP[
            selectedSubscription.product as InAppProduct
          ].SKU,
          barcode:
            IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
              .BARCODE,
          itemName:
            IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
              .NAME,
        })

        eventLogger.logInAppPurchaseCompleted({
          price: selectedSubscription.trialPrices.fullPrice,
          currency,
          productId:
            IN_APP_PRODUCT_EVENT_NAMES_MAP[
              selectedSubscription?.product as InAppProduct
            ],
          screenName,
        })

        await dispatch(
          savePlanAdditionsAction([
            IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
              .NAME,
          ]),
        )

        dispatch(stopFetching(CHARGE_IN_APP))

        return
      }

      if (!response.success) {
        eventLogger.logGuaShaPurchaseFailed({
          error: response?.data?.error,
          ...inAppPurchaseUserData,
          transactionId: response?.data?.inapp?.payment_id,
          email,
          price: selectedSubscription?.trialPrices.fullPrice,
          ...(selectedSubscription?.product && {
            sku: IN_APP_META_DATA_MAP[
              selectedSubscription.product as InAppProduct
            ].SKU,
            barcode:
              IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
                .BARCODE,
            itemName:
              IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
                .NAME,
          }),
        })
        eventLogger.logInAppPurchaseFailed({
          error: {
            description: response?.data?.error,
          },
          price: selectedSubscription?.trialPrices.fullPrice,
          currency,
          screenName,
          ...(selectedSubscription?.product && {
            productId:
              IN_APP_PRODUCT_EVENT_NAMES_MAP[
                selectedSubscription?.product as InAppProduct
              ],
          }),
        })

        dispatch(
          setErrorAction(
            response.data.error
              ? i18n.t(
                  PRIMER_PAYMENT_ERRORS[response.data.error] ||
                    PRIMER_PAYMENT_ERRORS.COMMON_ERROR,
                )
              : i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR),
          ),
        )

        dispatch(
          sendUserConfigAction({
            failed_inapp_product:
              IN_APP_PRODUCT_EVENT_NAMES_MAP[
                selectedSubscription?.product as InAppProduct
              ],
          }),
        )

        dispatch(stopFetching(CHARGE_IN_APP))

        return
      }

      dispatch(stopFetching(CHARGE_IN_APP))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        eventLogger.logInAppPurchaseFailed({
          error: {
            description: error.toString(),
          },
          price: selectedSubscription?.trialPrices.fullPrice,
          currency,
          screenName,
          ...(selectedSubscription?.product && {
            productId:
              IN_APP_PRODUCT_EVENT_NAMES_MAP[
                selectedSubscription?.product as InAppProduct
              ],
          }),
        })
        eventLogger.logGuaShaPurchaseFailed({
          error: error.toString(),
          ...inAppPurchaseUserData,
          email,
          price: selectedSubscription?.trialPrices.fullPrice,
          ...(selectedSubscription?.product && {
            sku: IN_APP_META_DATA_MAP[
              selectedSubscription.product as InAppProduct
            ].SKU,
            barcode:
              IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
                .BARCODE,
            itemName:
              IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
                .NAME,
          }),
        })
        dispatch(stopFetching(CHARGE_IN_APP))

        return
      }

      dispatch(setErrorAction(String(error)))
      eventLogger.logInAppPurchaseFailed({
        error: {
          description: String(error),
        },
        price: selectedSubscription?.trialPrices.fullPrice,
        currency,
        screenName,
        ...(selectedSubscription?.product && {
          productId:
            IN_APP_PRODUCT_EVENT_NAMES_MAP[
              selectedSubscription?.product as InAppProduct
            ],
        }),
      })
      eventLogger.logGuaShaPurchaseFailed({
        error: String(error),
        ...inAppPurchaseUserData,
        email,
        price: selectedSubscription?.trialPrices.fullPrice,
        ...(selectedSubscription?.product && {
          sku: IN_APP_META_DATA_MAP[
            selectedSubscription.product as InAppProduct
          ].SKU,
          barcode:
            IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
              .BARCODE,
          itemName:
            IN_APP_META_DATA_MAP[selectedSubscription.product as InAppProduct]
              .NAME,
        }),
      })
      dispatch(
        sendUserConfigAction({
          failed_inapp_product:
            IN_APP_PRODUCT_EVENT_NAMES_MAP[
              selectedSubscription?.product as InAppProduct
            ],
        }),
      )
      dispatch(stopFetching(CHARGE_IN_APP))
    }
  }
}

export function saveDeliveryAddressAction(screenName: ScreenName): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const inAppPurchaseUserData = selectInAppPurchaseUserData(state)

    if (!inAppPurchaseUserData) return

    dispatch(startFetching(CHARGE_IN_APP))

    try {
      const response = await userApi.saveUserAddress({
        uuid,
        inAppPurchaseUserData,
      })

      if (response.success && response.data) {
        dispatch(inAppPurchaseAction(screenName))
        return
      }

      if (!response.success) {
        dispatch(
          setErrorAction(
            response.data.error || i18n.t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR),
          ),
        )
        dispatch(stopFetching(CHARGE_IN_APP))

        return
      }
      dispatch(stopFetching(CHARGE_IN_APP))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        dispatch(stopFetching(CHARGE_IN_APP))

        return
      }

      dispatch(setErrorAction(String(error)))
      dispatch(stopFetching(CHARGE_IN_APP))
    }
  }
}
