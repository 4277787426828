import UserAvatars1 from 'assets/images/users-avatars-1.png'
import UserAvatars2 from 'assets/images/users-avatars-2.png'
import UserAvatars3 from 'assets/images/users-avatars-3.png'

export const QUESTION = 'Getting healthy skin just became easy with Luvly'

export const USER_AVATARS = [
  { id: '1', image: UserAvatars1 },
  { id: '2', image: UserAvatars2 },
  { id: '3', image: UserAvatars3 },
]
