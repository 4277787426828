import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { DomainsContainer } from 'components/DomainsContainer'
import { ErrorNotification } from 'components/ErrorNotification'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { Modal } from 'components/Modal'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'

import {
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
  I18N_CONTEXT_COHORT_MAP,
} from 'root-constants'

import { StyledEmail as S } from './Email.styles'

export const Email: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)

  const cohortToUse = useCohortToUse()
  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const [isErrorModalShown, setIsErrorModalShown] = useState(false)
  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)

  const [email, setEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])
  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const errorText = useMemo(
    () => (!email.isValid && !domainsList.length ? email.validationText : ''),
    [email.isValid, email.validationText, domainsList],
  )

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!email.isValid) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
          isConsentRequired: true,
        }),
      )

      eventLogger.logEmailPageCompleted({ email: email.value })
      window.fbq('track', 'Lead', {}, { eventID: uuid })
      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq && window.ttq.track('CompleteRegistration', { event_id: uuid })
      window.rdt &&
        window.rdt('track', 'Lead', {
          email: isPersonalDataAllowed ? email.value : '',
          externalId: uuid,
        })
      window.snaptr &&
        window.snaptr('track', 'SIGN_UP', {
          user_email: isPersonalDataAllowed ? email.value : '',
        })
      window.obApi && window.obApi('track', 'Lead')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'complete_registration',
        })
    },
    [
      dispatch,
      email.isValid,
      email.value,
      handleErrorModal,
      uuid,
      isPersonalDataAllowed,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
      setAreEmailTipsVisible(true)
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  const handlePrefilledEmail = useCallback(
    ({ target }) => {
      setAreEmailTipsVisible(false)
      setEmail((prevState) => ({
        ...prevState,
        value: target.value,
      }))
    },
    [setEmail],
  )

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <div>
      <S.Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.email.title"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        <S.DescriptionContainer>
          <PageTitleDescription>
            {t('onboarding.email.subtitle')}
          </PageTitleDescription>
        </S.DescriptionContainer>
        <form onSubmit={handleSubmit}>
          <S.InputContainer>
            <InputWithFloatPlaceholder
              value={email.value}
              data-testid="email-input"
              isValid={isEmailValid}
              labelName={t`onboarding.email.inputPlaceholder`}
              hasValidationIcon
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {areEmailTipsVisible && (
              <DomainsContainer>
                {domainsList.map((item) => (
                  <button
                    type="button"
                    key={item}
                    value={item}
                    onClick={handlePrefilledEmail}
                  >
                    {item}
                  </button>
                ))}
              </DomainsContainer>
            )}
          </S.InputContainer>
          <S.Disclaimer>
            <Trans
              i18nKey="onboarding.email.disclaimer"
              components={[<br />]}
            />
          </S.Disclaimer>
          <S.ButtonContainer>
            <ErrorNotification errorText={errorText} />
            <Button
              type="submit"
              disabled={!email.value || !isEmailValid}
              data-testid="confirm-email-btn"
            >
              {t`actions.getMyPlan`}
            </Button>
          </S.ButtonContainer>
        </form>
      </S.Container>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </div>
  )
}
