import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants'

export const StyledPersonalPurchasePoster = {
  ResultImageContainer: styled.div`
    max-width: 360px;
    margin: 0 auto 24px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
    }
  `,
  ResultImage: styled.img`
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
  `,
}
