import styled from 'styled-components'

import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'

import { Color } from 'root-constants'

export const StyledPersonalPurchase = {
  Wrapper: styled.div``,
  Container: styled.div`
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 20px 40px;

    &:first-of-type {
      padding: 0 20px 8px;
    }
  `,
  Subtitle: styled.span`
    width: 290px;
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PersonalSubscriptionsBlock: styled(SubscriptionsBlock)`
    h2 {
      font-weight: 700;
    }
  `,
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  ButtonContainer: styled.div`
    width: 100%;
  `,
}
