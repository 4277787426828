import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goBack } from 'browser-history'
import { OptionType, UserAge } from 'root-constants'

import { StyledAge as S } from './Age.styles'
import { AGE_OPTION_VALUES_MAP, QUESTION } from './constants'

export const Age: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[answer])
    handleContinue(answer)
  }, [answer, handleContinue])

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (!isTiktokCohort) {
        setAnswer(value)
        return
      }

      eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, isTiktokCohort, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>{t`onboarding.age.question`}</PageTitle>
        </S.TitleContainer>
        <S.DescriptionContainer>
          <PageTitleDescription>
            {t`onboarding.age.description`}
          </PageTitleDescription>
        </S.DescriptionContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.TWENTY}
          >
            <AnswerButton>18-29</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.THIRTY}
          >
            <AnswerButton>30-39</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.FOURTY}
          >
            <AnswerButton>40-49</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.FIFTY}
          >
            <AnswerButton>50-59</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.SIXTY}
          >
            <AnswerButton>60-69</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={UserAge.SEVENTY}
          >
            <AnswerButton>70+</AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {!isTiktokCohort && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
