import styled, { css } from 'styled-components'

import { Color, DynamicDiscountTheme } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountTheme
}

export const StyledTicker = {
  Root: styled.div<TTheme>`
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    color: ${Color.WHITE};
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.21px;
    text-transform: uppercase;
    position: relative;
    z-index: 4;

    & .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }
    & .swiper-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
    }

    & svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff5343 8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.YELLOW:
          return css`
            background: linear-gradient(91deg, #ffce3c -8.21%, #ff9000 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
        default:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
      }
    }}
  `,
  Sale: styled.p`
    display: flex;
    align-items: center;
    margin-right: 4px;
  `,
}
