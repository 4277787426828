import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button } from 'components/Button'
import { CustomerReviews } from 'components/CustomerReviews'
import { Spinner } from 'components/Spinner'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useLockScroll } from 'hooks/useLockScroll'

import { CardPaymentForm } from 'modules/purchase/components/CardPaymentForm'
import { ConfirmCheckoutModal } from 'modules/purchase/components/ConfirmCheckoutModal'
import { LuvlyBenefits } from 'modules/purchase/components/LuvlyBenefits'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PaymentRequestButton } from 'modules/purchase/components/PaymentRequestButton'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PurchaseSubtitle } from 'modules/purchase/components/PurchaseSubtitle'
import { PurchaseTitle } from 'modules/purchase/components/PurchaseTitle'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'
import { Y_OFFSET } from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { PageId } from 'page-constants'
import { ScreenName, SubscriptionListType } from 'root-constants'

import { PersonalizedPlan } from '../../components/PersonalizedPlan'
import {
  GlobalStyle,
  StyledPurchaseVariant1 as S,
} from './PurchaseVariant1.styles'

export const PurchaseVariant1: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const elemForComparisonRef = useRef<HTMLHeadingElement>(null)
  const paymentContainerRef = useRef<HTMLDivElement>(null)
  const { selectedSubscription, threeDSecureIframeUrl } = usePurchaseStore()
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics()

  const { arePricesReady } = usePricesStatus()

  useLayoutEffect(() => {
    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE))
  }, [dispatch])

  useDefaultSubscription()

  useEffect(() => {
    const { current: elem } = paymentContainerRef
    if (elem && isCheckoutShown) {
      const yCoordinate =
        elem.getBoundingClientRect().top + window.scrollY - Y_OFFSET
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }, [isCheckoutShown])

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
  }, [dispatch])

  const handleOpenConfirmModal = useCallback(() => {
    setIsConfirmModalShown(true)
  }, [])

  const handleCloseConfirmModal = useCallback(() => {
    setIsConfirmModalShown(false)
    setIsCheckoutShown(true)
    sendGeneralPurchaseEvents()
  }, [sendGeneralPurchaseEvents])

  useLockScroll(isConfirmModalShown || isPaymentWaitingShown)

  return (
    <div>
      {!arePricesReady ? (
        <Spinner />
      ) : (
        <S.Wrapper>
          {threeDSecureIframeUrl ? (
            <S.ThreeDSecureIframe
              title="3DSecure"
              src={threeDSecureIframeUrl}
            />
          ) : (
            <div>
              <PurchaseTitle />
              <PurchaseSubtitle />
              <PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
              <S.Subtitle ref={elemForComparisonRef}>
                {t('purchase1.goalSubtitle')}
              </S.Subtitle>
              <UserGoalGraph pageId={PageId.PURCHASE} />
              <LuvlyBenefits />
              <SubscriptionsBlock />
              <MoneyBackGuarantee />
              <CustomerReviews />
              {isCheckoutShown ? (
                <div ref={paymentContainerRef}>
                  <S.PaymentContainer data-is-visible={isCheckoutShown}>
                    <S.PaymentMethodTitle />
                    <PaymentRequestButton key={selectedSubscription?.id} />
                    <CardPaymentForm isCardPaymentShown />
                    <SecurityInfo />
                  </S.PaymentContainer>
                </div>
              ) : (
                <S.ButtonContainer>
                  <Button
                    data-testid="start-plan-btn"
                    onClick={handleOpenConfirmModal}
                  >
                    {t`actions.startMyPlan`}
                  </Button>
                </S.ButtonContainer>
              )}

              <GlobalStyle
                isModalShown={isConfirmModalShown || isPaymentWaitingShown}
              />
              <PaymentWaitingModal
                isPaymentWaitingShown={isPaymentWaitingShown}
                setIsPaymentWaitingShown={setIsPaymentWaitingShown}
                buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
              />
              <ConfirmCheckoutModal
                isShown={isConfirmModalShown}
                onClose={handleCloseConfirmModal}
              />
            </div>
          )}
        </S.Wrapper>
      )}
    </div>
  )
}
