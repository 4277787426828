import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { ProfilerSummaryCard } from 'modules/purchase/components/ProfileSummary/ProfilerSummaryCard'
import {
  SKIN_CONCERNS_LIST,
  SKIN_TYPE_LIST,
  SkinConcerns,
} from 'modules/purchase/pages/PersonalPurchase/constants'

import lotus from 'assets/images/lotus.png'

import { StyledProfileSummary as S } from './ProfileSummary.style'

export const ProfileSummary: FC = () => {
  const { t } = useTranslation()
  const userAnswer = useSelector(selectAnswers)

  const skinType = useMemo(
    () => SKIN_TYPE_LIST[userAnswer?.skinType] || SKIN_TYPE_LIST.normal,
    [userAnswer],
  )

  const skinConcernsImage = useMemo(
    () =>
      SKIN_CONCERNS_LIST[userAnswer?.skinConcerns?.[0]]?.image ||
      SKIN_CONCERNS_LIST[SkinConcerns.NOTHING_FROM_THE_ABOVE].image,
    [userAnswer],
  )

  const skinConcernsInfo = useMemo(
    () =>
      userAnswer?.skinConcerns?.map(
        (concern: string) => SKIN_CONCERNS_LIST[concern].info,
      ) || [SKIN_CONCERNS_LIST[SkinConcerns.NOTHING_FROM_THE_ABOVE].info],
    [userAnswer?.skinConcerns],
  )

  return (
    <S.Wrapper>
      <S.Title>{t`purchasePersonal.profilerSummary.title`}</S.Title>

      <S.CardsContainer>
        <ProfilerSummaryCard
          image={lotus}
          label={t('purchasePersonal.profilerSummary.age')}
        >
          {userAnswer?.age || '21'}
        </ProfilerSummaryCard>

        <ProfilerSummaryCard
          image={skinType.image}
          label={t('purchasePersonal.profilerSummary.skinType')}
        >
          {t(skinType.info)}
        </ProfilerSummaryCard>

        <ProfilerSummaryCard
          image={skinConcernsImage}
          label={t('purchasePersonal.profilerSummary.skinConcerns')}
        >
          {skinConcernsInfo.map((el) => t(el)).join(', ')}
        </ProfilerSummaryCard>
      </S.CardsContainer>
    </S.Wrapper>
  )
}
