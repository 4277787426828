import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseTitle = {
  Title: styled.h1`
    margin: 0 -10px 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
  `,
}
