import React, { FC, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useTimerForTarget } from 'modules/purchase/hooks'

import { StyledPersonalPurchaseTimer as S } from './PersonalPurchaseTimer.style'

type TProps = {
  className?: string
}

export const PersonalPurchaseTimer: FC<TProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.Wrapper className={className}>
      <S.Clock />
      <S.Text>{t`purchasePersonal.timer.timerTextBefore`}</S.Text>
      <S.Timer ref={timerElementRef} />
      <S.Text>{t`purchasePersonal.timer.timerTextAfter`}</S.Text>
    </S.Wrapper>
  )
}
