import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { UserGoalGraph } from 'components/UserGoalGraph'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  setSubscriptionListAction,
  startFetching,
} from 'root-redux/actions/common'
import { selectCurrentVariantParentCohort } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { FeaturedBlock } from 'modules/purchase/components/FeaturedBlock'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { NumberPurchasePlans } from 'modules/purchase/components/NumberPurchasePlans'
import { PersonalPurchaseBenefits } from 'modules/purchase/components/PersonalPurchaseBenefits'
import { PersonalPurchaseBonus } from 'modules/purchase/components/PersonalPurchaseBonus'
import { PersonalPurchaseCustomerReviews } from 'modules/purchase/components/PersonalPurchaseCustomerReviews'
import { PersonalPurchasePoster } from 'modules/purchase/components/PersonalPurchasePoster'
import { PersonalPurchaseReviews } from 'modules/purchase/components/PersonalPurchaseReviews'
import { PersonalPurchaseSubtitle } from 'modules/purchase/components/PersonalPurchaseSubtitle'
import { PersonalPurchaseTimer } from 'modules/purchase/components/PersonalPurchaseTimer'
import { PersonalPurchaseTitle } from 'modules/purchase/components/PersonalPurchaseTitle'
import { ProfileSummary } from 'modules/purchase/components/ProfileSummary'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import {
  CURRENCY_SYMBOLS,
  PURCHASE_DISCLAIMER,
  PURCHASE_DISCLAIMER_WITHOUT_TAXES,
} from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Cohort,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledPersonalPurchase as S } from './PersonalPurchase.style'
import { DEFAULT_REVIEWS } from './constants'

export const PersonalPurchase = () => {
  const dispatch: TAppDispatch = useDispatch()
  const hasVatInfo = useVatInfo()
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const { pathname, search } = useLocation()
  const { t } = useTranslation()
  const { arePricesReady } = usePricesStatus()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)
  const {
    cohort,
    periodName,
    periodQuantity,
    price,
    taxAmount,
    currency,
    hasCancelOffer,
    language,
  } = usePurchaseStore()
  const cohortToUse = parentCohort || cohort

  const localizationKeys = useMemo(() => {
    if (hasVatInfo) {
      return PURCHASE_DISCLAIMER_WITHOUT_TAXES
    }
    return PURCHASE_DISCLAIMER
  }, [hasVatInfo])

  const disclaimerTextPath = useMemo(() => {
    if (!periodQuantity) return ''

    return localizationKeys[periodQuantity]
  }, [localizationKeys, periodQuantity])

  const hasPaypalButton = useMemo(() => language === Locale.ENGLISH, [language])

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const screenName = useMemo(() => {
    const isEmailCohort = cohortToUse === Cohort.LUVLY_EMAIL_7
    return isEmailCohort ? ScreenName.EMAIL_OFFER : ScreenName.PURCHASE
  }, [cohortToUse])

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(screenName)

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(screenName))

    const vatTag = hasVatInfo
      ? SubscriptionTagsType.TAX
      : SubscriptionTagsType.NO_TAX

    dispatch(getSubscriptionListAction(SubscriptionListType.PURCHASE, vatTag))
  }, [dispatch, hasVatInfo, screenName])

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch, setIsCheckoutShown])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
    })

    if (hasCancelOffer) {
      dispatch(setSubscriptionListAction([]))
      goTo({ pathname: PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER, search })
      return
    }
    setIsCheckoutShown(false)
  }, [
    dispatch,
    setIsCheckoutShown,
    pathname,
    cohort,
    productId,
    screenName,
    search,
    hasCancelOffer,
  ])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.Container>
            <PersonalPurchaseTitle />
            <PersonalPurchaseSubtitle />
            <NumberPurchasePlans />
          </S.Container>

          <PersonalPurchaseTimer />

          <PersonalPurchasePoster />
          <S.Container>
            <PersonalPurchaseBenefits />
            <PersonalPurchaseBonus />

            <S.PersonalSubscriptionsBlock />

            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>

            <FeaturedBlock />
            <MoneyBackGuarantee />
            <ProfileSummary />
            <S.Subtitle>{t('purchase1.goalSubtitle')}</S.Subtitle>
            <UserGoalGraph pageId={PageId.PURCHASE} />
            <PersonalPurchaseReviews reviews={DEFAULT_REVIEWS} />
            <PersonalPurchaseCustomerReviews />

            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.ButtonContainer>

            <SecurityInfo />
            <S.Disclaimer>
              <Trans
                i18nKey={disclaimerTextPath}
                values={{
                  price: getCalculatedPrice(price),
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                }}
                components={[<br />]}
              />{' '}
              <TermsOfUseLink />.
            </S.Disclaimer>
          </S.Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
          hasPaypalButton={hasPaypalButton}
        />
      )}
    </>
  )
}
