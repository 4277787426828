import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { getPaymentConfigAction } from 'root-redux/actions/common'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { Cohort } from 'root-constants'

import { UpsellPaywallSwitchers } from '../UpsellPaywallSwitchers'
import { UpsellWithTimer } from '../UpsellWithTimer'

export const UpsellProvider: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)

  useEffect(() => {
    dispatch(getPaymentConfigAction())
  }, [dispatch])

  const isPaywallWithTimerActive = useFeatureIsOn('lv_177_upsell_test')

  return cohort === Cohort.LUVLY_INTRO_FAST && isPaywallWithTimerActive ? (
    <UpsellWithTimer />
  ) : (
    <UpsellPaywallSwitchers />
  )
}
