export const ONE_OPTION = 1

export const ANSWERS_MAP = {
  get_rid_of_wrinkles: 'presale.getRid',
  reduce_face_fat: 'presale.reduceFat',
  both_options: 'presale.complexGoal',

  normal: 'onboarding.skinType.normal',
  combination: 'onboarding.skinType.combination',
  dry: 'onboarding.skinType.dry',
  oily: 'onboarding.skinType.oily',
  dont_know: 'onboarding.skinType.dontKnow',

  no_specific_diet: 'onboarding.diet.noDiet.title',
  vegetarian: 'onboarding.diet.vegetarian.title',
  no_gluten_diet: 'onboarding.diet.noGlutenDiet.title',
  vegan: 'onboarding.diet.vegan.title',

  acne: 'presale.pimples',
  redness: 'onboarding.skinConcerns.redness',
  dullness: 'onboarding.skinConcerns.dullness',
  rough_texture: 'onboarding.skinConcerns.roughTexture',
  uneven_skin_tone: 'onboarding.skinConcerns.unevenSkinTone',
  pigmentation_changes: 'onboarding.skinConcerns.pigmentationChanges',
  nothing_from_the_above: 'presale.nothingFromTheAbove',

  forehead: 'onboarding.problematicAreas.forehead',
  temples: 'onboarding.problematicAreas.temples',
  eyes: 'onboarding.problematicAreas.eyes',
  cheeks: 'onboarding.problematicAreas.cheeks',
  jawline: 'onboarding.problematicAreas.jawline',
  mouth: 'onboarding.problematicAreas.mouth',
  chin: 'onboarding.problematicAreas.chin',
  neck: 'onboarding.problematicAreas.neck',
  neckline: 'onboarding.problematicAreas.neckline',
}
