import brianna from 'assets/images/brianna-before-after-min.jpg'
import jenifer from 'assets/images/dalia-before-after-min.png'
import linda from 'assets/images/linda-before-after-min.jpg'
import melissa from 'assets/images/melissa-before-after-min.png'

import { IReviews } from '../PersonalPurchase/constants'

export const REVIEWS: IReviews[] = [
  {
    name: 'purchasePersonal.reviews.melissa',
    image: melissa,
  },
  {
    name: 'purchasePersonal.reviews.brianna',
    image: brianna,
  },
  {
    name: 'purchasePersonal.reviews.linda',
    image: linda,
  },
  {
    name: 'purchasePersonal.reviews.jennifer',
    image: jenifer,
  },
]
