import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color, MediaBreakpoint } from 'root-constants'

type TProps = {
  isSelected: boolean
}

export const StyledChooseTrialPrice = {
  TitleContainer: styled.div`
    background-color: ${Color.SANDY};
    padding: 24px 20px 18px;
    margin: -16px auto 24px;
    max-width: 360px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
    }
  `,
  Title: styled.h2`
    text-align: center;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  Description: styled.div`
    p {
      margin-bottom: 24px;
    }
  `,
  OptionsContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  OptionTitle: styled.h2`
    margin-bottom: 28px;
  `,
  DescriptionContainer: styled.div<TProps>`
    display: flex;
    margin-bottom: ${({ isSelected }) => (isSelected ? '0px' : '40px')};
  `,
  Highlighted: styled.p`
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: ${Color.PRIMARY};
    margin-bottom: 22px;
  `,
  OptionDescription: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.LIGHT};
    max-width: 255px;
    margin-bottom: 8px;
  `,
  Image: styled.img``,
  OptionButton: styled.div<TProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 48px;
    background-color: ${Color.SANDY};
    color: ${Color.BLUE_DARK};
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    transition: background-color 0.25s;
    border-radius: 10px;

    ${({ isSelected }) =>
      isSelected &&
      `
      background: #ff8276;
      color: #fff;
      `}
  `,
  Info: styled.p`
    margin-top: 16px;
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: ${Color.LIGHT};
  `,
  Button: styled(Button)`
    margin-top: 21px;
  `,
}
