import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPersonalOfferTimer = {
  TimerContainer: styled.div`
    margin-top: 24px;
    width: 100%;
    height: 50px;
    background-color: ${Color.LIGHT_PINK};
    color: ${Color.WHITE};
    font-weight: bold;
    font-size: 18px;
    line-height: 46px;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 24px auto 0;
    }
  `,
  TimerText: styled.span`
    padding-right: 5px;

    & + strong {
      display: inline-block;
      min-width: 65px;
      text-align: left;
    }
  `,
}
