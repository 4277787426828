import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { PageId } from 'page-constants'
import { TIKTOK_FLOW_CONTEXT } from 'root-constants'

import { StyledPurchaseSubtitle as S } from './PurchaseSubtitle.styles'
import { USER_GOALS } from './constants'

export const PurchaseSubtitle: React.FC = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isTiktokCohort } = useActiveCohortIdentifier()

  const disclaimerText = useMemo(() => {
    const goal = userAnswers?.[PageId.INTRO]
    return t(USER_GOALS[goal], {
      context: isTiktokCohort ? TIKTOK_FLOW_CONTEXT : '',
    })
  }, [isTiktokCohort, t, userAnswers])

  return <S.Subtitle>{disclaimerText}</S.Subtitle>
}
