import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import presalePlan from 'assets/images/presale-plan.png'

import { goTo } from 'browser-history'
import { PURCHASE_PAGES_MAP, PageId } from 'page-constants'
import { AGE_OPTIONS, Cohort } from 'root-constants'

import { StyledPresale as S } from './Presale.styles'
import { PresaleCustomerReviews } from './PresaleCustomerReviews'
import { ProgramBenefitsCarousel } from './ProgramBenefitsCarousel'
import { ANSWERS_MAP, ONE_OPTION } from './constants'

export const Presale: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const answers = useSelector(selectAnswers)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const age = useMemo(
    () => AGE_OPTIONS.find((option) => option.value === answers?.[PageId.AGE]),
    [answers],
  )

  useEffect(() => eventLogger.logPresaleScreenShown(), [])

  const getWrappedLongAnswer = useCallback(
    (answer: string[]): string => {
      if (!answer) return ''

      return answer.length > ONE_OPTION
        ? `${t(ANSWERS_MAP[answer[0]])} ${t('presale.andOther')}`
        : t(ANSWERS_MAP[answer[0]])
    },
    [t],
  )

  const [goal, skinType, diet, skinConcerns, problematicAreas, dailyWater] =
    useMemo(() => {
      const goalAnswer = ANSWERS_MAP[answers?.[PageId.INTRO]]
      const skinTypeAnswer = ANSWERS_MAP[answers?.[PageId.SKIN_TYPE]]
      const dietAnswer =
        ANSWERS_MAP[answers?.[PageId.DIET] as string] || t`presale.other`
      const problematicAreaAnswers = [
        ...new Set<string>(
          answers?.[PageId.PROBLEMATIC_AREA]?.map((answer) => answer.value),
        ),
      ]
      const dailyWaterText =
        answers?.[PageId.DAILY_WATER] &&
        +answers[PageId.DAILY_WATER] > ONE_OPTION
          ? t('presale.glasses')
          : t('presale.glass')

      return [
        t(goalAnswer),
        t(skinTypeAnswer),
        t(dietAnswer),
        getWrappedLongAnswer(answers?.[PageId.SKIN_CONCERNS]),
        getWrappedLongAnswer(problematicAreaAnswers),
        dailyWaterText,
      ]
    }, [answers, t, getWrappedLongAnswer])

  const handleContinue = useCallback(() => {
    goTo({
      pathname:
        PURCHASE_PAGES_MAP[(parentCohort as Cohort) || (cohort as Cohort)],
      search,
    })
  }, [search, cohort, parentCohort])

  const renderOption = (title, value) => (
    <S.Option>
      <S.OptionTitle>{title}</S.OptionTitle>
      <S.OptionValue>{value}</S.OptionValue>
    </S.Option>
  )

  return (
    <>
      <Container>
        <S.Header>{t('presale.header')}</S.Header>
        <S.Description>{t('presale.description')}</S.Description>
      </Container>
      <S.PlanCard>
        <img src={presalePlan} alt="plan-result" />
        <S.OptionsList>
          {renderOption(t('presale.goal'), goal)}
          {renderOption(t('presale.skinType'), skinType)}
          {renderOption(t('presale.age'), age?.label)}
          {renderOption(t('presale.targetAreas'), problematicAreas)}
          {renderOption(t('presale.skinConcerns'), skinConcerns)}
          {renderOption(t('presale.diet'), diet)}
          {renderOption(
            t('presale.waterIntake'),
            `${answers?.[PageId.DAILY_WATER]} ${dailyWater}`,
          )}
        </S.OptionsList>
      </S.PlanCard>
      <S.ProgramBenefitsTitle>{t('presale.inProgram')}</S.ProgramBenefitsTitle>
      <ProgramBenefitsCarousel />
      <PresaleCustomerReviews />
      <S.ButtonContainer>
        <Button onClick={handleContinue}>{t('actions.continue')}</Button>
      </S.ButtonContainer>
    </>
  )
}
