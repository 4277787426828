import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div`
    padding-bottom: 30px;
  `,
  Container: styled.div`
    padding: 16px 15px 0;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 14px;
  `,
  Title: styled.h2`
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
  `,
  Text: styled.p`
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.LIGHT};

    a {
      font-weight: 400;
      color: #0091ff;
      text-decoration: underline;

      &.not-decorated {
        text-decoration: none;
      }
    }
  `,
}
