import { AnyAction } from 'redux'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'
import { IUserStatus } from 'models/user.model'

import {
  SET_AUTH_TOKEN,
  SET_DELETED_SUBSCRIPTION_ID,
  SET_SECRET,
  SET_STATUS,
  SET_TEST_ENVIRONMENT_QUERY_PARAM,
  SET_USER_CONTACT_EMAIL,
  SET_USER_SUBSCRIPTION_INFO,
  SET_UUID,
} from '../actions/user'

export interface IUserState {
  authToken: string
  uuid: string
  secret: string
  status: IUserStatus | null
  userSubscriptionsInfo: IUserSubscriptionsInfo | null
  userContactEmail: string
  deletedSubscriptionId: string
  testEnvironmentQueryParam: string
}

const initState: IUserState = {
  authToken: '',
  uuid: '',
  secret: '',
  status: null,
  userSubscriptionsInfo: null,
  userContactEmail: '',
  deletedSubscriptionId: '',
  testEnvironmentQueryParam: '',
}

export function userReducer(
  state: IUserState = initState,
  { type, payload = null }: AnyAction,
): IUserState {
  switch (type) {
    case SET_UUID: {
      return {
        ...state,
        uuid: payload,
      }
    }
    case SET_SECRET: {
      return {
        ...state,
        secret: payload,
      }
    }
    case SET_STATUS: {
      return {
        ...state,
        status: payload,
      }
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: payload,
      }
    }
    case SET_USER_SUBSCRIPTION_INFO: {
      return {
        ...state,
        userSubscriptionsInfo: payload,
      }
    }
    case SET_USER_CONTACT_EMAIL: {
      return {
        ...state,
        userContactEmail: payload,
      }
    }
    case SET_DELETED_SUBSCRIPTION_ID: {
      return {
        ...state,
        deletedSubscriptionId: payload,
      }
    }
    case SET_TEST_ENVIRONMENT_QUERY_PARAM: {
      return {
        ...state,
        testEnvironmentQueryParam: payload,
      }
    }
    default:
      return state
  }
}
