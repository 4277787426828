import styled from 'styled-components'

export const StyledShortProgramDescription = {
  Wrapper: styled.div``,
  TitleContainer: styled.header`
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 24px;
  `,
}
