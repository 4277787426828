import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import { selectActionList, selectScreenName } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'

import { PayPalButton } from 'modules/purchase/components/PayPalButton'
import { PrimerPaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { Separator } from 'modules/purchase/components/Separator'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import securitySystems from 'assets/images/security-systems.png'

import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutPrimer as S } from './CheckoutPrimer.styles'

type TProps = {
  handleCloseCheckout: () => void
  hasTotalAmount?: boolean
  hasPaypalButton?: boolean
}

export const CheckoutPrimer: React.FC<TProps> = ({
  handleCloseCheckout,
  hasTotalAmount = true,
  hasPaypalButton = false,
}) => {
  const { t } = useTranslation()
  const screenName = useSelector(selectScreenName)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const fetchingActionsList = useSelector(selectActionList)
  const hasVatInfo = useVatInfo()

  const {
    periodName,
    periodQuantity,
    trialPeriodDays,
    trialPrice,
    trialPriceTax,
    price,
    currentPriceTax,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
  } = usePurchaseStore()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  return (
    <div>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
      ) : (
        <>
          <S.Wrapper isVisible={isCheckoutReady}>
            <S.Title>{t`purchase2.checkout.title`}</S.Title>
            <S.CloseButton
              data-testid="close-btn"
              onClick={handleCloseCheckout}
            />
            {(hasTotalAmount || hasVatInfo) && (
              <S.TotalAmount>
                <Trans
                  i18nKey="purchase1.subscription.totalIncludingTaxes"
                  components={[<strong />, <br />]}
                  values={{
                    price: trialPrice || price,
                    tax: trialPriceTax || currentPriceTax,
                    currency: CURRENCY_SYMBOLS[currency],
                    count: periodQuantity,
                    context: trialPeriodDays,
                  }}
                />
              </S.TotalAmount>
            )}

            <S.PaymentContainer>
              <PrimerPaymentForm />

              {hasPaypalButton && (
                <>
                  <Separator />
                  <PayPalButton />
                </>
              )}

              <S.Image src={securitySystems} alt="security-systems" />
              <S.Text>{t`purchase2.checkout.moneyBackGuarantee`}</S.Text>
            </S.PaymentContainer>
          </S.Wrapper>
          <PrimerPaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
          {!isCheckoutReady && <Spinner />}
        </>
      )}
    </div>
  )
}
