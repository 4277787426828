import styled from 'styled-components'

import productSelectedIcon from 'assets/images/primary-check.png'

import { Color } from 'root-constants'

type TProductOptionProps = {
  isSelected: boolean
}

type TProductNameProps = {
  image: string
}

export const StyledUpsellWithTimer = {
  Title: styled.h1`
    text-align: center;
    color: ${Color.DARK};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin: 16px auto;
  `,
  Description: styled.p`
    text-align: center;
    color: ${Color.LIGHT};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    padding: 6px 10px 10px;
    background: linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
    margin-bottom: 24px;
  `,
  TimerContainerText: styled.span`
    color: #fffefe;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;

    strong {
      font-size: 20px;
      font-weight: 700;
    }
  `,
  ProductItem: styled.div<TProductOptionProps>`
    position: relative;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid
      ${({ isSelected }) => (isSelected ? Color.PRIMARY : Color.GRAY_SECONDARY)};
    margin-bottom: 12px;
  `,
  Discount: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${Color.PRIMARY};
    padding: 2px 16px;
    height: 28px;
    border-radius: 0 10px 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  ProductDescription: styled.div<TProductOptionProps>`
    position: relative;
    width: 100%;

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1px solid #cacaca;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50% - 28px);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${productSelectedIcon});
          background-size: 20px 20px;
          opacity: 1;
          border: none;
        `}
    }
  `,
  ProductName: styled.p<TProductNameProps>`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.DARK};

    &::before {
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      background-size: 36px 36px;
      background-repeat: no-repeat;
      border-radius: 50%;
      margin-right: 8px;
      background-image: url(${({ image }) => image});
    }
  `,
  PlusImage: styled.img`
    width: 24px;
    height: 24px;
    margin-left: 6px;
  `,
  ProductPrices: styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  `,
  ProductOldPrice: styled.p`
    color: ${Color.LIGHT};
    position: relative;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      right: 0;
      border-top: 2px solid #ec4f40;

      -webkit-transform: rotate(-15deg);
      -moz-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }
  `,
  ProductCurrentPrice: styled.p`
    color: ${Color.RED};
    font-weight: 600;
    margin-left: 8px;
  `,
  LinkContainer: styled.div`
    text-align: center;
    margin: 16px auto 0;
    padding-bottom: 32px;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-decoration: underline;
    color: ${Color.LIGHT};
    cursor: pointer;
  `,
}
