import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import googlePayButtonLogo from 'assets/images/google-pay-button-logo.png'

import { BottomButtonContainer } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

type TPrimerInputProps = {
  isInvalid: boolean
}

type TVisibilityProps = {
  isVisible: boolean
}

type TButtonContainerProps = {
  hasShadow: boolean
}

type TDefaultVariantStylesProps = {
  isDefaultStyles: boolean
}

type TSubmitButtonProps = {
  isMaximized?: boolean
}

export const commonInputStyles = css<TDefaultVariantStylesProps>`
  color: ${Color.DARK};
  height: ${({ isDefaultStyles }) => (isDefaultStyles ? '60px' : '50px')};
  background-color: ${Color.WHITE};
  line-height: ${({ isDefaultStyles }) => (isDefaultStyles ? '60px' : '50px')};
  border-radius: 10px;
  border: 1px solid ${Color.LIGHT};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;
  width: 100%;
  outline: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  ::placeholder {
    color: ${Color.DARK};
  }
`

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
`

export const StyledPrimerPaymentForm = {
  Wrapper: styled.div<TDefaultVariantStylesProps>`
    ${({ isDefaultStyles }) =>
      !isDefaultStyles &&
      `
        position: relative;
        width: 300px;
        margin: 0 auto;
      `}
  `,
  Form: styled.form<TVisibilityProps>`
    ${({ isVisible }) =>
      isVisible
        ? `
        position: static;
      `
        : hiddenStyles}
  `,
  InputContainer: styled.div<TPrimerInputProps & TDefaultVariantStylesProps>`
    ${commonInputStyles};
    display: flex;
    flex-direction: column;
  `,
  Label: styled.p`
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
  `,
  CardHolderInputContainer: styled.div<TPrimerInputProps>`
    position: relative;
    ${({ isInvalid }) =>
      isInvalid &&
      ` 
        input {
          color: ${Color.ERROR};
          caret-color: ${Color.DARK};   
          border-color: ${Color.ERROR};
        }
       
    `}
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardHolderInput: styled.input`
    ${commonInputStyles};
    &::placeholder {
      color: ${Color.LIGHT};
      font-weight: 400;
    }
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardCvcElementIcon: styled.img<TDefaultVariantStylesProps>`
    position: absolute;
    top: ${({ isDefaultStyles }) => (isDefaultStyles ? '43px' : '38px')};
    right: 16px;
    max-width: 20px;
  `,
  SubmitButton: styled(Button)<TSubmitButtonProps>`
    max-width: 320px;
    margin-top: 15px;
    height: ${({ isMaximized }) => (isMaximized ? '56px' : '50px')};
  `,
  GooglePayButtonContainer: styled.div<TDefaultVariantStylesProps>`
    & div {
      height: 55px;
    }

    & button {
      padding: 15px 15% 15px !important;
      outline: none !important;
      box-shadow: none;
      border-radius: 12px;
      font-size: 16px;

      ${({ isDefaultStyles }) =>
        isDefaultStyles
          ? `
              min-width: 320px !important;
              background-color: ${Color.DARK} !important;
            `
          : `
              min-width: 300px !important;
              background-color: #000000 !important;
              background-image: url(${googlePayButtonLogo}) !important;
              background-size: 58px 22px;
            `}
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 0;
    }
  `,
  ApplePayButtonContainer: styled.div<TDefaultVariantStylesProps>`
    & button {
      height: 55px !important;
      margin-top: 24px;
      border-radius: 12px;
      font-size: 16px;

      ${({ isDefaultStyles }) =>
        isDefaultStyles
          ? `
              min-width: 320px !important;
            `
          : `
              min-width: 300px !important;
            `}

      @media (min-width: ${MediaBreakpoint.TABLET}px) {
        width: 310px !important;
        margin-top: 0;
      }
    }
  `,
  PaymentButtonsContainer: styled.div<TVisibilityProps>`
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: initial;
      display: flex;
    }

    ${({ isVisible }) =>
      isVisible
        ? `
            position: static;
          `
        : hiddenStyles}
  `,
  ButtonContainer: styled(BottomButtonContainer)<TButtonContainerProps>`
    bottom: -1px;
    padding: ${({ hasShadow }) => (hasShadow ? '40px 0 40px' : '0 0 32px')};
    background: ${({ hasShadow }) =>
      hasShadow
        ? `linear-gradient(
           180deg,
           rgba(255, 255, 255, 0.0001) 0.25%,
           #ffffff 26.14%
         )`
        : `unset`};
  `,
}
