import React, {
  FormEvent,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { ErrorNotification } from 'components/ErrorNotification'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useEmailInputField } from 'hooks/useEmailInputField'

import { eventLogger } from 'services/eventLogger.service'

import { Chat } from 'common-styles'
import {
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
} from 'root-constants'

import { StyledLikeChatEmail as S } from './LikeChatEmail.styles'

export const LikeChatEmail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const fetchingActionsList = useSelector(selectActionList)
  const uuid = useSelector(selectUUID)
  const error = useSelector(selectError)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const [isErrorModalShown, setIsErrorModalShown] = useState<boolean>(false)
  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState<boolean>(false)
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const [email, setEmail] = useEmailInputField()
  const deferredEmail = useDeferredValue(email.value)

  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const errorText = useMemo(
    () => (!email.isValid && !domainsList.length ? email.validationText : ''),
    [email.isValid, email.validationText, domainsList],
  )

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleErrorModal = useCallback(() => setIsErrorModalShown(true), [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!email.isValid) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: handleErrorModal,
          isConsentRequired: true,
        }),
      )

      eventLogger.logEmailPageCompleted({ email: email.value })
      window.fbq('track', 'Lead', {}, { eventID: uuid })
      window.ttq &&
        window.ttq.identify({ email: isPersonalDataAllowed ? email.value : '' })
      window.ttq && window.ttq.track('CompleteRegistration', { event_id: uuid })
      window.rdt &&
        window.rdt('track', 'Lead', {
          email: isPersonalDataAllowed ? email.value : '',
          externalId: uuid,
        })
      window.snaptr &&
        window.snaptr('track', 'SIGN_UP', {
          user_email: isPersonalDataAllowed ? email.value : '',
        })
      window.obApi && window.obApi('track', 'Lead')
      window._tfa &&
        window._tfa.push({
          notify: 'event',
          name: 'complete_registration',
        })
    },
    [
      dispatch,
      email.isValid,
      email.value,
      handleErrorModal,
      uuid,
      isPersonalDataAllowed,
    ],
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
      setAreEmailTipsVisible(true)
    },
    [setEmail],
  )

  const handleFocus = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: true,
    }))
  }, [setEmail])

  const handleBlur = useCallback(() => {
    setEmail((prevState) => ({
      ...prevState,
      isFocused: false,
    }))
  }, [setEmail])

  const handlePrefilledEmail = useCallback(
    ({ target }) => {
      setAreEmailTipsVisible(false)
      setEmail((prevState) => ({
        ...prevState,
        value: target.value,
      }))
    },
    [setEmail],
  )

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <S.CustomContainer fullHeight fields={0}>
      <Chat>
        <Container>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={DELAY_BEFORE_SHOW}
            hasJustNowLabel
          >
            <Trans
              i18nKey="onboarding.likeChatEmail.title"
              components={[<br />, <strong />]}
            />
          </ChatMessage>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={2000}
            onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
            hasHeader={false}
          >
            {t('onboarding.likeChatEmail.secure')}
          </ChatMessage>
        </Container>
      </Chat>
      <S.BottomContainer
        isShown={chatStep === ChatStep.USER_ANSWER}
        pinedToBottom
      >
        <S.ErrorContainer>
          <ErrorNotification errorText={errorText} />
        </S.ErrorContainer>
        {areEmailTipsVisible && (
          <S.Domains>
            {domainsList.map((item) => (
              <button
                type="button"
                key={item}
                value={item}
                onClick={handlePrefilledEmail}
              >
                {item}
              </button>
            ))}
          </S.Domains>
        )}

        <S.BottomContainerContent>
          <form onSubmit={handleSubmit}>
            <S.EmailInput
              value={email.value}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required
            />
            <S.InputLabel>
              {t(
                email.isFocused
                  ? 'onboarding.likeChatEmail.input.label'
                  : 'onboarding.likeChatEmail.input.placeholder',
              )}
            </S.InputLabel>
            <S.ActionButtonWrapper>
              <S.SendEmailButton
                isValid={!!email.value && email.isValid}
                disabled={!email.value || !isEmailValid}
                type="submit"
              />
            </S.ActionButtonWrapper>
          </form>
        </S.BottomContainerContent>
      </S.BottomContainer>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </S.CustomContainer>
  )
}
