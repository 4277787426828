import LogoBlack from 'assets/images/luvly-logo-black.png'
import LogoWhite from 'assets/images/luvly-logo-white.png'

export enum IntroHeaderColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export const LOGO_MAP: Record<IntroHeaderColor, string> = {
  [IntroHeaderColor.LIGHT]: LogoWhite,
  [IntroHeaderColor.DARK]: LogoBlack,
}
