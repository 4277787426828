import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { createProductId } from 'helpers/createProductId'

import { PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { setIsFirstPaymentRetryPassedAction } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { Locale, ScreenName } from 'root-constants'

import { Checkout } from '../Checkout'
import { CheckoutPrimer } from '../CheckoutPrimer'
import { CheckoutSeparateMethods } from '../CheckoutSeparateMethods'

type TCheckoutList = {
  setIsCheckoutShown: (isCheckoutShown: boolean) => void
  isCancelOffer: boolean
}

export const CheckoutList = ({
  setIsCheckoutShown,
  isCancelOffer,
}: TCheckoutList) => {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const {
    isPrimerActive,
    isPaypalButtonActive,
    isCancelOfferActive,
    isSeparatedCheckoutActive,
  } = useABTest()

  const {
    isLuvlyIntroFastCohort,
    isLuvly14Cohort,
    isStartPageVideoCohort,
    isChatCohort,
    isIntroSaleCohort,
  } = useActiveCohortIdentifier()

  const {
    language,
    cohort,
    hasCancelOffer,
    periodName,
    periodQuantity,
    price,
  } = usePurchaseStore()

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const hasPaypalButton = useMemo(
    () => isEnLocale && isLuvlyIntroFastCohort && isPaypalButtonActive,
    [isLuvlyIntroFastCohort, isPaypalButtonActive, isEnLocale],
  )

  const isSeparatedCheckout = useMemo(() => {
    const isSupportedCohort =
      isLuvlyIntroFastCohort ||
      isLuvly14Cohort ||
      isStartPageVideoCohort ||
      isChatCohort ||
      isIntroSaleCohort

    return (
      (isSupportedCohort && isEnLocale) ||
      (isSupportedCohort && !isEnLocale && isSeparatedCheckoutActive)
    )
  }, [
    isChatCohort,
    isEnLocale,
    isIntroSaleCohort,
    isLuvly14Cohort,
    isLuvlyIntroFastCohort,
    isSeparatedCheckoutActive,
    isStartPageVideoCohort,
  ])

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: isCancelOffer
        ? ScreenName.CANCEL_OFFER_LONG
        : ScreenName.PURCHASE,
    })

    if (isPrimerActive) {
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }

    if (
      hasCancelOffer &&
      (isCancelOfferActive || isChatCohort) &&
      !isCancelOffer
    ) {
      dispatch(setSubscriptionListAction([]))
      goTo({ pathname: PageId.PURCHASE_INTRO_CANCEL_OFFER, search })
      return
    }
    setIsCheckoutShown(false)
  }, [
    dispatch,
    pathname,
    cohort,
    productId,
    isCancelOffer,
    isPrimerActive,
    hasCancelOffer,
    isCancelOfferActive,
    isChatCohort,
    setIsCheckoutShown,
    search,
  ])

  return (
    <>
      {!isPrimerActive && !isSeparatedCheckout && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
          hasPaypalButton={hasPaypalButton}
        />
      )}
      {isPrimerActive && !isSeparatedCheckout && (
        <CheckoutPrimer
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
          hasPaypalButton={hasPaypalButton}
        />
      )}

      {isSeparatedCheckout && (
        <CheckoutSeparateMethods
          handleCloseCheckout={handleCloseCheckout}
          hasPaypalButton={hasPaypalButton}
          paymentSystem={
            isPrimerActive ? PaymentSystem.PRIMER : PaymentSystem.STRIPE
          }
        />
      )}
    </>
  )
}
