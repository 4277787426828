import styled from 'styled-components'

import { PageTitle } from 'components/PageTitle'

import { Color } from 'root-constants'

export const StyledUsersStatement = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 15px auto 0;
  `,
  PageTitle: styled(PageTitle)`
    line-height: 28px;
  `,
  Subtitle: styled.p`
    font-size: 15px;
    line-height: 20px;
    color: ${Color.LIGHT};
    text-align: center;
    margin: 15px auto 89px;
  `,
  ContentContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
  `,
  CarouselWrapper: styled.div`
    aspect-ratio: 216/80;
  `,
  Claim: styled.p`
    font-size: 15px;
    line-height: 22px;
    color: ${Color.DARK};
    margin: 40px 30px;
    text-align: center;

    & > strong:first-of-type {
      color: ${Color.PRIMARY};
      font-size: 34px;
      font-weight: 700;
      line-height: 60px;
    }

    & > strong:last-of-type {
      color: ${Color.PRIMARY};
      font-size: 24px;
    }
  `,
  ImageWrapper: styled.div`
    max-width: 216px;
    margin: 0 auto;
  `,
  UserImage: styled.img``,
}
