import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { createProductId } from 'helpers/createProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

export const usePrimerAnalytics = (): {
  logPaymentStarted: (paymentMethod: PaymentMethod) => void
  logPaymentFailed: ({
    error,
    paymentMethod,
  }: {
    error: any
    paymentMethod: PaymentMethod
  }) => void
} => {
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPeriodPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const currency = useSelector(selectCurrency)
  const screenName = useSelector(selectScreenName)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: currentPrice,
      }),
    [periodName, periodQuantity, currentPrice],
  )

  const logPaymentStarted = useCallback(
    (paymentMethod) => {
      eventLogger.logPurchaseStarted({
        // email,
        screenName,
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        paymentMethod,
        paymentSystem: PaymentSystem.PRIMER,
        stripeAccountName,
        stripeAccountId,
      })

      window.obApi && window.obApi('track', 'Checkout')
      window._tfa &&
        window._tfa.push({ notify: 'event', name: 'start_checkout' })
    },
    [
      currency,
      currentPrice,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
      trialPeriodPrice,
    ],
  )

  const logPaymentFailed = useCallback(
    ({ error, paymentMethod }) => {
      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        error: {
          type: error.type,
          code: error.code,
          description: error.message,
        },
        paymentMethod,
        screenName,
        paymentSystem: PaymentSystem.PRIMER,
        stripeAccountId,
        stripeAccountName,
      })
    },
    [
      currency,
      currentPrice,
      productId,
      screenName,
      stripeAccountName,
      stripeAccountId,
      trialPeriodPrice,
    ],
  )

  return {
    logPaymentStarted,
    logPaymentFailed,
  }
}
