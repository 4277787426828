import React, { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { BENEFIT_LIST } from 'modules/purchase/pages/PersonalPurchase/constants'

import { StyledPersonalPurBenefits as S } from './PersonalPurchaseBenefits.style'

type TProp = {
  className?: string
}

export const PersonalPurchaseBenefits: FC<TProp> = ({ className = '' }) => {
  const { t } = useTranslation()
  const userAnswer = useSelector(selectAnswers)

  const userGoal = useMemo(
    () => BENEFIT_LIST.userGoal[userAnswer?.intro] || 'both',
    [userAnswer],
  )

  const skinConcerns = useMemo(
    () =>
      BENEFIT_LIST.skinConcerns[userAnswer?.skinConcerns?.[0]] ||
      BENEFIT_LIST.skinConcerns.nothing_from_the_above,
    [userAnswer],
  )

  const skinType = useMemo(
    () =>
      BENEFIT_LIST.skinType[userAnswer?.skinType] ||
      BENEFIT_LIST.skinType.normal,
    [userAnswer],
  )

  const yogaExercises = useMemo(
    () => BENEFIT_LIST.userGoal[userAnswer?.intro] || 'both',
    [userAnswer],
  )

  return (
    <S.Wrapper className={className}>
      <S.Title>{t`purchasePersonal.benefitsList.title`}</S.Title>
      <S.List>
        <S.ListItem>
          <Trans
            i18nKey="purchasePersonal.benefitsList.firstItem"
            context={userGoal}
            components={[<strong />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans i18nKey={skinConcerns} components={[<strong />]} />
        </S.ListItem>
        <S.ListItem>
          <Trans i18nKey={skinType} components={[<strong />]} />
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey="purchasePersonal.benefitsList.fourthItem"
            values={{
              context: yogaExercises,
            }}
            components={[<strong />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey="purchasePersonal.benefitsList.fifthItem"
            components={[<strong />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey="purchasePersonal.benefitsList.sixthItem"
            components={[<strong />]}
          />
        </S.ListItem>
      </S.List>
    </S.Wrapper>
  )
}
