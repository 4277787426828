import styled, { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants'

interface IPageWrapperProps {
  isModalShown: boolean
}

export const GlobalStyle = createGlobalStyle<IPageWrapperProps>`
   body {
     overflow: ${({ isModalShown }) => (isModalShown ? 'hidden' : 'initial')}
   }
   html {
     overflow-x: ${({ isModalShown }) => (isModalShown ? 'initial' : 'hidden')}
   }
 `

export const StyledPurchaseVariant1 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 60px;
  `,
  Subtitle: styled.h2`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PaymentMethodTitle: styled.h2`
    margin: 30px 0 23px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin: 30px 0;
  `,
  PaymentContainer: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
