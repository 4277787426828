import blushEmoji from 'assets/images/blush-emoji.png'
import CustomerReview2 from 'assets/images/customer-photo-2.png'
import CustomerReview1 from 'assets/images/customer-photo-3.png'
import CustomerReview3 from 'assets/images/customer-photo-7.png'
import diamondIcon from 'assets/images/diamond.png'
import lotusIcon from 'assets/images/lotus.png'
import reduceWrinklesIcon from 'assets/images/reduce-wrinkles.png'

export const BENEFITS_LIST = [
  { id: '1', key: 'purchase3.reduceWrinkles', icon: reduceWrinklesIcon },
  { id: '2', key: 'purchase3.healthyHabits', icon: lotusIcon },
  { id: '3', key: 'purchase3.feelConfident', icon: diamondIcon },
]

export const CUSTOMER_REVIEWS = [
  {
    name: 'onboarding.shortProgramDescription.thirdUserName',
    icon: CustomerReview1,
    review: 'onboarding.shortProgramDescription.thirdUserReview',
  },
  {
    name: 'onboarding.shortProgramDescription.secondUserName',
    icon: CustomerReview2,
    review: 'onboarding.shortProgramDescription.secondUserReview',
  },

  {
    name: 'onboarding.shortProgramDescription.firstUserNameV3',
    icon: CustomerReview3,
    review: 'onboarding.shortProgramDescription.firstUserReview',
    emoji: [blushEmoji],
  },
]
