import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BottomButtonContainer } from 'common-styles'

import { StyledUsersStatement as S } from './UsersStatement.styles'
import { QUESTION, USER_AVATARS } from './constants'

export const UsersStatement: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <S.PageTitle>{t`onboarding.photoResultTiktok.question`}</S.PageTitle>
          <S.Subtitle>{t`onboarding.photoResultTiktok.subtitle`}</S.Subtitle>
        </S.TitleContainer>
        <S.ContentContainer>
          <S.CarouselWrapper>
            <Carousel
              slidesPerView={1}
              loop
              autoplay={{ delay: 2000, disableOnInteraction: false }}
              speed={1100}
            >
              {USER_AVATARS.map(({ id, image }) => (
                <SwiperSlide key={id}>
                  <S.ImageWrapper>
                    <S.UserImage src={image} alt="user-avatars" />
                  </S.ImageWrapper>
                </SwiperSlide>
              ))}
            </Carousel>
          </S.CarouselWrapper>
          <S.Claim>
            <Trans
              i18nKey={t`onboarding.photoResultTiktok.usersClaim`}
              components={[<strong />, <br />]}
            />
          </S.Claim>
        </S.ContentContainer>
      </Container>
      <BottomButtonContainer>
        <Button onClick={handleNextClick}>{t`actions.gotIt`}</Button>
      </BottomButtonContainer>
    </div>
  )
}
