import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserSCCID } from 'root-redux/actions/user'
import { selectLanguage, selectSnapPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'

import { Locale } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useSnapPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const snapPixelId = useSelector(selectSnapPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const shouldSNAPTRBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length && isEnglishLang) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAdvertizingAndMarketingCookie &&
        isEnglishLang)
    )
  }, [
    isEUUser,
    userCookieConsentAnswer,
    hasAdvertizingAndMarketingCookie,
    isEnglishLang,
  ])

  const isPersonalDataAllowed = useMemo(() => {
    return (
      (isEUUser && hasFunctionalCookie && isEnglishLang) ||
      (isEUUser && !isEnglishLang) ||
      !isEUUser
    )
  }, [isEUUser, isEnglishLang, hasFunctionalCookie])

  useLayoutEffect(() => {
    if (!snapPixelId || !uuid || !userCountryCode || shouldSNAPTRBePaused) {
      return
    }

    window.snaptr('init', snapPixelId, {
      user_email: isPersonalDataAllowed ? email : '',
    })
    window.snaptr('track', 'PAGE_VIEW', {
      user_email: isPersonalDataAllowed ? email : '',
    })

    const searchParams = new URLSearchParams(document.location.search)
    const sccid = searchParams.get('sccid')
    const scid = getCookie('_scid')

    if (sccid) {
      dispatch(sendUserSCCID(sccid, scid))
    }
  }, [
    snapPixelId,
    email,
    uuid,
    dispatch,
    userCountryCode,
    shouldSNAPTRBePaused,
    isPersonalDataAllowed,
  ])
}
