import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { ProgressRounded } from 'components/ProgressRounded'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import animationConfig from 'assets/animation/expertSearchAnimation.json'

import { StyledExpertLoader as S } from './ExpertLoader.styles'
import { QUESTION } from './constants'

export const ExpertLoader: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (animationRef.current) {
      const animation = lottie.loadAnimation({
        container: animationRef.current,
        animationData: animationConfig,
        loop: false,
      })
      animation.setSpeed(0.35)
    }
  }, [])

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const { counter } = useCounter(true, {
    duration: 6000,
    callback: handleNextClick,
  })

  return (
    <S.Root>
      <Container>
        <S.ProgressContainer>
          <ProgressRounded value={counter} />
        </S.ProgressContainer>
        <S.Title>{t('onboarding.expertLoader.title')}</S.Title>
      </Container>
      <S.Experts ref={animationRef} />
    </S.Root>
  )
}
