import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'

import { StyledConfirmCheckoutModal as S } from './ConfirmCheckoutModal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
}

export const ConfirmCheckoutModal: React.FC<TProps> = ({
  isShown,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        <S.Title>{t`purchase1.confirmCheckoutModal.beautyCommitment`}</S.Title>
        <S.Subtitle>{t`purchase1.confirmCheckoutModal.subtitle`}</S.Subtitle>
        <S.Text>{t`purchase1.confirmCheckoutModal.text`}</S.Text>
        <Button onClick={onClose}>
          {t`purchase1.confirmCheckoutModal.buttonText`}
        </Button>
      </S.Content>
    </S.Wrapper>
  )
}
