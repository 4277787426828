import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { Cohort, OptionType } from 'root-constants'

import { StyledGoal as S } from './Goal.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Goal: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { handleChange } = useDelayedContinue()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId:
      cohortToUse === Cohort.LUVLY_MARISSA ||
      cohortToUse === Cohort.LUVLY_OAKLEY1
        ? PageId.INTRO
        : pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
      },
    }),
    [pageId, handleChange, handleContinue],
  )

  return (
    <Container>
      <S.TitleContainer>
        <PageTitle>{t`onboarding.goal.question`}</PageTitle>
      </S.TitleContainer>
      <div>
        <Option {...optionProps} value={OPTION_VALUES.GET_RID}>
          <AnswerButton>{t`onboarding.goal.getRid`}</AnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.REDUCE_FAT}>
          <AnswerButton>{t`onboarding.goal.reduceFat`}</AnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.BOTH_OPTIONS}>
          <AnswerButton>{t`onboarding.goal.bothOptions`}</AnswerButton>
        </Option>
      </div>
    </Container>
  )
}
