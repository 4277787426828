import { configApi, subscriptionsApi, variantsApi } from 'api'
import { AnyAction } from 'redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getAppConfigFromConfigRaw } from 'helpers/getAppConfigFromConfigRaw'
import { getPaymentConfigFromConfigRaw } from 'helpers/getPaymentConfigFromConfigRaw'
import { getSubscriptionListFromRawSubscriptionList } from 'helpers/getSubscriptionListFromRawSubscriptionList'
import { getVariantFromRawVariant } from 'helpers/getVariantFromRawVariant'

import { AnimationState } from 'modules/purchase/constants'
import {
  setTaxAmountAction,
  setUpgradeSubscriptionAction,
} from 'modules/purchase/redux/actions/common'

import { TAnswer, TUtmTags } from 'models/common.model'
import { IAppConfig, IPaymentConfig } from 'models/config.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IVariant } from 'models/variant.model'

import { PageId } from 'page-constants'
import {
  CENTS_IN_DOLLAR,
  Cohort,
  ScreenName,
  SubscriptionListType,
} from 'root-constants'

const MODULE_NAME = 'COMMON'

// actions types
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_VARIANT = `${MODULE_NAME}/GET_VARIANT`
export const SET_VARIANT = `${MODULE_NAME}/SET_VARIANT`
export const GET_SUBSCRIPTION_LIST = `${MODULE_NAME}/GET_SUBSCRIPTION_LIST`
export const SET_SUBSCRIPTION_LIST = `${MODULE_NAME}/SET_SUBSCRIPTION_LIST`
export const GET_APP_CONFIG = `${MODULE_NAME}/GET_APP_CONFIG`
export const SET_APP_CONFIG = `${MODULE_NAME}/SET_APP_CONFIG`
export const GET_PAYMENT_CONFIG = `${MODULE_NAME}/GET_PAYMENT_CONFIG`
export const SET_PAYMENT_CONFIG = `${MODULE_NAME}/SET_PAYMENT_CONFIG`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const SET_ANSWERS_FROM_BACKEND = `${MODULE_NAME}/SET_ANSWERS_FROM_BACKEND`
export const SET_LANGUAGE = `${MODULE_NAME}/SET_LANGUAGE`
export const SET_SCREEN_NAME = `${MODULE_NAME}/SET_SCREEN_NAME`
export const SET_UTM_TAGS = `${MODULE_NAME}/SET_UTM_TAGS`
export const SET_IS_PAYMENT_STATUS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_STATUS_SHOWN`
export const SET_PAYMENT_ANIMATION_STATUS = `${MODULE_NAME}/SET_PAYMENT_ANIMATION_STATUS`
export const SET_IS_PRIMER_RETRY_PROCESSING = `${MODULE_NAME}/SET_IS_PRIMER_RETRY_PROCESSING`
export const SET_IS_POST_PAYMENT_PROGRESSBAR_STATIC = `${MODULE_NAME}/SET_IS_POST_PAYMENT_PROGRESSBAR_STATIC`
export const SET_IS_CONTEXT_SHOWN = `${MODULE_NAME}/SET_IS_CONTEXT_SHOWN`

// actions handlers
export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setVariantAction(payload: IVariant): IAction<IVariant> {
  return {
    type: SET_VARIANT,
    payload,
  }
}

export function setIsInContextShownAction(payload: boolean): IAction<boolean> {
  return {
    type: SET_IS_CONTEXT_SHOWN,
    payload,
  }
}

export function getVariantAction({
  cohort,
}: {
  cohort: string
}): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await variantsApi.getVariant({ cohort })

    if (response.success && response.data) {
      const variant = getVariantFromRawVariant(response.data.variant)

      dispatch(setVariantAction(variant))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setSubscriptionListAction(
  payload: ISubscription[],
): IAction<ISubscription[]> {
  return {
    type: SET_SUBSCRIPTION_LIST,
    payload,
  }
}

export function setUtmTags(payload: TUtmTags): IAction<TUtmTags> {
  return {
    type: SET_UTM_TAGS,
    payload,
  }
}

export function getSubscriptionListAction(
  subscriptionType: SubscriptionListType,
  tags?: string,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)

    dispatch(startFetching(GET_SUBSCRIPTION_LIST))

    const response = await subscriptionsApi.getSubscriptionList({
      uuid,
      cohort: cohort as Cohort,
      type: subscriptionType,
      tags,
    })

    if (response.success && response.data) {
      const subscriptionList = getSubscriptionListFromRawSubscriptionList(
        response.data.plans,
      )

      dispatch(setSubscriptionListAction(subscriptionList))

      const taxAmount = response.data.plans.tax * 100

      dispatch(setTaxAmountAction(taxAmount))
    } else {
      dispatch(setSubscriptionListAction([]))
    }

    dispatch(stopFetching(GET_SUBSCRIPTION_LIST))
  }
}

export function getUpgradeSubscriptionListAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort

    dispatch(startFetching(GET_SUBSCRIPTION_LIST))

    const response = await subscriptionsApi.getUpgradeSubscriptionList({
      cohort,
      uuid,
    })

    if (response.success && response.data) {
      const subscription = {
        currency: response.data.price.currency,
        fullPrice: response.data.price.full_price / CENTS_IN_DOLLAR,
        periodName: response.data.price.interval,
        periodQuantity: response.data.price.interval_count,
        lookupKey: response.data.price.lookup_key,
        amountToPay: response.data.price.amount_to_pay,
        isDefault: true,
      }

      dispatch(setUpgradeSubscriptionAction(subscription))
    }

    dispatch(stopFetching(GET_SUBSCRIPTION_LIST))
  }
}

export function setAppConfigAction(payload: IAppConfig): IAction<IAppConfig> {
  return {
    type: SET_APP_CONFIG,
    payload,
  }
}

export function getAppConfigAction(): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_APP_CONFIG))

    const response = await configApi.getAppConfig()

    if (response.success && response.data) {
      const config = getAppConfigFromConfigRaw(response.data.config)
      dispatch(setAppConfigAction(config))
    }

    dispatch(stopFetching(GET_APP_CONFIG))
  }
}

export function setPaymentConfigAction(
  payload: IPaymentConfig,
): IAction<IPaymentConfig> {
  return {
    type: SET_PAYMENT_CONFIG,
    payload,
  }
}

export function getPaymentConfigAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)
    const parentCohort = selectCurrentVariantParentCohort(state)

    dispatch(startFetching(GET_PAYMENT_CONFIG))

    const response = await configApi.getPaymentConfig({
      uuid,
      cohort: (parentCohort as Cohort) || (cohort as Cohort),
    })

    if (response.success && response.data) {
      const config = getPaymentConfigFromConfigRaw(response.data)
      dispatch(setPaymentConfigAction(config))
    }

    dispatch(stopFetching(GET_PAYMENT_CONFIG))
  }
}

export function setAnswersAction({
  answers,
  pageId,
}: {
  answers: TAnswer
  pageId: PageId | string
}): AnyAction {
  return {
    type: SET_ANSWERS,
    payload: { [pageId]: answers },
  }
}

export function setAnswersFromBackendAction(answers: {
  [key: string]: string | string[]
}): AnyAction {
  return {
    type: SET_ANSWERS_FROM_BACKEND,
    payload: answers,
  }
}

export function setLanguageAction(language: string): IAction<string> {
  return {
    type: SET_LANGUAGE,
    payload: language,
  }
}

export function setScreenNameAction(
  screenName: ScreenName,
): IAction<ScreenName> {
  return {
    type: SET_SCREEN_NAME,
    payload: screenName,
  }
}

export function setIsPaymentStatusShown(
  isPaymentStatusShown: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PAYMENT_STATUS_SHOWN,
    payload: isPaymentStatusShown,
  }
}

export function setPaymentAnimationStatus(
  status: AnimationState | boolean,
): IAction<AnimationState | boolean> {
  return {
    type: SET_PAYMENT_ANIMATION_STATUS,
    payload: status,
  }
}

export function setIsPrimerRetryProcessing(
  isPrimerRetryProcessing: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PRIMER_RETRY_PROCESSING,
    payload: isPrimerRetryProcessing,
  }
}

export function setIsPostPaymentProgressbarStatic(
  isPostPaymentProgressbarAbsoluted: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_POST_PAYMENT_PROGRESSBAR_STATIC,
    payload: isPostPaymentProgressbarAbsoluted,
  }
}
