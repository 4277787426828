import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectSubscriptionList } from 'root-redux/selects/common'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { getButtonTapEventData } from 'helpers/getGuashaButtonsEventData'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectSubscription,
  selectSubscriptionId,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { Cohort, InAppProduct, OptionType, ScreenName } from 'root-constants'

import { StyledUpsellGuashaSubscriptionBlock as S } from './UpsellGuashaSubscriptionBlock.styles'
import { IN_APP_PRODUCTS_DATA } from './constants'

type TUpsellGuashaSubscriptionBlockProps = {
  onAddToCartCallback: () => void
  isCancelOffer: boolean
  buttonsOrder: [number]
}

export const UpsellGuashaSubscriptionBlock: React.FC<
  TUpsellGuashaSubscriptionBlockProps
> = ({
  onAddToCartCallback,
  isCancelOffer,
  buttonsOrder,
}: TUpsellGuashaSubscriptionBlockProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currency = useSelector(selectCurrency)
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const selectedSubscription = useSelector(selectSubscription)
  const cohortToUse = useCohortToUse()

  const [addToCartOrder] = buttonsOrder

  const addToCart = useCallback(
    async (event) => {
      if (cohortToUse === Cohort.LUVLY_CHAT_GUASHA) {
        await dispatch(
          sendUserConfigAction({
            item_price: selectedSubscription?.trialPrices.fullPrice || 0,
            item_name: selectedSubscription?.product || InAppProduct.MIX,
          }),
        )
      }

      const { buttonText, buttonOrder } = getButtonTapEventData(event)
      eventLogger.logInAppButtonTap(
        buttonText,
        buttonOrder,
        isCancelOffer
          ? ScreenName.UPSELL_GUASHA_CANCEL
          : ScreenName.UPSELL_GUASHA,
      )
      onAddToCartCallback()
    },
    [
      isCancelOffer,
      onAddToCartCallback,
      selectedSubscription,
      dispatch,
      cohortToUse,
    ],
  )

  const handleChange = useCallback(
    (value: string) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )

      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Root>
      <Container fields={16}>
        <S.OptionsContainer>
          {subscriptions.map((subscription) => {
            return (
              <Option
                type={OptionType.RADIO}
                value={subscription.id}
                key={subscription.id}
                onChange={handleChange}
                name={subscription.type}
                withoutMargin
              >
                <S.ProductItem
                  isSelected={subscription.id === selectedSubscriptionId}
                  image={IN_APP_PRODUCTS_DATA[subscription.id].image}
                >
                  {IN_APP_PRODUCTS_DATA[subscription.id].hasLabel && (
                    <S.Bestseller>
                      {t('guashaUpsell.subscription.bestsellerLabel')}
                    </S.Bestseller>
                  )}
                  <S.ProductItemContent>
                    <S.ProductName>
                      {t(IN_APP_PRODUCTS_DATA[subscription.id].name)}
                    </S.ProductName>
                    <S.ProductPrices>
                      <S.ProductOldPrice>
                        {CURRENCY_SYMBOLS[currency]}
                        {Math.ceil(
                          subscription.trialPrices.oldPrices.fullPrice,
                        )}
                      </S.ProductOldPrice>
                      <S.ProductCurrentPrice>
                        {CURRENCY_SYMBOLS[currency]}
                        {subscription.trialPrices.fullPrice}
                      </S.ProductCurrentPrice>
                      <S.Discount>
                        <Trans
                          i18nKey="upsellWithTimer.off"
                          values={{
                            value:
                              IN_APP_PRODUCTS_DATA[subscription.id][
                                isCancelOffer
                                  ? 'cancelOfferDiscount'
                                  : 'defaultDiscount'
                              ],
                          }}
                        />
                      </S.Discount>
                    </S.ProductPrices>
                  </S.ProductItemContent>
                </S.ProductItem>
              </Option>
            )
          })}
        </S.OptionsContainer>
        <Button
          onClick={addToCart}
          marginBottom={24}
          data-order={addToCartOrder}
        >
          {t('guashaUpsell.subscription.withGuashaButton')}
        </Button>
      </Container>
    </S.Root>
  )
}
