import React from 'react'
import { Trans } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { ProgramBenefitsCarousel as S } from './ProgramBenefitsCarousel.styles'
import { PLAN_BENEFITS } from './constants'

export const ProgramBenefitsCarousel: React.FC = () => {
  return (
    <S.CarouselContainer>
      <Carousel
        spaceBetween={4}
        slidesPerView={1}
        loop
        pagination={{ clickable: true }}
        autoplay={{ delay: 3500, disableOnInteraction: false }}
      >
        {PLAN_BENEFITS.map(({ id, img, textPath }) => (
          <SwiperSlide key={id}>
            <S.CardContainer>
              <img src={img} alt="plan-background" />
              <S.Text>
                <Trans i18nKey={textPath} components={[<br />]} />
              </S.Text>
            </S.CardContainer>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.CarouselContainer>
  )
}
