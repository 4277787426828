import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledProfileSummary = {
  Wrapper: styled.div`
    display: flex;
    width: 360px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  CardsContainer: styled.div`
    display: flex;
    width: 320px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
  `,
  Card: styled.div`
    max-width: 320px;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: 16px;
    flex-shrink: 0;
    border-radius: 14px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 rgba(209, 214, 235, 0.5);
  `,
  Image: styled.img`
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-right: 12px;
  `,
  InfoContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  Label: styled.h3`
    color: ${Color.GRAY_SECONDARY};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  `,
  Info: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-wrap: normal;
  `,
}
