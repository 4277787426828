import { UserGoal } from 'root-constants'

export const PURCHASE_TITLE_MAP: Record<UserGoal, string> = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'purchaseCosmeticScanner.titleWrinkles',
  [UserGoal.REDUCE_FACE_FAT]: 'purchaseCosmeticScanner.titleFaceFat',
  [UserGoal.BOTH_OPTIONS]: 'purchaseCosmeticScanner.titleBothOptions',
}

export const PURCHASE_SUBTITLE_MAP: Record<UserGoal, string> = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'purchaseCosmeticScanner.subtitleWrinkles',
  [UserGoal.REDUCE_FACE_FAT]: 'purchaseCosmeticScanner.subtitleFaceFat',
  [UserGoal.BOTH_OPTIONS]: 'purchaseCosmeticScanner.subtitleBothOptions',
}
