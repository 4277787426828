import React, { FC } from 'react'

import { StyledProfileSummary as S } from 'modules/purchase/components/ProfileSummary/ProfileSummary.style'

type TProps = {
  image: string
  label?: string
  children?: string | string[]
}

export const ProfilerSummaryCard: FC<TProps> = ({ children, image, label }) => {
  return (
    <S.Card>
      <S.Image src={image} />

      <S.InfoContainer>
        <S.Label>{label}</S.Label>
        <S.Info>{children}</S.Info>
      </S.InfoContainer>
    </S.Card>
  )
}
