import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType, SCALE_OPTION_VALUES } from 'root-constants'

import { StyledStatementQuestion as S } from './StatementQuestion.styles'
import {
  STATEMENT_QUESTIONS_CONTEXT_MAP,
  STATEMENT_QUESTIONS_DEFAULT_TEXT,
  STATEMENT_QUESTIONS_MAP,
} from './constants'

export const StatementQuestion: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort, isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: STATEMENT_QUESTIONS_MAP[pageId],
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (includeNavigation) {
        setAnswer(value)
        return
      }

      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, includeNavigation, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  const statementQuestionText = useMemo(() => {
    return (
      STATEMENT_QUESTIONS_CONTEXT_MAP[cohortToUse] ||
      STATEMENT_QUESTIONS_DEFAULT_TEXT
    )
  }, [cohortToUse])

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <S.Title isLargeSize={isSeniorCohort || isTiktokCohort}>
            <Trans
              i18nKey={
                !isTiktokCohort
                  ? `commonComponents.statementTitle`
                  : `onboarding.${pageId}.statement`
              }
            />
          </S.Title>
          <S.Question isLargeSize={isSeniorCohort || isTiktokCohort}>
            {t(statementQuestionText[pageId])}
          </S.Question>
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={SCALE_OPTION_VALUES.ONE}
          >
            <S.AnswerButtonMini isLargeSize={isSeniorCohort || isTiktokCohort}>
              1
            </S.AnswerButtonMini>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={SCALE_OPTION_VALUES.TWO}
          >
            <S.AnswerButtonMini isLargeSize={isSeniorCohort || isTiktokCohort}>
              2
            </S.AnswerButtonMini>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={SCALE_OPTION_VALUES.THREE}
          >
            <S.AnswerButtonMini isLargeSize={isSeniorCohort || isTiktokCohort}>
              3
            </S.AnswerButtonMini>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={SCALE_OPTION_VALUES.FOUR}
          >
            <S.AnswerButtonMini isLargeSize={isSeniorCohort || isTiktokCohort}>
              4
            </S.AnswerButtonMini>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={SCALE_OPTION_VALUES.FIVE}
          >
            <S.AnswerButtonMini isLargeSize={isSeniorCohort || isTiktokCohort}>
              5
            </S.AnswerButtonMini>
          </Option>
        </S.OptionsContainer>
        <S.Scale isLargeSize={isSeniorCohort || isTiktokCohort}>
          <span>{t`commonComponents.lowestScaleValue`}</span>
          <span>{t`commonComponents.highestScaleValue`}</span>
        </S.Scale>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
