import { useFeatureIsOn } from '@growthbook/growthbook-react'

export const useABTest = () => {
  const isPrimerActive = useFeatureIsOn('lv_27_primer_integration')
  const areAgeHighPricesActive = useFeatureIsOn('lv_242_age_test')
  const isCancelOfferActive = useFeatureIsOn('lv_312_cancel_offer_test')
  const isPaypalButtonActive = useFeatureIsOn('lv_327_paypal_button_test')
  const isWeeklyProductCardsActive = useFeatureIsOn(
    'lv_362_new_product_cards_test',
  )
  const isSeparatedCheckoutActive = useFeatureIsOn('lv_735_new_checkout_test')

  return {
    isPrimerActive,
    areAgeHighPricesActive,
    isCancelOfferActive,
    isPaypalButtonActive,
    isWeeklyProductCardsActive,
    isSeparatedCheckoutActive,
  }
}
