import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { selectSubscriptionPeriodQuantity } from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import { UserGoal } from 'root-constants'

import { StyledPurchaseTitle as S } from './PurchaseTitle.styles'

export const PurchaseTitle: React.FC = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const titleText = useMemo(() => {
    const goal = userAnswers?.[PageId.INTRO]
    const titleParams = {
      count: periodQuantity,
    }

    return goal === UserGoal.BOTH_OPTIONS
      ? t('purchase1.titleSecond', titleParams)
      : t('purchase1.titleFirst', titleParams)
  }, [t, userAnswers, periodQuantity])

  return <S.Title>{titleText}</S.Title>
}
