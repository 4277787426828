/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import styled from 'styled-components'

import selectedIcon from 'assets/images/selected-option-icon.svg'

import { Color } from 'root-constants'

export const customSelectStyles = {
  menu: () => ({
    border: ' 1px solid #000000',
    borderRadius: '10px',
    padding: '7px 0',
    marginTop: '8px',
  }),
  control: (_, state) => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: state.selectProps.menuIsOpen
      ? '1px solid #000000'
      : '1px solid transparent',
    borderRadius: '10px',
    padding: '22px 16px 6px 20px',
    minHeight: '52px',
    boxShadow: !state.selectProps.menuIsOpen ? '0px 8px 20px #DFE3F3' : 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    overflow: 'visible',
    position: 'relative',
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: 15,
  }),
  multiValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    marginRight: 5,
  }),

  multiValueRemove: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    marginTop: '-16px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#17202A',
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
}

export const StyledSelect = {
  Root: styled.div`
    position: relative;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    padding: 0 51px 0 21px;
    min-height: 40px;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: ${Color.DARK};
    position: relative;
    border-bottom: 1px solid #dadadd;

    &:last-child {
      border-bottom: 0;
    }
  `,
  SelectedIcon: styled.span`
    width: 20px;
    height: 20px;
    background: url(${selectedIcon}) no-repeat center;
    background-size: contain;
    position: absolute;
    right: 21px;
    top: calc(50% - 10px);
  `,
  CircleMarkIcon: styled.span`
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border: 1px solid #555965;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
  `,
  MultiValue: styled.span`
    font-weight: 500;
    font-size: 15px;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  `,
  Placeholder: styled.span`
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: ${Color.DARK};
    position: absolute;
    top: 6px;
    left: 21px;
    pointer-events: none;
  `,
}
