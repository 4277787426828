import { useEffect, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectLanguage,
  selectOutbrainPixelId,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { COHORT_QUERY_PARAM, Locale } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useOutbrainPixelInitialization = () => {
  const { search } = document.location
  const uuid = useSelector(selectUUID)
  const outbrainPixelId = useSelector(selectOutbrainPixelId)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const shouldOutbrainPixelBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length && isEnglishLang) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAdvertizingAndMarketingCookie &&
        isEnglishLang)
    )
  }, [
    isEUUser,
    userCookieConsentAnswer?.length,
    isEnglishLang,
    hasAdvertizingAndMarketingCookie,
  ])

  useLayoutEffect(() => {
    if (
      !outbrainPixelId ||
      !uuid ||
      !userCountryCode ||
      shouldOutbrainPixelBePaused
    ) {
      return
    }

    const outbrainScript = document.createElement('script')

    outbrainScript.type = 'text/javascript'
    outbrainScript.setAttribute('data-obct', 'true')
    outbrainScript.text = `
      !function(_window, _document) {
        var OB_ADV_ID = '${outbrainPixelId}';
        if (_window.obApi) {
          var toArray = function(object) {
            return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
          };
          _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
          return;
        }

        var api = _window.obApi = function() {
          api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
        };
        api.version = '1.1';
        api.loaded = true;
        api.marketerId = OB_ADV_ID;
        api.queue = [];
        var tag = _document.createElement('script');
        tag.async = true;
        tag.src = '//amplify.outbrain.com/cp/obtp.js';
        tag.type = 'text/javascript';
        var script = _document.getElementsByTagName('script')[0];
        script.parentNode.insertBefore(tag, script);
      }(window, document);
    `

    document.body.appendChild(outbrainScript)
  }, [
    cohort,
    outbrainPixelId,
    shouldOutbrainPixelBePaused,
    userCountryCode,
    uuid,
  ])

  useEffect(() => {
    if (
      !outbrainPixelId ||
      !uuid ||
      !userCountryCode ||
      shouldOutbrainPixelBePaused
    ) {
      return
    }

    window.obApi && window.obApi('track', 'PAGE_VIEW')
  }, [
    cohort,
    outbrainPixelId,
    shouldOutbrainPixelBePaused,
    userCountryCode,
    uuid,
  ])
}
