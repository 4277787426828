import React from 'react'
import { useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import starSvg from 'assets/images/sprite/star.svg'

import { StyledPresaleCustomerReviews as S } from './PresaleCustomerReviews.styles'
import { CUSTOMER_REVIEWS } from './constants'

export const PresaleCustomerReviews: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <S.Title>{t`purchaseChatBot.customerReviews.title`}</S.Title>
        <S.RatingContainer>
          <SvgImage svg={starSvg} width={15} />
          <S.Rating>{t`purchaseChatBot.customerReviews.appStoreRating`}</S.Rating>
          <SvgImage svg={starSvg} width={15} />
          <S.Rating>{t`purchaseChatBot.customerReviews.googlePlayRating`}</S.Rating>
        </S.RatingContainer>
      </Container>
      <S.CarouselContainer>
        <Carousel
          spaceBetween={4}
          slidesPerView={1}
          loop
          pagination={{ clickable: true }}
          autoplay={{ delay: 3500, disableOnInteraction: false }}
        >
          {CUSTOMER_REVIEWS.map(({ id, authorPath, textPath }) => (
            <SwiperSlide key={id}>
              <S.CardContainer>
                <S.Card>
                  <S.RatingInfo>
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                  </S.RatingInfo>
                  <S.Text>{t(textPath)}</S.Text>
                  <S.Author>{t(authorPath)}</S.Author>
                </S.Card>
              </S.CardContainer>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </>
  )
}
