import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'
import { selectIsUpsellAvailable } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { createProductId } from 'helpers/createProductId'
import { getButtonTapEventData } from 'helpers/getGuashaButtonsEventData'
import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SkipWellnessBundleModal } from 'modules/purchase/components/SkipWellnessBundleModal'
import { TopRatedWellnessApps } from 'modules/purchase/components/TopRatedWellnessApps'
import { WellnessBundleAppGallery } from 'modules/purchase/components/WellnessBundleAppGallery'
import { WellnessBundleBeforeAfterGallery } from 'modules/purchase/components/WellnessBundleBeforeAfterGallery'
import { WellnessBundleBenefits } from 'modules/purchase/components/WellnessBundleBenefits'
import { WellnessBundlePurchaseBlock } from 'modules/purchase/components/WellnessBundlePurchaseBlock'
import { TEN_MINUTES } from 'modules/purchase/constants'
import {
  usePricesStatus,
  usePurchaseStore,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeWellnessBundleUpsellAction,
} from 'modules/purchase/redux/actions/upsell'

import { eventLogger } from 'services/eventLogger.service'

import glowUpImage from 'assets/images/glow-up-wellness-bundle.jpg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { useScrollEvent } from '../../hooks'
import { StyledUpsellWellnessBundle as S } from './UpsellWellnessBundle.styles'
import { TRACKED_BREAKPOINTS } from './constants'

export const UpsellWellnessBundle: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { fetchingActionsList, periodName, periodQuantity, price } =
    usePurchaseStore()
  const { arePricesReady } = usePricesStatus()
  const { pathname, search } = useLocation()
  const isUpsellAvailable = useSelector(selectIsUpsellAvailable)
  const subscriptions = useSelector(selectSubscriptionList)
  const timerElementRef = useRef<HTMLSpanElement | null>(null)

  const [isSkipModalShown, setIsSkipModalShown] = useState<boolean>(false)
  const [isPricesStartedFetching, setIsPricesStartedFetching] =
    useState<boolean>(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS)

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const isCancelOfferRoute = useMemo(() => {
    const currentPageId = getPageIdFromPathName(pathname)

    return currentPageId === PageId.WELLNESS_BUNDLE_CANCEL_PAYWALL
  }, [pathname])

  const screenName = useMemo(
    () =>
      isCancelOfferRoute
        ? ScreenName.LIVECHAT_WELLNESS_BUNDLE_CANCEL_OFFER
        : ScreenName.LIVECHAT_WELLNESS_BUNDLE,
    [isCancelOfferRoute],
  )

  const { scrollEvent } = useScrollEvent(ScreenName.LIVECHAT_WELLNESS_BUNDLE)

  useTrackPageScrollDepth({
    trackedBreakpoints: TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCancelOfferRoute || isSkipModalShown,
  })

  useLayoutEffect(() => {
    if (isUpsellAvailable) {
      const tags = isCancelOfferRoute
        ? `${SubscriptionTagsType.WELLNESS_BUNDLE_CANCEL},${SubscriptionTagsType.NO_TAX}`
        : `${SubscriptionTagsType.WELLNESS_BUNDLE},${SubscriptionTagsType.NO_TAX}`

      dispatch(getSubscriptionListAction(SubscriptionListType.UPSELL, tags))
      setIsPricesStartedFetching(true)
      return
    }

    goTo({ pathname: PageId.DOWNLOAD, search })
  }, [search, dispatch, isCancelOfferRoute, isUpsellAvailable])

  useLayoutEffect(() => {
    if (subscriptions && subscriptions.length) {
      dispatch(setSelectedSubscriptionAction(subscriptions[0]))
    }
  }, [dispatch, subscriptions])

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  useEffect(() => {
    if (!periodName || !periodQuantity || !price) {
      return
    }

    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logSalePageShown({
        productIds: [productId],
        screenName,
        isAmplitudeEvent: true,
      })
    }
  }, [
    subscriptions,
    isPricesStartedFetching,
    arePricesReady,
    screenName,
    productId,
    periodName,
    periodQuantity,
    price,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname: PageId.DOWNLOAD,
        search,
      })
    }
  }, [arePricesReady, isPricesStartedFetching, search, subscriptions])

  const openSkipModal = useCallback(() => {
    setIsSkipModalShown(true)
    eventLogger.logPlansPageClose(ScreenName.LIVECHAT_WELLNESS_BUNDLE)
    eventLogger.logWellnessBundleModalOpened()
  }, [])

  const closeSkipModal = useCallback(() => {
    setIsSkipModalShown(false)
    eventLogger.logWellnessBundleModalClosed()
    goTo({ pathname: PageId.WELLNESS_BUNDLE_CANCEL_PAYWALL, search })
  }, [search])

  const handleContinue = useCallback(() => {
    goTo({ pathname: PageId.DOWNLOAD, search })
  }, [search])

  const makeBundleUpsell = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isCancelOfferRoute) {
        const { buttonText, buttonOrder } = getButtonTapEventData(event)
        eventLogger.logPlanPageButtonClicked({
          buttonText,
          buttonNumber: buttonOrder,
          screenName,
        })
      }

      await dispatch(makeWellnessBundleUpsellAction(screenName))
    },
    [dispatch, isCancelOfferRoute, screenName],
  )

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      {isCancelOfferRoute && !isSkipModalShown && (
        <Container>
          <S.CancelOfferContainer>
            <S.CancelOfferImage />
            <S.CancelOfferText>
              <Trans
                i18nKey="wellnessBundleUpsell.cancelOffer"
                components={[<strong />, <br />]}
              />
              <S.TimerValue ref={timerElementRef}>
                {!timerElementRef?.current?.hasAttribute('data-value')
                  ? TEN_MINUTES.AS_STRING
                  : null}
              </S.TimerValue>
            </S.CancelOfferText>
          </S.CancelOfferContainer>
        </Container>
      )}
      <S.Hero>
        <img src={glowUpImage} alt="" />
        <S.HeroTitle>{t('wellnessBundleUpsell.title')}</S.HeroTitle>
      </S.Hero>
      <TopRatedWellnessApps />
      <WellnessBundlePurchaseBlock
        openSkipModal={openSkipModal}
        purchaseBundle={makeBundleUpsell}
        buttonOrder={1}
        position="top"
      />
      {!isCancelOfferRoute && (
        <>
          <WellnessBundleAppGallery />
          <Container fields={16}>
            <S.PurchaseButton data-order={2} onClick={makeBundleUpsell}>
              {t('wellnessBundleUpsell.purchase.addToPlan')}
            </S.PurchaseButton>
          </Container>
          <WellnessBundleBenefits />
          <WellnessBundleBeforeAfterGallery />
          <S.GlowUpContainer>
            <img src={glowUpImage} alt="" />
            <S.HeroTitle>{t('wellnessBundleUpsell.title')}</S.HeroTitle>
          </S.GlowUpContainer>
          <TopRatedWellnessApps />
          <WellnessBundlePurchaseBlock
            openSkipModal={openSkipModal}
            purchaseBundle={makeBundleUpsell}
            buttonOrder={3}
            position="bottom"
          />
          <Container fields={16}>
            <MoneyBackGuarantee />
            <SecurityInfo />
          </Container>
        </>
      )}
      {isSkipModalShown && (
        <SkipWellnessBundleModal closeModal={closeSkipModal} />
      )}
      {isUpsellInProgress && <Spinner />}
      <PaymentWaitingModal
        resetErrorCallback={handleContinue}
        successCallback={handleContinue}
        successSubtitle="wellnessBundleUpsell.paymentModalSuccessAction"
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        errorButtonText={t`wellnessBundleUpsell.paymentModalErrorAction`}
      />
    </S.Wrapper>
  )
}
