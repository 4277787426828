import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IReviews } from 'modules/purchase/pages/PersonalPurchase/constants'

import { StyledPersonalPurchaseReviews as S } from './PersonalPurchaseReviews.style'

type TProps = {
  className?: string
  reviews: IReviews[]
}

export const PersonalPurchaseReviews: FC<TProps> = ({ className, reviews }) => {
  const { t } = useTranslation()
  return (
    <S.Wrapper className={className}>
      <S.HeaderContainer>
        <S.Title>{t`purchasePersonal.reviewsTitle`}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey="purchasePersonal.reviewsSubtitle"
            components={[<strong />]}
          />
        </S.Subtitle>
      </S.HeaderContainer>

      <S.HolidaysCancelOfferReviews hasTitle={false} reviews={reviews} />
    </S.Wrapper>
  )
}
