import styled from 'styled-components'

type TLinkProps = {
  marginBottom: number
}

export const StyledLogin = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  `,
  Title: styled.h1`
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  EmailLoginContainer: styled.div``,
  DisclaimerContainer: styled.div`
    position: relative;
    margin-top: 66px;
  `,
  SignUpLink: styled.p<TLinkProps>`
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    pointer-events: none;

    strong {
      font-weight: 600;
      text-decoration: underline;
      pointer-events: auto;
      cursor: pointer;
    }
  `,
  ButtonsContainer: styled.div`
    button:last-child {
      margin-bottom: 24px;
    }

    button:not(:last-child) {
      margin-bottom: 8px;
    }
  `,
}
