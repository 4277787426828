import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useCreatePaypalSubscription } from 'hooks/useCreatePaypalSubscription'

import { createProductId } from 'helpers/createProductId'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { logStartedPayPalPayment } from 'modules/purchase/helpers/logStartedPayPalPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscriptionFullPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { handleGoaffproRefPurchase } from '../helpers/rootHelpers'

type TValues = {
  isButtonTouched: boolean
  handlePaymentApprove: (data) => Promise<void>
  handlePaymentError: (error) => void
  handlePaymentCancel: () => void
  handleButtonClick: () => void
}

export const usePayPalButton = (): TValues => {
  const [isButtonTouched, setIsButtonTouched] = useState(false)

  const dispatch = useDispatch()

  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const currentUUID = useSelector(selectUUID)
  const currentPeriodName = useSelector(selectSubscriptionPeriodName)
  const currentPeriodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const currency = useSelector(selectCurrency)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const screenName = useSelector(selectScreenName)
  const email = useSelector(selectUserOnboardingEmail)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const productId = createProductId({
    periodName: currentPeriodName,
    periodQuantity: currentPeriodQuantity,
    price: trialPrice || currentPrice,
  })

  const { handleError, createSubscription, handleSuccess } =
    useCreatePaypalSubscription()

  const handlePaymentApprove = useCallback(
    async (data) => {
      const response = await createSubscription(paypalPlanId)

      if (response.status) {
        handleSuccess({
          productId: paypalPlanId,
          price: currentPrice,
          trialPrice,
          trialPeriodDays,
          subscriptionId: data.subscriptionID,
          uuid: currentUUID,
          periodName: currentPeriodName,
          periodQuantity: currentPeriodQuantity,
          paymentMethod: PaymentMethod.PAYPAL,
          isTrialActive: !!trialPeriodDays,
          currency,
          screenName,
          email: isPersonalDataAllowed ? email : '',
          stripeAccountId,
          stripeAccountName,
          paymentSystem: PaymentSystem.PAYPAL,
        })

        handleGoaffproRefPurchase({
          subscriptionId: data.subscriptionID,
          purchaseAmount: trialPrice || currentPrice,
          currency,
        })
      }
    },
    [
      createSubscription,
      paypalPlanId,
      handleSuccess,
      currentPrice,
      trialPrice,
      trialPeriodDays,
      currentUUID,
      currentPeriodName,
      currentPeriodQuantity,
      currency,
      screenName,
      email,
      stripeAccountId,
      stripeAccountName,
      isPersonalDataAllowed,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsButtonTouched(false)
      handleError({
        productId,
        price: currentPrice,
        error,
        paymentMethod: PaymentMethod.PAYPAL,
        currency,
        screenName,
        isTrialActive: !!trialPeriodDays,
      })
    },

    [
      handleError,
      currency,
      currentPrice,
      productId,
      screenName,
      trialPeriodDays,
    ],
  )

  const handlePaymentCancel = useCallback(() => {
    setIsButtonTouched(false)
    eventLogger.logPayPalPaymentPopupClose()
  }, [])

  const handleButtonClick = useCallback(() => {
    setIsButtonTouched(true)

    eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
    logStartedPayPalPayment({
      productId,
      price: currentPrice,
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      currency,
      screenName,
      isTrialActive: !!trialPeriodDays,
      stripeAccountName,
      stripeAccountId,
    })

    dispatch(
      sendUserConfigAction({
        payment_currency: currency,
        payment_method: PaymentMethod.PAYPAL,
        subscription_price: `${currentPrice}`,
        period_name: currentPeriodName || '',
        period_quantity: currentPeriodQuantity,
        price_id: paypalPlanId,
        trial_price: `${trialPrice}`,
        trial_period: `${trialPeriodDays}`,
        screen_name: screenName,
      }),
    )
  }, [
    paypalPlanId,
    currentPrice,
    trialPrice,
    trialPeriodDays,
    currentPeriodName,
    currentPeriodQuantity,
    screenName,
    dispatch,
    productId,
    currency,
    stripeAccountName,
    stripeAccountId,
  ])

  return {
    isButtonTouched,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  }
}
