import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { Gender, OptionType, TIKTOK_FLOW_CONTEXT } from 'root-constants'

import { StyledMenstrualCycle as S } from './MenstrualCycle.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const MenstrualCycle: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isTiktokCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (!isTiktokCohort) {
        setAnswer(value)
        return
      }

      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, isTiktokCohort, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  useLayoutEffect(() => {
    const userGender = userAnswers?.[PageId.GENDER]
    if (!userGender || userGender !== Gender.FEMALE) {
      replaceHistory(nextPagePath)
    }
  }, [userAnswers, nextPagePath])

  return (
    <div>
      <Container>
        <S.TitleContainer>
          {t`onboarding.menstrualCycle.question`}
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.REGULAR}
          >
            <AnswerButton>{t`onboarding.menstrualCycle.regular`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.IRREGULAR}
          >
            <AnswerButton>{t`onboarding.menstrualCycle.irregular`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.DONT_HAVE_DUE_TO_AGE}
          >
            <AnswerButton
              {...(isTiktokCohort && { height: AnswerButtonHeight.L })}
            >
              <Trans
                i18nKey="onboarding.menstrualCycle.dontHaveDueToAge"
                components={[<br />]}
                context={isTiktokCohort ? TIKTOK_FLOW_CONTEXT : ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.DONT_KNOW}
          >
            <AnswerButton>
              <Trans
                i18nKey="onboarding.menstrualCycle.dontKnow"
                context={isTiktokCohort ? TIKTOK_FLOW_CONTEXT : ''}
              />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {!isTiktokCohort && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
