import styled from 'styled-components'

import { customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

export const StyledResult = {
  Root: styled.div`
    padding-top: 11px;
    position: relative;
    padding-bottom: 39px;

    ${customBackgroundStyles}
  `,
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
  `,
  Title: styled.header`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 32px;
    text-align: center;
  `,
  Summery: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px #b5c3fa;
    padding: 24px 16px;
    position: relative;
    margin-bottom: 24px;
  `,
  List: styled.ul``,
  Item: styled.li`
    margin-bottom: 16px;
    padding-left: 40px;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  ItemName: styled.h3`
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY};
  `,
  Icon: styled.div`
    display: grid;
    place-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${Color.CORAL_GRAY};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    & img {
      max-width: 20px;
    }
  `,
  UserAnswer: styled.span`
    display: inline-block;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${Color.DARK};
    max-width: 142px;
  `,
  Image: styled.img`
    width: auto;
    height: 220px;
    position: absolute;
    bottom: 0;
    right: 0;
  `,
  AnimationContainer: styled.div`
    width: 100%;
    max-width: 280px;
    height: 148px;
  `,
  Graph: styled.div`
    background-color: ${Color.WHITE};
    padding: 24px 16px 16px 32px;
    box-shadow: 0px 3.836px 14px 0px rgba(68, 85, 127, 0.19);
    border-radius: 16px;
    margin: 0 auto 22px auto;
    position: relative;
  `,
  Intervals: styled.div`
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #918c8c;
  `,
  GraphGoal: styled.span`
    color: #918c8c;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    left: 8px;
    position: absolute;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: center;
    width: auto;
    top: 50%;
  `,
}
