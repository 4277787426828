import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPresaleCustomerReviews = {
  Title: styled.h2`
    padding-bottom: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  RatingContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 28px;
  `,
  Rating: styled.span`
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${Color.LIGHT};

    &:first-of-type {
      padding-right: 38px;
    }
  `,
  CarouselContainer: styled.div`
    max-width: 360px;
    margin: 0 auto 40px;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
    }
  `,
  CardContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 18px 32px;
  `,
  Card: styled.div`
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 16px;
    padding: 16px 8px;
    background-color: ${Color.WHITE};
  `,
  Author: styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  `,
  RatingInfo: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;

    svg {
      width: 15px;
      height: 15px;
      margin: 0 4px;
    }
  `,
  Text: styled.p`
    min-height: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 8px;
  `,
}
