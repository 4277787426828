import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledRatingInfo = {
  Wrapper: styled.div`
    margin-bottom: 30px;
    padding: 16px 7px 24px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 14px;
  `,
  AppMarketContainer: styled.div`
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-bottom: 20px;
  `,
  MarketItemContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Image: styled.img`
    max-width: 50px;
  `,
  Label: styled.p`
    padding-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  `,
  RatingContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 16px;
  `,
  Rating: styled.div`
    svg {
      margin: 0 1px;
      width: 20px;
      height: 18px;
    }
  `,
  RatingDescription: styled.span`
    padding: 4px 8px;
    background-color: rgba(155, 228, 46, 0.2);
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #c4c4c4;

    strong {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #8fdf2c;
    }
  `,
  DownloadsDescriptionText: styled.p`
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: ${Color.LIGHT};
    text-align: center;

    strong {
      color: ${Color.DARK};
    }
  `,
  Disclaimer: styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
}
