import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  selectUserPaymentSystem,
  selectUserStatus,
} from 'root-redux/selects/user'

import { PaymentSystem } from 'modules/purchase/constants'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

import { UpsellGuasha } from '../UpsellGuasha'

export const LivechatUpsellGuasha = () => {
  const { search } = useLocation()
  const userStatus = useSelector(selectUserStatus)
  const paymentSystem = useSelector(selectUserPaymentSystem)

  useLayoutEffect(() => {
    const isLivechatUpsellGuashaAvailable =
      userStatus &&
      userStatus.hasSubscription &&
      !userStatus.inapp.isInAppPurchased &&
      paymentSystem === PaymentSystem.PRIMER

    if (!isLivechatUpsellGuashaAvailable) {
      goTo({ pathname: PageId.FINISHING_TOUCHES, search })
    }
  }, [paymentSystem, search, userStatus])

  return <UpsellGuasha />
}
