import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPersonalPurchaseTitle = {
  Title: styled.h1`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
  `,
}
