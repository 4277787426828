import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import lottie from 'lottie-web/build/player/lottie_light'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectIsFetching,
  selectUserGoal,
} from 'root-redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import smileIcon from 'assets/images/dark-smile.svg'
import starsIcon from 'assets/images/dark-stars.svg'
import dropsIcon from 'assets/images/dark-waterdrops.svg'

import { goTo } from 'browser-history'
import { StickyButtonContainer } from 'common-styles'
import { PURCHASE_PAGES_MAP, PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  TARGET_AREA_OPTIONS,
  UserGoal,
} from 'root-constants'

import { StyledResult as S } from './Result.styles'
import { GRAPH_PATH, QUESTION, WOMEN_IMAGE } from './constants'

export const Result: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const userGoal = useSelector(selectUserGoal)
  const userAnswers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement>(null)
  const cohort = useSelector(selectCurrentVariantCohort)
  const isFetching = useSelector(selectIsFetching)

  const problematicAreas = useMemo(
    () => userAnswers?.[PageId.PROBLEMATIC_AREA] || [],
    [userAnswers],
  )

  const [firstProblematicArea, secondProblematicArea] = useMemo(
    () =>
      TARGET_AREA_OPTIONS.filter(
        ({ key }) => !!problematicAreas.find(({ label }) => label === key),
      ).map(({ label }) => t(label)),
    [t, problematicAreas],
  )

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${GRAPH_PATH}.json`,
        loop: false,
        autoplay: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  const handleClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers: '',
    })
    goTo({ pathname: PURCHASE_PAGES_MAP[cohort as Cohort], search })
  }, [search, cohort])

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>{t('onboarding.result.title')}</S.Title>
        <S.Summery>
          <S.List>
            <S.Item>
              <S.Icon>
                <img src={starsIcon} alt="" />
              </S.Icon>
              <S.ItemName>{t('onboarding.result.goal')}</S.ItemName>
              <S.UserAnswer>
                <Trans
                  i18nKey="onboarding.result.userGoalSelected"
                  context={userGoal || UserGoal.REDUCE_FACE_FAT}
                />
              </S.UserAnswer>
            </S.Item>
            {!!problematicAreas.length && (
              <S.Item>
                <S.Icon>
                  <img src={smileIcon} alt="" />
                </S.Icon>
                <S.ItemName>{t('onboarding.result.targetArea')}</S.ItemName>
                <S.UserAnswer>
                  {firstProblematicArea}
                  {secondProblematicArea &&
                    `, ${secondProblematicArea.toLowerCase()}`}
                </S.UserAnswer>
              </S.Item>
            )}
            <S.Item>
              <S.Icon>
                <img src={dropsIcon} alt="" />
              </S.Icon>
              <S.ItemName>{t('onboarding.result.skinType')}</S.ItemName>
              <S.UserAnswer>
                <Trans
                  i18nKey="onboarding.result.userSkinType"
                  context={userAnswers?.[PageId.SKIN_TYPE]}
                />
              </S.UserAnswer>
            </S.Item>
            <S.Image
              src={WOMEN_IMAGE[userGoal || UserGoal.REDUCE_FACE_FAT]}
              alt=""
            />
          </S.List>
        </S.Summery>
        <S.Graph>
          <S.AnimationContainer ref={animationContainerRef} />
          <S.Intervals>
            <span>{t('onboarding.result.week1')}</span>
            <span>{t('onboarding.result.week2')}</span>
            <span>{t('onboarding.result.week3')}</span>
            <span>{t('onboarding.result.week4')}</span>
          </S.Intervals>
          <S.GraphGoal>
            <Trans
              i18nKey="onboarding.result.graphGoal"
              context={userGoal || UserGoal.REDUCE_FACE_FAT}
            />
          </S.GraphGoal>
        </S.Graph>
      </Container>
      <StickyButtonContainer
        customBackground={`linear-gradient(
        180deg,
        rgba(255,255,255,0) 0%,
        #c5d1ff 40%)`}
      >
        <Container>
          <Button onClick={handleClick} disabled={isFetching}>
            {t('actions.next')}
          </Button>
        </Container>
      </StickyButtonContainer>
    </S.Root>
  )
}
