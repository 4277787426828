import React from 'react'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { TComponentProps } from 'models/common.model'

import { StyledPageTitleDescription as S } from './PageTitleDescription.styles'

export const PageTitleDescription: React.FC<TComponentProps> = ({
  children,
}) => {
  const { isTiktokCohort } = useActiveCohortIdentifier()

  return (
    <S.PageTitleDescription isSmallSize={isTiktokCohort}>
      {children}
    </S.PageTitleDescription>
  )
}
