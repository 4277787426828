import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage, selectTaboolaPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { COHORT_QUERY_PARAM, Locale } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useTaboolaPixelInitialization = () => {
  const { search } = document.location
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)
  const taboolaPixelId = useSelector(selectTaboolaPixelId)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const shouldTaboolaPixelBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length && isEnglishLang) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAdvertizingAndMarketingCookie &&
        isEnglishLang)
    )
  }, [
    isEUUser,
    userCookieConsentAnswer?.length,
    isEnglishLang,
    hasAdvertizingAndMarketingCookie,
  ])

  useLayoutEffect(() => {
    if (
      !uuid ||
      !userCountryCode ||
      shouldTaboolaPixelBePaused ||
      !taboolaPixelId
    ) {
      return
    }

    const taboolaScript = document.createElement('script')

    taboolaScript.type = 'text/javascript'
    taboolaScript.text = `
    window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: ${taboolaPixelId}});
    !function (t, f, a, x) {
           if (!document.getElementById(x)) {
              t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
           }
    }(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1679288/tfa.js',
    'tb_tfa_script');
    `

    document.body.appendChild(taboolaScript)
  }, [
    cohort,
    shouldTaboolaPixelBePaused,
    taboolaPixelId,
    userCountryCode,
    uuid,
  ])
}
