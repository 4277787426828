import styled from 'styled-components'

import clock from 'assets/images/clock-three-hours.svg'

import { Color } from 'root-constants'

export const StyledPersonalPurchaseTimer = {
  Wrapper: styled.div`
    position: sticky;
    top: 0;
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    line-height: 26px;
    color: ${Color.WHITE};
    background-size: 100%;
    background: linear-gradient(
      316deg,
      #9796f0 0%,
      rgba(250, 185, 172, 0.99) 68.47%,
      #fcc 100%
    );
    z-index: 10;
  `,
  Clock: styled.div`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-image: url(${clock});
  `,
  Text: styled.span`
    font-size: 18px;
  `,
  Timer: styled.span`
    font-size: 20px;
    margin: 0 5px;
  `,
}
