import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import {
  selectUserConfigData,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { CookieConsentOption, EU_COUNTRY_CODES, Locale } from 'root-constants'

export const useCookieConsentAnswer = () => {
  const userCountryCode = useSelector(selectUserCountryCode)
  const config = useSelector(selectUserConfigData)
  const language = useSelector(selectLanguage)

  const isEUUser = EU_COUNTRY_CODES.includes(userCountryCode.toUpperCase())

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const { userCookieConsentAnswer } = useMemo(() => {
    return {
      userCookieConsentAnswer: config?.cookieConsent as string[],
    }
  }, [config])

  const hasAdvertizingAndMarketingCookie = useMemo(() => {
    return userCookieConsentAnswer?.includes(
      CookieConsentOption.ADVERTIZING_AND_TARGETING,
    )
  }, [userCookieConsentAnswer])

  const hasFunctionalCookie = useMemo(() => {
    return userCookieConsentAnswer?.includes(
      CookieConsentOption.FUNCTIONAL_COOKIES,
    )
  }, [userCookieConsentAnswer])

  const hasAnalyticsAndPerformanceCookie = useMemo(() => {
    return userCookieConsentAnswer?.includes(
      CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
    )
  }, [userCookieConsentAnswer])

  const isPersonalDataAllowed = useMemo(() => {
    return (
      (isEUUser && hasFunctionalCookie && isEnglishLang) ||
      (isEUUser && !isEnglishLang) ||
      !isEUUser
    )
  }, [isEUUser, isEnglishLang, hasFunctionalCookie])

  return {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
    isPersonalDataAllowed,
  }
}
