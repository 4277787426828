import brianna from 'assets/images/brianna-before-after-min.jpg'
import firstReviewerUnder39 from 'assets/images/customer-photo-2.png'
import fourthReviewerUnder29 from 'assets/images/customer-photo-3.png'
import firstReviewerUnder29 from 'assets/images/customer-photo-google-funnel-7.png'
import secondReviewerUnder29 from 'assets/images/customer-photo-google-funnel-8.png'
import thirdReviewerUnder29 from 'assets/images/customer-photo-google-funnel-9.png'
import secondReviewerUnder39 from 'assets/images/customer-photo-google-funnel-10.png'
import thirdReviewerUnder39 from 'assets/images/customer-photo-google-funnel-11.png'
import fourthReviewerUnder39 from 'assets/images/customer-photo-google-funnel-12.png'
import firstReviewerUnder49 from 'assets/images/customer-photo-google-funnel-13.png'
import secondReviewerUnder49 from 'assets/images/customer-photo-google-funnel-14.png'
import thirdReviewerUnder49 from 'assets/images/customer-photo-google-funnel-15.png'
import fourthReviewerUnder49 from 'assets/images/customer-photo-google-funnel-16.png'
import firstReviewerUnder59 from 'assets/images/customer-photo-google-funnel-17.png'
import secondReviewerUnder59 from 'assets/images/customer-photo-google-funnel-18.png'
import thirdReviewerUnder59 from 'assets/images/customer-photo-google-funnel-19.png'
import fourthReviewerUnder59 from 'assets/images/customer-photo-google-funnel-20.png'
import firstReviewerOlder60 from 'assets/images/customer-photo-google-funnel-21.png'
import secondReviewerOlder60 from 'assets/images/customer-photo-google-funnel-22.png'
import thirdReviewerOlder60 from 'assets/images/customer-photo-google-funnel-23.png'
import fourthReviewerOlder60 from 'assets/images/customer-photo-google-funnel-24.png'
import jenifer from 'assets/images/dalia-before-after-min.png'
import dalia from 'assets/images/dalia-result.png'
import linda from 'assets/images/linda-before-after-min.jpg'
import melissa from 'assets/images/melissa-before-after-min.png'
import paul from 'assets/images/paul-before-after-min.png'
import personalPurchase29Image from 'assets/images/personal-purchase-29-image.png'
import personalPurchase39ReduceImage from 'assets/images/personal-purchase-39-reduce-image.png'
import personalPurchase39WrinklesImage from 'assets/images/personal-purchase-39-wrinkles-image.png'
import personalPurchase49WrinklesImage from 'assets/images/personal-purchase-49-reduce-image.png'
import personalPurchase49ReduceImage from 'assets/images/personal-purchase-49-wrinkles-image.png'
import personalPurchase50Image from 'assets/images/personal-purchase-50-image.png'
import skinConcernsAcne from 'assets/images/skin-concerns-acne.svg'
import skinConcernsDullness from 'assets/images/skin-concerns-dullness.svg'
import skinConcernsPigmentation from 'assets/images/skin-concerns-pigmentation.svg'
import skinConcernsRedness from 'assets/images/skin-concerns-redness.svg'
import skinConcernsRough from 'assets/images/skin-concerns-rough.svg'
import skinConcernsUneven from 'assets/images/skin-concerns-uneven.svg'
import skinTypeCombination from 'assets/images/skin-type-combination.svg'
import skinTypeDry from 'assets/images/skin-type-dry.svg'
import skinTypeNormal from 'assets/images/skin-type-normal.svg'
import skinTypeOily from 'assets/images/skin-type-oily.svg'

import { UserGoal } from 'root-constants'

export interface IReviews {
  name: string
  image: string
}

export enum SkinConcerns {
  ACNE = 'acne',
  REDNESS = 'redness',
  DULLNESS = 'dullness',
  ROUGH_TEXTURE = 'rough_texture',
  UNEVEN_SKIN_TONE = 'uneven_skin_tone',
  PIGMENTATION_CHANGES = 'pigmentation_changes',
  NOTHING_FROM_THE_ABOVE = 'nothing_from_the_above',
}

export enum SKIN_TYPE_OPTIONS {
  NORMAL = 'normal',
  COMBINATION = 'combination',
  DRY = 'dry',
  OILY = 'oily',
}

export enum AGE_VALUES {
  TWENTY = '20',
  THIRTY = '30',
  FORTY = '40',
  FIFTY = '50',
  SIXTY = '60',
  SEVENTY = '70',
}

export const PERSONAL_POSTER = {
  [AGE_VALUES.TWENTY]: personalPurchase29Image,
  [AGE_VALUES.THIRTY]: {
    [UserGoal.REDUCE_FACE_FAT]: personalPurchase39ReduceImage,
    [UserGoal.WRINKLE_FREE_SKIN]: personalPurchase39WrinklesImage,
    [UserGoal.BOTH_OPTIONS]: personalPurchase39ReduceImage,
  },
  [AGE_VALUES.FORTY]: {
    [UserGoal.REDUCE_FACE_FAT]: personalPurchase49ReduceImage,
    [UserGoal.WRINKLE_FREE_SKIN]: personalPurchase49WrinklesImage,
    [UserGoal.BOTH_OPTIONS]: personalPurchase49ReduceImage,
  },
  [AGE_VALUES.FIFTY]: personalPurchase50Image,
}

export const BENEFIT_LIST = {
  userGoal: {
    [UserGoal.WRINKLE_FREE_SKIN]: 'wrinkles',
    [UserGoal.REDUCE_FACE_FAT]: 'reduceFat',
    [UserGoal.BOTH_OPTIONS]: 'both',
  },
  skinConcerns: {
    [SkinConcerns.ACNE]:
      'purchasePersonal.benefitsList.secondItem.acneAndPimples',
    [SkinConcerns.REDNESS]: 'purchasePersonal.benefitsList.secondItem.redness',
    [SkinConcerns.DULLNESS]:
      'purchasePersonal.benefitsList.secondItem.dullness',
    [SkinConcerns.ROUGH_TEXTURE]:
      'purchasePersonal.benefitsList.secondItem.roughTexture',
    [SkinConcerns.UNEVEN_SKIN_TONE]:
      'purchasePersonal.benefitsList.secondItem.unevenSkinTone',
    [SkinConcerns.PIGMENTATION_CHANGES]:
      'purchasePersonal.benefitsList.secondItem.pigmentationChanges',
    [SkinConcerns.NOTHING_FROM_THE_ABOVE]:
      'purchasePersonal.benefitsList.secondItem.nothingFromTheAbove',
  },
  skinType: {
    [SKIN_TYPE_OPTIONS.NORMAL]:
      'purchasePersonal.benefitsList.thirdItem.normal',
    [SKIN_TYPE_OPTIONS.COMBINATION]:
      'purchasePersonal.benefitsList.thirdItem.combination',
    [SKIN_TYPE_OPTIONS.DRY]: 'purchasePersonal.benefitsList.thirdItem.dry',
    [SKIN_TYPE_OPTIONS.OILY]: 'purchasePersonal.benefitsList.thirdItem.oily',
  },
}

export const SKIN_TYPE_LIST = {
  [SKIN_TYPE_OPTIONS.NORMAL]: {
    image: skinTypeNormal,
    info: 'onboarding.skinType.normal',
  },
  [SKIN_TYPE_OPTIONS.COMBINATION]: {
    image: skinTypeCombination,
    info: 'onboarding.skinType.combination',
  },
  [SKIN_TYPE_OPTIONS.DRY]: {
    image: skinTypeDry,
    info: 'onboarding.skinType.dry',
  },
  [SKIN_TYPE_OPTIONS.OILY]: {
    image: skinTypeOily,
    info: 'onboarding.skinType.oily',
  },
}
export const SKIN_CONCERNS_LIST = {
  [SkinConcerns.ACNE]: {
    image: skinConcernsAcne,
    info: 'onboarding.skinConcerns.acne',
  },
  [SkinConcerns.REDNESS]: {
    image: skinConcernsRedness,
    info: 'onboarding.skinConcerns.redness',
  },
  [SkinConcerns.DULLNESS]: {
    image: skinConcernsDullness,
    info: 'onboarding.skinConcerns.dullness',
  },
  [SkinConcerns.ROUGH_TEXTURE]: {
    image: skinConcernsRough,
    info: 'onboarding.skinConcerns.roughTexture',
  },
  [SkinConcerns.UNEVEN_SKIN_TONE]: {
    image: skinConcernsUneven,
    info: 'onboarding.skinConcerns.unevenSkinTone',
  },
  [SkinConcerns.PIGMENTATION_CHANGES]: {
    image: skinConcernsPigmentation,
    info: 'onboarding.skinConcerns.pigmentationChangesGirlish',
  },
  [SkinConcerns.NOTHING_FROM_THE_ABOVE]: {
    image: skinTypeNormal,
    info: 'purchasePersonal.profilerSummary.normal',
  },
}

export const DEFAULT_REVIEWS: IReviews[] = [
  {
    name: 'purchasePersonal.reviews.melissa',
    image: melissa,
  },
  {
    name: 'purchasePersonal.reviews.dalia',
    image: dalia,
  },
  {
    name: 'purchasePersonal.reviews.paul',
    image: paul,
  },
  {
    name: 'purchasePersonal.reviews.brianna',
    image: brianna,
  },
  {
    name: 'purchasePersonal.reviews.linda',
    image: linda,
  },
  {
    name: 'purchasePersonal.reviews.jennifer',
    image: jenifer,
  },
]

export const CUSTOMER_REVIEWS = {
  [AGE_VALUES.TWENTY]: [
    {
      name: 'purchasePersonal.customersReview.under29.kate.name',
      review: 'purchasePersonal.customersReview.under29.kate.review',
      icon: firstReviewerUnder29,
    },
    {
      name: 'purchasePersonal.customersReview.under29.sarah.name',
      review: 'purchasePersonal.customersReview.under29.sarah.review',
      icon: secondReviewerUnder29,
    },
    {
      name: 'purchasePersonal.customersReview.under29.jennifer.name',
      review: 'purchasePersonal.customersReview.under29.jennifer.review',
      icon: thirdReviewerUnder29,
    },
  ],
  [AGE_VALUES.THIRTY]: [
    {
      name: 'purchasePersonal.customersReview.under39.kate.name',
      review: 'purchasePersonal.customersReview.under39.kate.review',
      icon: firstReviewerUnder39,
    },
    {
      name: 'purchasePersonal.customersReview.under39.sarah.name',
      review: 'purchasePersonal.customersReview.under39.sarah.review',
      icon: secondReviewerUnder39,
    },
    {
      name: 'purchasePersonal.customersReview.under39.jennifer.name',
      review: 'purchasePersonal.customersReview.under39.jennifer.review',
      icon: thirdReviewerUnder39,
    },
  ],
  [AGE_VALUES.FORTY]: [
    {
      name: 'purchasePersonal.customersReview.under49.kate.name',
      review: 'purchasePersonal.customersReview.under49.kate.review',
      icon: firstReviewerUnder49,
    },
    {
      name: 'purchasePersonal.customersReview.under49.sarah.name',
      review: 'purchasePersonal.customersReview.under49.sarah.review',
      icon: secondReviewerUnder49,
    },
    {
      name: 'purchasePersonal.customersReview.under49.jennifer.name',
      review: 'purchasePersonal.customersReview.under49.jennifer.review',
      icon: thirdReviewerUnder49,
    },
  ],
  [AGE_VALUES.FIFTY]: [
    {
      name: 'purchasePersonal.customersReview.under59.kate.name',
      review: 'purchasePersonal.customersReview.under59.kate.review',
      icon: firstReviewerUnder59,
    },
    {
      name: 'purchasePersonal.customersReview.under59.sarah.name',
      review: 'purchasePersonal.customersReview.under59.sarah.review',
      icon: secondReviewerUnder59,
    },
    {
      name: 'purchasePersonal.customersReview.under59.jennifer.name',
      review: 'purchasePersonal.customersReview.under59.jennifer.review',
      icon: thirdReviewerUnder59,
    },
  ],
  [AGE_VALUES.SIXTY]: [
    {
      name: 'purchasePersonal.customersReview.older60.kate.name',
      review: 'purchasePersonal.customersReview.older60.kate.review',
      icon: firstReviewerOlder60,
    },
    {
      name: 'purchasePersonal.customersReview.older60.sarah.name',
      review: 'purchasePersonal.customersReview.older60.sarah.review',
      icon: secondReviewerOlder60,
    },
    {
      name: 'purchasePersonal.customersReview.older60.jennifer.name',
      review: 'purchasePersonal.customersReview.older60.jennifer.review',
      icon: thirdReviewerOlder60,
    },
  ],
}

export const CUSTOMER_REVIEWS_MORE = {
  [AGE_VALUES.TWENTY]: [
    {
      name: 'purchasePersonal.customersReview.under29.emma.name',
      review: 'purchasePersonal.customersReview.under29.emma.review',
      icon: fourthReviewerUnder29,
    },
  ],
  [AGE_VALUES.THIRTY]: [
    {
      name: 'purchasePersonal.customersReview.under39.emma.name',
      review: 'purchasePersonal.customersReview.under39.emma.review',
      icon: fourthReviewerUnder39,
    },
  ],
  [AGE_VALUES.FORTY]: [
    {
      name: 'purchasePersonal.customersReview.under49.emma.name',
      review: 'purchasePersonal.customersReview.under49.emma.review',
      icon: fourthReviewerUnder49,
    },
  ],
  [AGE_VALUES.FIFTY]: [
    {
      name: 'purchasePersonal.customersReview.under59.emma.name',
      review: 'purchasePersonal.customersReview.under59.emma.review',
      icon: fourthReviewerUnder59,
    },
  ],
  [AGE_VALUES.SIXTY]: [
    {
      name: 'purchasePersonal.customersReview.older60.emma.name',
      review: 'purchasePersonal.customersReview.older60.emma.review',
      icon: fourthReviewerOlder60,
    },
  ],
}
