import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import {
  COHORT_QUERY_PARAM,
  Cohort,
  CurrentEnvironment,
  HOTJAR_AVAILABLE_COHORTS,
  HotJarClientId,
  Locale,
} from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useHotJarInitialization = (): void => {
  const { search } = document.location
  const isProduction = getCurrentEnv() === CurrentEnvironment.PROD
  const HOTJAR_CLIENT_ID = isProduction
    ? HotJarClientId.PROD
    : HotJarClientId.DEV
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM) as Cohort

  const {
    isEUUser,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])
  const isAvailableCohort = useMemo(
    () => HOTJAR_AVAILABLE_COHORTS.includes(cohort),
    [cohort],
  )

  const shouldHotJarBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length && isEnglishLang) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAnalyticsAndPerformanceCookie &&
        isEnglishLang)
    )
  }, [
    isEUUser,
    userCookieConsentAnswer,
    hasAnalyticsAndPerformanceCookie,
    isEnglishLang,
  ])

  useLayoutEffect(() => {
    if (shouldHotJarBePaused || !userCountryCode || !isAvailableCohort) return

    const script = document.createElement('script')
    script.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_CLIENT_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
    script.async = true
    document.head.appendChild(script)
  }, [
    shouldHotJarBePaused,
    userCountryCode,
    HOTJAR_CLIENT_ID,
    isAvailableCohort,
  ])
}
