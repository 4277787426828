import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserGoal } from 'root-redux/selects/common'

import { useKeywordValue } from 'hooks/useKeywordValue'

import { selectSubscriptionPeriodQuantity } from 'modules/purchase/redux/selects/common'

import { UserGoal } from 'root-constants'

import { StyledPurchaseTitleV2 as S } from './PurchaseTitleV2.styles'

type TProps = {
  className?: string
}

export const PurchaseTitleV2: React.FC = ({ className = '' }: TProps) => {
  const { t } = useTranslation()
  const goal = useSelector(selectUserGoal)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const keyword = useKeywordValue()

  const titleText = useMemo(() => {
    const titleParams = {
      count: periodQuantity,
    }

    switch (goal) {
      case UserGoal.WRINKLE_FREE_SKIN:
        return t(
          `purchase2.title.first.${keyword}`,
          t('purchase1.titleFirst', titleParams),
          titleParams,
        )
      case UserGoal.REDUCE_FACE_FAT:
        return t(
          `purchase2.title.second.${keyword}`,
          t('purchase1.titleFirst', titleParams),
          titleParams,
        )
      case UserGoal.BOTH_OPTIONS:
        return t(
          `purchase2.title.both.${keyword}`,
          t('purchase1.titleSecond', titleParams),
          titleParams,
        )
      default:
        return t('purchase1.titleFirst', titleParams)
    }
  }, [periodQuantity, goal, t, keyword])

  return <S.Title className={className}>{titleText}</S.Title>
}
