import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectHasInAppOffer,
  selectHasUpsellOffer,
} from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import {
  EMAIL_CONSENT_PAGES_MAP,
  IN_APP_PAYWALL_PAGES_MAP,
  PURCHASE_PAGES_MAP,
  PageId,
  UPSELL_PAGES_MAP,
} from 'page-constants'
import { Cohort } from 'root-constants'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const hasUpsellOffer = useSelector(selectHasUpsellOffer)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const hasInAppOffer = useSelector(selectHasInAppOffer)

  const cohortToUse = (parentCohort || cohort) as Cohort

  const isUpsellPassed =
    (userStatus?.upsell.isUpsellPurchased && !hasInAppOffer) ||
    (userStatus?.upsell.isUpsellTried && !hasInAppOffer)

  const isInAppPassed =
    userStatus?.inapp.isInAppPurchased || userStatus?.inapp.isInAppTried

  const upsellAndInAppInactive =
    userStatus?.hasSubscription && !hasUpsellOffer && !hasInAppOffer

  if (userStatus?.account.hasAccount) {
    return PageId.DOWNLOAD
  }

  if (isUpsellPassed || isInAppPassed || upsellAndInAppInactive) {
    return PageId.FINISHING_TOUCHES
  }

  if (
    userStatus?.upsell.isUpsellPurchased ||
    userStatus?.upsell.isUpsellTried ||
    (userStatus?.hasSubscription && !hasUpsellOffer)
  ) {
    return IN_APP_PAYWALL_PAGES_MAP[cohortToUse] || PageId.IN_APP_PAYWALL
  }

  if (userStatus?.hasSubscription) {
    return UPSELL_PAGES_MAP[cohortToUse] || PageId.UPSELL_PAYWALL
  }

  if (userStatus?.email.hasEmail && userStatus?.email.isEmailConsentTried) {
    return PURCHASE_PAGES_MAP[cohortToUse] || PageId.PROXY_TO_START
  }

  if (userStatus?.email.hasEmail && !userStatus?.email.isEmailConsentTried) {
    return EMAIL_CONSENT_PAGES_MAP[cohortToUse] || PageId.EMAIL_CONSENT
  }

  return ''
}
