import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledNumberPurchasePlans = {
  Container: styled.div`
    color: ${Color.BLUE_DARK};
    line-height: 22px;
    margin-bottom: 8px;

    & > strong {
      color: ${Color.PRIMARY};
    }
  `,
}
